import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getBuildingRequest, getBuildingRequestLM } from "../../actions/buildings";
import { getCustomerBuildings, logoutCustomer, setCurrentBuilding } from "../../actions/auth";
import CDropdown from "./components/CDropdown";
import Themes from "./Themes";
import "./Themes.scss";
import moment from "moment";
import axios from "axios";
import FocusLock from 'react-focus-lock';
import { toast } from 'react-toastify';

import { withTranslation, Trans } from "react-i18next";
import i18next from 'i18next';

import "./CustomerSettings.scss";
import ToggleButton from "../Common/Modules/ToggleButton";

/////

class CustomerSettingsPage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			modalOpen: null,
			modalY: 0,
			shareLevel: 1,
			deleteBuildings: {},
			selectedBuilding: null,
			shareBuildingInfo: {}
		};

		this.modalTitleRef = {};
		this.modalCloseClick = this.modalCloseClick.bind(this);
		this.modalOpenClick = this.modalOpenClick.bind(this);

		this.theme = Themes.autodetect();

		if (window.location.href.includes('/settings')) {
			if (localStorage.getItem('token') !== null) {
				props.dispatch(getCustomerBuildings(true));
			}
			else {
				props.dispatch(getBuildingRequest());
				props.dispatch(getBuildingRequestLM());
			}
		}
	}

	componentDidUpdate(prevProps) {
		if (!this.state.selectedBuilding && this.props.customerBuildings?.length) {
			let building = this.props.customerBuildings[0];
			let options = [];
			let shareBuildingInfo = {};
			if (this.props.customerBuildings) {
				for (let building of this.props.customerBuildings) {
					options.push({
						title: building.address,
						id: building.id,
						key: building.id,
						naddr: building.normalized_address,
						current: building.current,
						primary: building.primary
					});
					shareBuildingInfo[building.id] = {
						publicMap: true,
						communityPlanning: true,
						futureOwners: true
					};
				}
			}
			this.setState({
				buildingOptions: options, shareBuildingInfo, selectedBuilding: {
					title: building.address,
					id: building.id,
					naddr: building.normalized_address,
					current: building.current,
					primary: building.primary
				}, rand: Math.random()
			});
		}
		if (!prevProps.show && this.props.show) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			const el = document.getElementById('ph-header-cont');
			if (el) {
				el.scrollTop = 0;
			}
		}
		if (this.state.shareLevel != this.props.shareLevel) {
			this.setState({ shareLevel: this.props.shareLevel })
		}
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

	deleteProfile() {
		const pin = this.state.deletePassword;
		if (!pin?.length && !this.props.isB2CUser) {
			return;
		}
		const body = {
			pin: this.props.isB2CUser ? null : pin,
			options: {}//this.state.shareBuildingInfo
		};
		this.setState({ loading: true });
		axios.post(`/users/delete-user-info`, body).then(res => {
			if (res?.data?.success) {
				this.setState({ loading: false });
				this.props.dispatch(logoutCustomer(true));
				if (res?.data?.b2c_domain_hint) {
					const url = `https://climativetest.b2clogin.com/climativetest.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_DELETE_MY_ACCOUNT&client_id=c37bab30-9b29-41a7-972b-e09ec1a0e7e6&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fremote-audit-staging.azurewebsites.net&scope=openid&response_type=id_token&prompt=login&domain_hint=${res?.data?.b2c_domain_hint}`;
					window.open(url, '_blank').focus();
				}
				window.location.href = "#/public/homeowner-map?deleted";
				window.location.reload();
			}
			else {
				this.setState({ loading: false });
				if (res?.data?.error === "Could not remove account. Error authorizing user") {
					toast.error(this.T("The password you entered is incorrect."));
				}
				else {
					toast.error(this.T("Unknown error, please try again later."));
				}
			}
		}).catch((error) => {
			this.setState({ loading: false });
			if (error?.response?.data?.error === "Could not remove account. Error authorizing user") {
				toast.error(this.T("The password you entered is incorrect."));
			}
			else {
				toast.error(this.T("Unknown error, please try again later."));
			}
		});
	}

	modalCloseClick() {
		return () => {
			this.modalJustOpened = false;
			this.setState({
				modalOpen: null,
				rand: Math.random(),
			});
		};
	}

	modalOpenClick(stateName) {

		if (!this.modalTitleRef[stateName]) {
			this.modalTitleRef[stateName] = React.createRef();
		}

		return () => {
			this.modalJustOpened = true;
			setTimeout(() => {
				if (this.modalJustOpened && this.modalTitleRef[stateName] && this.modalTitleRef[stateName].current) {
					this.modalTitleRef[stateName].current.focus();
					this.modalJustOpened = false;
				}
			}, 100);
			this.setState({
				faqExpand: null,
				modalOpen: stateName,
				rand: Math.random(),
				modalY: this.props.isPreview ? document.getElementById('report-preview-cont').scrollTop : document.documentElement.scrollTop,
			});
		};
	}

	renderModal(stateName, title, contentFn) {
		let fullScreen = false;
		switch (stateName) {
			case 'delete-account':
				fullScreen = true;
				break;
			default: break;
		}
		return (
			<div
				className={"crp-modal-cont " + (fullScreen ? 'crp-modal-fullscreen' : '')}
				style={this.state.modalOpen == stateName ? { display: "block" } : { display: "none" }}
				//onClick={this.modalCloseClick(stateName)}
				aria-label={title + " Modal"}
				role="dialog"
				aria-labelledby="crp-modal-title"
				aria-live="polite" accessibilityLiveRegion="polite"
			>
				{this.state.modalOpen == stateName ? <FocusLock>
					<div
						className="cnp-modal"
						//style={{ top: this.state.modalY + "px" }}
						onClick={(e) => {
							(e || window.event).stopPropagation();
							return false;
						}}
					>
						<div ref={this.modalTitleRef[stateName]} className="crp-modal-header">
							<h2 className="crp-modal-title">{stateName === 'share-more-error' ? <img alt="" src="/images/yellow-warning.svg" className="cst-warning-icon" /> : undefined}{title}</h2>
							<button onClick={this.modalCloseClick(stateName)}><img alt="Close Modal" src="/images/x-icon-black.svg" /></button>
						</div>
						<div className="crp-modal-body">{contentFn()}</div>
					</div>
				</FocusLock> : undefined}
			</div>
		);
	}

	updateSharingLevel(level) {
		const buildingId = this.props.building.buildingId;
		axios.put(`/buildings/${buildingId}/set-data-sharing-level`, { level }).then(res => {
			this.props.dispatch(getBuildingRequest(buildingId));
			this.props.dispatch(getBuildingRequestLM(buildingId));
			toast.success(this.T("Your building's sharing permissions have been updated."));
		}).catch((err) => {
			if (err?.response?.data === "sharing level permissions error") {
				this.modalOpenClick('share-more-error')();
			}
			else {
				toast.error(this.T("Unknown error updating your building's sharing permissions."));
			}
		});
	}

	renderHomeSwitcher() {
		let ret;
		if (this.props?.currentUser?.primaryBuildingId) {
			let selected = this.state.buildingOptions ? this.state.buildingOptions.find((e) => e.id === this.props.building.id) : null;
			if (this.state?.buildingOptions?.length > 1 && selected) {
				ret = (<CDropdown aria-label={"Switch Home " + selected.title} key="crp-rh-switcher" style="report-home-switcher" title={selected.title} options={this.state.buildingOptions} defaultValue={selected} onChange={(val) => {
					this.props.dispatch(setCurrentBuilding(val.id));
				}}></CDropdown>);
			}
			else {
				if (!selected) {
					selected = {
						title: this.props.building.address,
						id: this.props.building.id,
						naddr: this.props.building.normalized_address,
						current: true
					};
				}
				ret = (<div className="btmh-address"><img src="/images/small-house.svg" /> {selected.title}</div>);
			}
		}
		return ret;
	}

	onRadioKeydown(e) {
		if (e.key == 'ArrowDown' || e.key == 'ArrowRight') {
			e.preventDefault();
			const next = e.target.nextElementSibling;
			if (next) {
				next.focus();
				next.click();
			}
		} else if (e.key == 'ArrowUp' || e.key == 'ArrowLeft') {
			e.preventDefault();
			const prev = e.target.previousElementSibling;
			if (prev) {
				prev.focus();
				prev.click();
			}
		}
	}

	renderDataPrivacySettings() {
		return (<div className="crp-dataprivacy-cont">
			<div className="crp-dataprivacy">
				<h2 className="crp-dp-title">{this.T("Data Privacy")}</h2>
				<div className="crp-dp-copy">{this.props.currentUser?.id === 'mock-user-123' ? '' : this.T("You are in control of how much data you share publicly")}</div>
				<div role="radiogroup" aria-label={this.T("Data Privacy")} className="crp-dp-btn-cont">
					<button role="radio" onKeyDown={(e) => this.onRadioKeydown(e)} tabIndex={this.state.shareLevel === 1 ? 0 : -1} aria-checked={this.state.shareLevel === 1 ? 'true' : 'false'} className={"crp-dp-btn " + (this.state.shareLevel === 1 ? "crp-dpb-selected" : "")} onClick={() => this.updateSharingLevel(1)}><img alt="" aria-hidden="true" src={this.state.shareLevel === 1 ? '/images/radio-selected.svg' : '/images/radio-unselected.svg'} /> {this.T("Share Nothing")}</button>
					<button role="radio" onKeyDown={(e) => this.onRadioKeydown(e)} tabIndex={this.state.shareLevel === 2 ? 0 : -1} aria-checked={this.state.shareLevel === 2 ? 'true' : 'false'} className={"crp-dp-btn " + (this.state.shareLevel === 2 ? "crp-dpb-selected" : "")} onClick={() => this.updateSharingLevel(2)}><img alt="" aria-hidden="true" src={this.state.shareLevel === 2 ? '/images/radio-selected.svg' : '/images/radio-unselected.svg'} /> {this.T("Share Some")}</button>
					<button role="radio" onKeyDown={(e) => this.onRadioKeydown(e)} tabIndex={this.state.shareLevel === 3 ? 0 : -1} aria-checked={this.state.shareLevel === 3 ? 'true' : 'false'} className={"crp-dp-btn " + (this.state.shareLevel === 3 ? "crp-dpb-selected" : "")} onClick={() => this.updateSharingLevel(3)}><img alt="" aria-hidden="true" src={this.state.shareLevel === 3 ? '/images/radio-selected.svg' : '/images/radio-unselected.svg'} /> {this.T("Share More")}</button>
				</div>
				<div role="list">
					<div className="cdb-dp-checkbox" role="listitem">
						<img alt={this.state.shareLevel > 1 ? this.T('Checked') : this.T('Unchecked')} src={this.state.shareLevel > 1 ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'} /><div className="cdb-dp-check-title">{this.T('Building Colour on Map')}</div>
					</div>
					<div className="cdb-dp-checkbox" role="listitem">
						<img alt={this.state.shareLevel > 1 ? this.T('Checked') : this.T('Unchecked')} src={this.state.shareLevel > 1 ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'} /><div className="cdb-dp-check-title">{this.T('Energy and Emissions Rating')}</div>
					</div>
					<div className="cdb-dp-checkbox" role="listitem">
						<img alt={this.state.shareLevel > 2 ? this.T('Checked') : this.T('Unchecked')} src={this.state.shareLevel > 2 ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'} /><div className="cdb-dp-check-title">{this.T('Energy Use Breakdown (one line)')}</div>
					</div>
					<div className="cdb-dp-checkbox" role="listitem">
						<img alt={this.state.shareLevel > 2 ? this.T('Checked') : this.T('Unchecked')} src={this.state.shareLevel > 2 ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'} /><div className="cdb-dp-check-title">{this.T('Retrofit Scenarios')}</div>
					</div>
				</div>
			</div>
		</div>);
	}

	modalDeleteAccount() {

		let selected = this.state.selectedBuilding;

		switch (this.state.deleteStep) {
			case 1:
				return (<div className="cst-delete-account-modal-cont">
					<div className="cst-da-info">
						<Trans i18nKey="cst-del-step-1">
							{/*<i>There are two types of information on your account: Your Profile (information about you), and your Building info (information about your building).</i><br/>
                            <br/>*/}
							Delete your <b>Profile</b> deletes any information about you (login information, priorities, etc.).<br />
							<br />
							<b>Are you sure you want to delete your Profile?</b><br />
							<div className="cst-del-step-1-warning"><img alt="" src="/images/yellow-warning.svg" /> This cannot be undone.</div>
						</Trans>
						<ToggleButton selected={this.state.deleteStep1Confirm} onChange={(val) => this.setState({ deleteStep1Confirm: val })}>
							{this.state.deleteStep1Confirm ?
								<Trans i18nKey="cst-del-step-1-conf-yes"><div className="cst-da-info"><b>Yes</b>, delete my profile.</div></Trans> :
								<Trans i18nKey="cst-del-step-1-conf-no"><div className="cst-da-info"><b>No</b>, Keep my profile.</div></Trans>
							}
						</ToggleButton>
						<div className="cst-del-btn-cont">
							<button disabled={!this.state.deleteStep1Confirm} onClick={() => this.props.isB2CUser ? this.deleteProfile() : this.setState({ deleteStep: 3 })} className={"cst-del-btn-next " + (this.props.isB2CUser ? 'cst-del-btn-delete' : '')}>{this.T(this.props.isB2CUser ? "Delete" : "Next")}</button>
							<button onClick={this.modalCloseClick()} className="cst-del-btn-cancel">{this.T("Cancel")}</button>
						</div>
					</div>
				</div>);
			case 2:
				return (<div className="cst-delete-account-modal-cont">
					<div className="cst-da-info">
						<Trans i18nKey="cst-del-step-2">
							<i>You decided on the previous page to delete Your Profile (information about you). <b>Any personal information will be deleted.</b></i><br />
							<br />
							<b>Information about your Building(s)</b> can be helpful to others in their attempts to make reduce our carbon emissions. Can your Building Info be used to help with these purposes?
						</Trans>
					</div>
					<div className="cst-da-buildings-title">{this.T("Buildings")}:</div>
					<div className="cst-da-buildings-subtitle">{this.T("Select and edit settings for each of your buildings.")}</div>
					<CDropdown key="cst-da-picker" style="report-home-switcher" title={selected?.title ?? ""} options={this.state.buildingOptions} defaultValue={this.state?.selectedBuilding} onChange={(val) => {
						this.setState({ selectedBuilding: val, rand: Math.random() });
					}}></CDropdown>
					{this.state.selectedBuilding ? <div className="cst-da-building-card">
						<div className="cst-da-bc-title">{this.T("Public Map")}</div>
						<div className="cst-da-bc-desc"><Trans i18nKey={"cst-da-public-map-q"}>
							Having the most accurate information about your building is helpful for the public Map.
						</Trans></div>
						<div className="cst-da-question">{this.T("Share Information?")}</div>
						<ToggleButton selected={!!this.state.shareBuildingInfo[this.state.selectedBuilding?.id]?.publicMap} onChange={(val) => {
							let shareBuildingInfo = this.state.shareBuildingInfo || {};
							shareBuildingInfo[this.state.selectedBuilding?.id] = shareBuildingInfo[this.state.selectedBuilding?.id] || {};
							shareBuildingInfo[this.state.selectedBuilding?.id].publicMap = !!val;
							this.setState({ shareBuildingInfo, rand: Math.random() });
						}}>
							{!this.state.shareBuildingInfo[this.state.selectedBuilding?.id]?.publicMap ?
								<Trans i18nKey="cst-del-step-2-q1-yes"><div className="cst-da-info"><b>No</b>, delete my building info for the public map.</div></Trans> :
								<Trans i18nKey="cst-del-step-2-q1-no"><div className="cst-da-info"><b>Yes</b>, Keep my building info for the public map.</div></Trans>
							}
						</ToggleButton>
					</div> : undefined}
					{this.state.selectedBuilding ? <div className="cst-da-building-card">
						<div className="cst-da-bc-title">{this.T("Help with Community-Level Planning")}</div>
						<div className="cst-da-bc-desc"><Trans i18nKey={"cst-da-community-planning-q"}>
							<b>Building Info</b> you have provided is helpful for local governments and other organizations for important activities like planning and tracking carbon emissions reductions.
						</Trans></div>
						<div className="cst-da-question">{this.T("Share Information?")}</div>
						<ToggleButton selected={!!this.state.shareBuildingInfo[this.state.selectedBuilding?.id]?.communityPlanning} onChange={(val) => {
							let shareBuildingInfo = this.state.shareBuildingInfo || {};
							shareBuildingInfo[this.state.selectedBuilding?.id] = shareBuildingInfo[this.state.selectedBuilding?.id] || {};
							shareBuildingInfo[this.state.selectedBuilding?.id].communityPlanning = !!val;
							this.setState({ shareBuildingInfo, rand: Math.random() });
						}}>
							{!this.state.shareBuildingInfo[this.state.selectedBuilding?.id]?.communityPlanning ?
								<Trans i18nKey="cst-del-step-2-q2-yes"><div className="cst-da-info"><b>No</b>, delete my Building Info for community-level planning</div></Trans> :
								<Trans i18nKey="cst-del-step-2-q2-no"><div className="cst-da-info"><b>Yes</b>, keep my Building Info for community-level planning</div></Trans>
							}
						</ToggleButton>
					</div> : undefined}
					{this.state.selectedBuilding ? <div className="cst-da-building-card">
						<div className="cst-da-bc-title">{this.T("Help future Homeowners")}</div>
						<div className="cst-da-bc-desc"><Trans i18nKey={"cst-da-community-planning-q"}>
							<b>Building Info</b> you have provided is also helpful for future homeowners.
						</Trans></div>
						<div className="cst-da-question">{this.T("Share Information?")}</div>
						<ToggleButton selected={!!this.state.shareBuildingInfo[this.state.selectedBuilding?.id]?.futureOwners} onChange={(val) => {
							let shareBuildingInfo = this.state.shareBuildingInfo || {};
							shareBuildingInfo[this.state.selectedBuilding?.id] = shareBuildingInfo[this.state.selectedBuilding?.id] || {};
							shareBuildingInfo[this.state.selectedBuilding?.id].futureOwners = !!val;
							this.setState({ shareBuildingInfo, rand: Math.random() });
						}}>
							{!this.state.shareBuildingInfo[this.state.selectedBuilding?.id]?.futureOwners ?
								<Trans i18nKey="cst-del-step-2-q3-yes"><div className="cst-da-info"><b>No</b>, delete my Building Info for future owners of my home</div></Trans> :
								<Trans i18nKey="cst-del-step-2-q3-no"><div className="cst-da-info"><b>Yes</b>, keep my Building Info for future owners of my home</div></Trans>
							}
						</ToggleButton>
					</div> : undefined}
					<div className="cst-del-btn-cont cst-no-absolute">
						<button disabled={false} onClick={() => this.setState({ deleteStep: 3 })} className="cst-del-btn-next">{this.T("Next")}</button>
						<button onClick={this.modalCloseClick()} className="cst-del-btn-cancel">{this.T("Cancel")}</button>
					</div>
				</div>);
			case 3:
				return (<div className="cst-delete-account-modal-cont cst-delete-account-modal-cont-step-3">
					<label for="delete-password-input" className="cst-da-label">{this.T("Enter your password to confirm")}</label>
					<input className="cst-da-input" id="delete-password-input" type="password" value={this.state.deletePassword} onChange={(e) => this.setState({ deletePassword: e.target.value })} />
					<button onClick={() => this.deleteProfile()} className="cst-delete-button" disabled={!this.state.deletePassword?.length}>{this.T("Delete Profile")}</button>
				</div>);
			default:
				return (<div className="cst-delete-account-modal-cont">
				</div>);
		}
	}

	shareMoreError() {

		return (<div className="cst-share-more-error">
			<Trans i18nKey="cst-share-more-error">Unfortunately, you aren’t able to use the Share More feature due to data restrictions.<br />
				<br />
				You will be able to Share More if you update your home with an EnerGuide Label.</Trans>
		</div>);

	}

	componentDidMount() {
		this.props.dispatch(getCustomerBuildings());
	}

	render() {
		if (this.props.show) {
			document.title = this.T('Settings') + ' - ' + this.T('Climative Low Carbon Communities');
			document.body.className = "customer-page " + this.theme.className;
		}

		if (!this.props.show) {
			return (<div />);
		}

		if (!this.props.currentUser) {
			window.location.href = "#/public/homeowner-map";
			return (<div />)
		}

		const lang = `${this.T('hmp-lang-code')}`.toLowerCase();

		let deleteProfileTitle = this.T("Delete my Profile");
		if (this.state.deleteStep === 2) {
			deleteProfileTitle = this.T("My Building Info");
		}
		else if (this.state.deleteStep === 3) {
			deleteProfileTitle = this.T("Confirmation");
		}

		return (
			<div className="csettings-page-cont">
				<div className={'loading ' + (this.state.loading ? 'loading-show' : 'loading-hide')}><img alt={this.T("Loading Animation")} src='/images/loading-transparent.gif' /></div>
				{this.renderModal("share-more-error", this.T("Can't Share More"), () => this.shareMoreError())}
				{this.renderModal("delete-account", deleteProfileTitle, () => this.modalDeleteAccount())}
				<div className="csettings-page-inner">
					{this.theme?.advisorExperience ? undefined : <h2 className="cst-title">{this.T('Building Settings')}</h2>}
					{this.theme?.advisorExperience ? undefined : this.renderHomeSwitcher()}
					{this.theme?.advisorExperience ? undefined : this.renderDataPrivacySettings()}
					<h2 className="cst-title">{this.T('Profile Settings')}</h2>
					<button className="cst-delete-account-btn" onClick={() => { this.setState({ deleteBuildings: {}, deletePassword: "", deleteStep: 1, deleteStep1Confirm: false }); this.modalOpenClick('delete-account')(); }}><img src="/images/delete-account-icon.svg" alt="" /> {this.T("Delete My Account")}</button><br />
					<br />
				</div>
				<footer className="cwp-footer">
					{this.theme.isSimptek ? <div style={{ height: '16px' }} /> : undefined}
					<div className='cwp-fspace' />{this.theme.footer.copyright[lang]}<br />{!this.theme.isSimptek ? <span><Trans i18nKey="simptek-copyright">Powered by <a href="https://www.climative.ai">Climative</a></Trans></span> : undefined}
				</footer>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
	let model = state.buildings.curModel || {};
	return {
		base64_report: state.buildings.loaded_b64_report && state.buildings.loaded_b64_report.base64_report,
		notif: model.notifications,
		building,
		currentUser: state.auth.currentUser,
		isB2CUser: !!state.auth.currentUser?.b2c_domain_hint,
		lastPublished: state.buildings.curModel.lastPublished,
		shareLevel: (!building || building.buildingId === 'MOCK-DATA-123') ? 1 : building?.data_sharing_level || 1,
		customerBuildings: state.auth.customerBuildings ?? null,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomerSettingsPage)));
