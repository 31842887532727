import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateCustomerPrioritiesRequest, getBuildingRequest, getBuildingRequestLM } from "../../../actions/buildings";
import CDataDisclaimer from './CDataDisclaimer';
import { toast } from 'react-toastify';

import { withTranslation, Trans } from "react-i18next";
import i18next from 'i18next';

import './CPriorities.scss';

const { RandomKey } = require('../../../helpers/utils');

class CPriorities extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.T = props.t;

    this.state = {
    }

    this.origJSON = {"money":5,"environment":3,"comfort":3,"energy":1};

    this.pInfo = {
        'money': {
            title: "Saving Money",
            icon: "/images/priority-money.svg"
        },
        'comfort': {
            title: "Improving Comfort",
            icon: "/images/priority-comfort.svg"
        },
        'energy': {
            title: "Reducing my Energy Use",
            icon: "/images/priority-energy.svg"
        },
        'environment': {
            title: "Reducing Carbon Emissions",
            icon: "/images/priority-environment.svg"
        }
    };

    this.state.value = [
        {
            title: "Most important to me",
            key: 5,
            list: [ ],
            max_count: 1
        },
        {
            title: "Important to me",
            key: 3,
            list: [ ],
            max_count: 4
        },
        {
            title: "Somewhat important to me",
            key: 1,
            list: [ ],
            max_count: 4
        },
        {
            title: "Does not affect my decisions",
            key: 0,
            list: [ ],
            max_count: 4
        }
    ];

    for (let key in this.origJSON) {
        const weight = this.origJSON[key];
        const obj = this.state.value.filter((e) => `${e.key}` == `${weight}`)[0];
        if (obj) {
            obj.list.push(key);
        }
    }
  }

  componentDidMount() {
    const buildingId = this.props.currentUser?.currentBuildingId ?? this.props.currentUser?.primaryBuildingId;
    if (buildingId) {
        this.props.dispatch(getBuildingRequest(buildingId));
        this.props.dispatch(getBuildingRequestLM(buildingId));
    }
  }

  componentDidUpdate() {
    this.props.building.customerPrioritiesJSON = this.props.building.customerPrioritiesJSON || JSON.stringify(this.origJSON);
    if (JSON.stringify(this.origJSON) != this.props.building.customerPrioritiesJSON) {
        this.origJSON = JSON.parse(this.props.building.customerPrioritiesJSON);
        for (let obj of this.state.value) {
            obj.list = [];
        }
        for (let key in this.origJSON) {
            const weight = this.origJSON[key];
            const obj = this.state.value.filter((e) => `${e.key}` == `${weight}`)[0];
            if (obj) {
                obj.list.push(key);
            }
        }
        console.log(JSON.stringify(this.state.value, null, 2));
        this.setState({ value: this.state.value, rand: Math.random() });
    }
  }

  moveUp(idx, jdx) {
    if (idx < 1) {
        return;
    }
    let newList = this.state.value[idx-1];
    let oldList = this.state.value[idx];
    if (!newList || (newList.list.length + 1) > newList.max_count) {
        return;
    }
    newList.list.push(oldList.list[jdx]);
    oldList.list.splice(jdx, 1);
    this.setState({ value: this.state.value, rand: Math.random() });
  }

  moveDown(idx, jdx) {
    if (idx >= this.state.value.length) {
        return;
    }
    let newList = this.state.value[idx+1];
    let oldList = this.state.value[idx];
    if (!newList || (newList.list.length + 1) > newList.max_count) {
        return;
    }
    newList.list = [ oldList.list[jdx], ...(newList.list) ];
    oldList.list.splice(jdx, 1);
    this.setState({ value: this.state.value, rand: Math.random() });
  }

  canMoveUp(idx, jdx) {
    if (idx < 1) {
        return false;
    }
    let newList = this.state.value[idx-1];
    if (!newList || (newList.list.length + 1) > newList.max_count) {
        return false;
    }
    return true;
  }

  canMoveDown(idx, jdx) {
    if (idx >= this.state.value.length) {
        return false;
    }
    let newList = this.state.value[idx+1];
    if (!newList || (newList.list.length + 1) > newList.max_count) {
        return false;
    }
    return true;
  }

  savePrio() {
    let json = {};
    for (let cat of this.state.value) {
        const weight = cat.key;
        for (let val of (cat.list || [])) {
            json[val] = parseFloat(weight) || 0;
        }
    }
    this.setState({ loading: true })
    this.props.dispatch(updateCustomerPrioritiesRequest(this.props.building.buildingId, json)).then(() => {
        toast.success(this.T("Sucessfully updated your priorities."));
        this.setState({ loading: false })
        window.location.href = "#/app/customer/view-report";
        this.props.dispatch(getBuildingRequestLM(this.props.building.buildingId));
    });
  }

  liveRegion(text) {
    const el = document.getElementById('live-region-a11y');
    el.innerHTML = "";
    if (el) {
        setTimeout(function() {
            el.innerHTML = text;
        }, 1000);
    }
  }

  cancelPrio() {
    window.location.href = "#/app/customer/view-report";
  }

  render() {
    if (!this.props.show) {
        return (<div/>)
    }

    return (
        <div className={'cust-priorities ' + (this.state.loading ? 'cp-loading' : '')}>
            <h1 className='custp-title'>{this.T("Personalize Your Recommendations")}</h1>
            <CDataDisclaimer copyId={"homeowner-priorities"} />
            <div className='custp-para'>{this.T("Everyone has different priorities when making energy efficiency upgrades.")}</div>
            <div className='custp-para'>{this.T("You can get upgrade packages that are customized based on what you care about by sharing a little info.")}</div>
            <div className='custp-para'><img src='/images/info-dark-blue.svg'/> <div className='custp-info'>{this.T("This is optional and can be updated at any time.")}</div></div>
            <div className='cust-priorities-widget' key={this.key}>
                <h2 className='cprio-header'>{this.T("When making decisions about energy efficiency upgrades, what is most important to you?")}</h2>
                {this.state.value.map((value, idx) => <div className='cust-prio-value' key={'value-' + value.key}>
                    <div className='cust-prio-value-title'>{this.T(value.title)}</div>
                    {!value.list.length ? <div className='cprio-empty-section'>{this.T('This Section is Empty')}</div> :
                    <div className='cprio-value-list'>
                        {value.list.map((item, jdx) => <div className='cprio-item' key={'item-' + item}>
                            <button aria-label={this.T('Move Down') + " - " + this.T(this.pInfo[item].title)} disabled={!this.canMoveDown(idx, jdx)} className={'prio-down ' + (!this.canMoveDown(idx, jdx) ? 'disabled' : '')} onClick={() => {
                                this.moveDown(idx, jdx);
                                this.liveRegion(this.T(this.pInfo[item].title)+ " - "+ this.T("Moved Down"));
                            }}><img alt="" src='/images/prio-down.svg' /></button>
                            <img alt="" src={this.pInfo[item].icon} className='prio-val-icon'/> <div className='prio-val-title'>{this.T(this.pInfo[item].title)}</div>
                            <button aria-label={this.T('Move Up') + " - " + this.T(this.pInfo[item].title)} disabled={!this.canMoveUp(idx, jdx)} className={'prio-up ' + (!this.canMoveUp(idx, jdx) ? 'disabled' : '')} onClick={() => {
                                this.moveUp(idx, jdx);
                                this.liveRegion(this.T(this.pInfo[item].title)+ " - "+ this.T("Moved Up"));
                                }}><img alt="" src='/images/prio-up.svg' /></button>
                        </div>)}
                    </div>}
                </div>)}
            </div>
            <div className='cprio-actions'>
                <button className='cprio-save' onClick={() => this.savePrio()}>{this.T('Save Priorities')}</button>
                <button className='cprio-cancel' onClick={() => this.cancelPrio()}>{this.T('Cancel')}</button>
            </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
	return {
		base64_report: state.buildings.loaded_b64_report && state.buildings.loaded_b64_report.base64_report,
		building,
		currentUser: state.auth.currentUser,
		lastPublished: state.buildings.curModel.lastPublished,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CPriorities)));
