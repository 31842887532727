import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import '../Common.scss';

const { RandomKey } = require('../../../helpers/utils');

class ToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.state = {
      selected: this.props.selected
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
  }

  render() {
    return (
      <button role='switch' aria-checked={this.state.selected ? 'true' : 'false'} className='en-toggle-button' onClick={() => { 
        if (this.props.onChange) {
            this.props.onChange(!this.state.selected);
        }
      }}>
        <img alt='' src={this.state.selected ? '/images/toggle-green-on.svg' : '/images/toggle-green-off.svg'} /> <div className='en-toggle-button-msg'>{this.props.children}</div>
      </button>
    );
  }
}

export default withRouter(connect(() => ({}))(ToggleButton));