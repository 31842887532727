import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import FocusLock from 'react-focus-lock';

import './CDataDisclaimer.scss';

const { RandomKey } = require('../../../helpers/utils');

class CDataDisclaimer extends React.Component {
  constructor(props) {
    super(props);

    this.T = props.t;
    this.key = RandomKey();
    this.state = {
        modalOpen: false
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  getHeader() {
    let content = [];
    switch (this.props.copyId) {
    case 'homeowner-survey-0':
      content.push(<span>Update <b>optional</b> info about your building to improve your home's energy modelling.</span>);
      break;
    case 'homeowner-priorities':
      content.push(<span>Update this <b>optional</b> section to personalize your Retrofit Scenarios.</span>);
      break;
    default:
      return;
    }
    return (<div className='cdd-header'><Trans i18nKey={"cdd-header-" + this.props.copyId}>
      {content}
    </Trans></div>);
  }

  getModal() {
    let content = [];
    switch (this.props.copyId) {
    case 'homeowner-survey-0':
      content.push((<span><Trans i18nKey={"cdd-modal-" + this.props.copyId + "-point-1"}>
        Your Home Rating and Retrofit Scenarios will be updated based on data provided on this screen.
      </Trans></span>));
      content.push((<span><Trans i18nKey={"cdd-modal-" + this.props.copyId + "-point-2"}>
        Building Data you enter about your building, and any derived data may be shared with future homeowners, and with organizations aiming to reduce carbon emissions in your area (like your regional government)
      </Trans></span>));
      content.push((<span><Trans i18nKey={"cdd-modal-" + this.props.copyId + "-point-3"}>
        There is no Personal Data in this survey.
      </Trans></span>));
      break;
    case 'homeowner-priorities':
      content.push((<span><Trans i18nKey={"cdd-modal-" + this.props.copyId + "-point-1"}>
        Data you enter on this screen may be anonymized and shared on a regional level<br/>
        <br/>
        <i>For example, your local government may see that 73% of their residents said saving money is the most important factor in making decisions about energy efficiency.</i>
      </Trans></span>));
      content.push((<span><Trans i18nKey={"cdd-modal-" + this.props.copyId + "-point-2"}>
        Individual data you enter on this screen will NOT be accessible by other users. It will ONLY be accessible by the user who inputted the data (you).
      </Trans></span>));
      break;
    default:
      return;
    }
    return (<div className='cdd-modal-data' role='list'>
      {content.map((item) => (<div className='cdd-point-cont' role='listitem'>
        <img className='cdd-point-icon' alt="" src="/images/cdd-point-blue.svg" /> {item}
      </div>))}
    </div>);
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  render() {
    return (
      <div key={this.key} className={'cust-data-disclaimer'}>
        <div className={'cust-dd-inner'}>
          {this.getHeader()}
          <br/>
          <button className='cdd-more-info-button' onClick={() => this.openModal()}>
            <img src="/images/cdd-info-blue.svg" alt="" className='cdd-mib-info' /> {this.T("How this data will be used")} <img src="/images/chevron-right-blue.svg" alt="" className='cdd-mib-arrow' />
          </button>
          {this.state.modalOpen ? <div className='cdd-modal-cont' id="cdd-modal-cont" onClick={(e) => {
            if (e.target?.id !== 'cdd-modal-cont') {
                return;
            }
            this.closeModal();
            e.stopPropagation();
            return false;
          }}>
            <div className='cdd-modal' role='dialog' aria-modal='true' aria-label={this.T("How this data will be used")} >
              <FocusLock>
                <h2 className='cdd-m-title'>{this.T("How this data will be used")}</h2>
                <button aria-label={this.T("Close")} className='cdd-modal-close' onClick={() => this.closeModal()}><img alt="" src="/images/cdd-modal-close.svg"/></button>
                {this.getModal()}
              </FocusLock>
            </div>
          </div> : undefined}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CDataDisclaimer)));
