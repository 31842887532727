import { logoutUser } from '../actions/auth';
import { Redirect, Route } from 'react-router';
import React from 'react';
import themes from './CustomerPages/Themes';
const { isAuthenticated } = require('../helpers/utils');

export const UserRoute = ({dispatch, component, ...rest}) => {
  if (!isAuthenticated()) {
    if (window.USER_IS_CUSTOMER || window.location.href.indexOf('customer') >= 0) {
      themes.redirectToLogin(false);
      return (<div/>);
    }
    dispatch(logoutUser());
    return (<Redirect to="/login"/>)
  } else {
    return ( // eslint-disable-line
      <Route {...rest} render={props => (React.createElement(component, props))}/>
    );
  }
};

export const PublicRoute = ({dispatch, component, ...rest}) => {
  return (
    <Route {...rest} render={props => (React.createElement(component, props))}/>
  );
};