import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import './CNotifBubble.scss';

const { RandomKey } = require('../../../helpers/utils');

class CNotifBubble extends React.Component {
  constructor(props) {
    super(props);

    this.T = props.t;
    this.key = RandomKey();
    this.state = {};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  hideMe() {
    let hidden = localStorage.getItem('hidden-notif');
    if (hidden) {
        hidden = JSON.parse(hidden);
    }
    else {
        hidden = {};
    }
    hidden[this.props.id] = true;
    localStorage.setItem('hidden-notif', JSON.stringify(hidden));
    this.key = RandomKey();
    this.setState({ rand: RandomKey() });
  }

  viewClick() {
    /*if (this.props.id) {
        
    }
    else {*/
        window.location.href = "#/app/customer/notifications";
    //}
  }

  render() {
    let hidden = localStorage.getItem('hidden-notif');
    if (hidden) {
        hidden = JSON.parse(hidden);
    }
    else {
        hidden = {};
    }
    if (hidden[this.props.id]) {
        return (<div style={{display: "none"}}/>)
    }
    return (
      <div key={this.key} className={'cust-notif-bubble-cont'}>
        <h2 className='cnb-title'><img className='cnbt-notif-icon' src="/images/notif-icon-yellow.svg" alt="" /> {this.props.title} <button className='cnbt-hide' onClick={() => this.hideMe()}><img alt="hide notification" src="/images/notif-hide-icon.svg" /></button></h2>
        <div className='cnb-desc'>{this.props.description}</div>
        <button autoFocus={true} onClick={() => this.viewClick()} aria-label={this.T('Go to alerts and notifications page')} className='cnb-view'>{this.props.id ? this.T("View Alert") : this.T("View Alerts")} <img src="/images/notif-details-icon.svg"/></button>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CNotifBubble)));
