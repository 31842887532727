import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";

import './CFaqButton.scss';

const { RandomKey } = require('../../../helpers/utils');

class CFaqButton extends React.Component {
    constructor(props) {
        super(props);

        this.T = props.t;
        this.key = RandomKey();
        this.state = {
        };
    }

    render() {
        return (
            <button aria-label={this.props.ariaLabel} id={this.props.id} className={'c-faq-button-cont ' + (this.props.extraClass ?? '') + ' ' + (this.props.alwaysSmall ? 'cfb-always-small' : '') + ' ' + (this.props.alwaysLarge ? 'cfb-always-large' : '')} key={this.key} onClick={this.props.onClick}>
                {!this.props.alwaysSmall && !this.props.alwaysLarge ? <img className='c-faq-button-large' alt="" src="/images/faq-button.svg" /> : undefined}
                {!this.props.alwaysSmall && !this.props.alwaysLarge ? <img className='c-faq-button-small' alt="" src="/images/faq-button-small.svg" /> : undefined}
                {this.props.alwaysSmall ? <img alt="" src="/images/faq-button-small.svg" /> : undefined}
                {this.props.alwaysLarge ? <img alt="" src="/images/faq-button.svg" /> : undefined}
            </button>
        );
    }

}

function mapStateToProps(state, ownProps) {
    return {};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CFaqButton)));
