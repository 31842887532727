import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ConnectedRouter } from 'connected-react-router';
import { getHistory } from '../index';
import { UserRoute, PublicRoute } from './RouteComponents';
import themes from './CustomerPages/Themes';
import '../styles/theme.scss';
import LayoutComponent from '../components/Layout';

const { isAuthenticated } = require('../helpers/utils');

const CloseButton = ({closeToast}) => <i onClick={closeToast} className="la la-close notifications-close"/>

let lastURL;
window.addEventListener('popstate', function(event) {
  if (lastURL !== window.location.href) {
    if (window.location.href.indexOf("id_token=") < 0 && window.location.href.indexOf("&error_description") < 0) {
      if (!window.HIDE_INTERCOM) {
        window.Intercom("update");
      }
      if (window.TRACK_EVENT) {
        window.TRACK_EVENT({eventId: 'page-change'});
      }
    }
    lastURL = window.location.href;
  }
});

class App extends React.PureComponent {

  render() {
    if (window.location.href.indexOf("id_token=") >= 0) {
			if (!window.ID_TOKEN) {
        window.ID_TOKEN = window.location.href.split("id_token=")[1];
        window.localStorage.setItem('b2c_token', window.ID_TOKEN);
        window.close();
      }
      return (<div/>);
		} else if (window.location.href.indexOf("&error_description") >= 0) {
      window.localStorage.setItem('b2c_error', true);
      window.close();
    }

    let IS_CUSTOMER = !!(window.USER_IS_CUSTOMER || window.location.href.indexOf('/customer/') >= 0 || window.location.href.indexOf('/customer-login') >= 0);
    let IS_PUBLIC = window.location.href.indexOf('/public/') >= 0 || window.location.href.indexOf('/customer/view-report') >= 0;
    if (window.location.href.indexOf('/customer/') >= 0 && !isAuthenticated()) {
      if (themes.autodetect()?.advisorExperience) {
        window.location.href = '#/public/homeowner-map';
        return;
      }
      else {
        if (window.location.href.indexOf('/customer/view-report') === -1 && window.location.href.indexOf('/customer/notifications') === -1) {
          window.location.href = '#/app/customer/view-report';
          return;
        }
      }
    }
    let IN_ORG_SITE = !!window.ORG_INFO?.org_id;

    return (
        <div>
            <div role='status' aria-atomic='true' aria-live='polite' aria-relevant='additions'>
            <ToastContainer
                autoClose={5000}
                hideProgressBar
                closeButton={<CloseButton/>}
            />
            </div>
            <ConnectedRouter history={getHistory()}>
                <HashRouter>
                    <Switch>
                      <PublicRoute path="/public/homeowner-map" dispatch={this.props.dispatch} component={LayoutComponent} />
                      {IN_ORG_SITE && <Route path="/" exact render={() => <Redirect to="/public/homeowner-map"/>}/>}
                      {!IN_ORG_SITE && <Route path="/" exact render={() => <Redirect to="/public/homeowner-map"/>}/>}
                      {!IN_ORG_SITE && <Route path="/app" exact render={() => <Redirect to="/app/main"/>}/>}
                      {!IN_ORG_SITE && <Route path='/e1' component={() => { 
                          themes.redirectToLogin(true);
                          return (<div/>);
                      }}/>}
                      {isAuthenticated() ? <UserRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent}/> : 
                                                 <PublicRoute path="/app" dispatch={this.props.dispatch} component={LayoutComponent}/>}
                      {!IN_ORG_SITE && <Route path="/customer-login" exact render={() => <Redirect to="/public/homeowner-map"/>}/>}
                      <Route path="/error" exact render={() => <Redirect to="/public/homeowner-map"/>}/>
                      {(IS_PUBLIC || IS_CUSTOMER || IN_ORG_SITE) && <Redirect from="*" to="/public/homeowner-map"/>}
                    </Switch>
                </HashRouter>
            </ConnectedRouter>
        </div>
    );
  }
}

const mapStateToProps = store => ({
  currentUser: store.auth.currentUser,
  loadingInit: store.auth.loadingInit,
});

export default connect(mapStateToProps)(App);
