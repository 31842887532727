import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './CStatBlock.scss';

const { RandomKey } = require('../../../helpers/utils');
const { ParseFloat2 } = require('../../../helpers/utils');

class CStatBlock extends React.Component {
  constructor(props) {
    super(props);

    this.key = RandomKey();
    this.state = {};
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let negative = ParseFloat2(this.props.stat0) < 0 || ParseFloat2(this.props.stat1) < 0 || ParseFloat2(this.props.stat2) < 0;
    let stat0 = this.props.stat0 ? `${this.props.stat0}` : undefined;
    let stat1 = this.props.stat1 ? `${this.props.stat1}` : undefined;
    let stat2 = this.props.stat2 ? `${this.props.stat2}` : undefined;
    let stat0Icon = this.props.stat0Icon ? `${this.props.stat0Icon}` : undefined;
    let stat1Icon = this.props.stat1Icon ? `${this.props.stat1Icon}` : undefined;
    let stat2Icon = this.props.stat2Icon ? `${this.props.stat2Icon}` : undefined;
    let color = this.props.color;
    
    let stat1IconAlt = this.props.stat1IconAlt ? `${this.props.stat1IconAlt}` : "";

    if (negative && stat0) {
      stat0 = `${stat0}`.replace(/-/g, '');
    }
    if (negative && stat1) {
      stat1 = `${stat1}`.replace(/-/g, '');
    }
    if (negative && stat2) {
      stat2 = `${stat2}`.replace(/-/g, '');
    }
    if (stat0Icon && negative) {
      if (stat0Icon.indexOf('down.svg') >= 0) {
        stat0Icon = '/images/negative-up-arrow.svg';
      }
    }
    if (stat1Icon && negative) {
      if (stat1Icon.indexOf('down.svg') >= 0) {
        stat1Icon = '/images/negative-up-arrow.svg';
      }
    }
    if (stat2Icon && negative) {
      if (stat2Icon.indexOf('down.svg') >= 0) {
        stat2Icon = '/images/negative-up-arrow.svg';
      }
    }
    if (negative) {
      color = undefined;
      if (this.props.stat0Negative !== undefined) {
        stat0 = `${this.props.stat0Negative}`;
      }
      if (this.props.stat1Negative !== undefined) {
        stat1 = `${this.props.stat1Negative}`;
      }
      if (this.props.stat2Negative !== undefined) {
        stat2 = `${this.props.stat2Negative}`;
      }
    }
    return (
      <div key={this.key} className={"cstat-block " + (this.props.className || "") }>
        {stat0 !== undefined ? <div className={'cstb-0 ' + (negative ? 'ra-negative-color' : '')} style={color ? {"color": color} : {}}>{stat0Icon?.length ? <img src={stat0Icon} /> : undefined}{stat0}</div> : undefined}
        <div className={'cstb-1 ' + (negative ? 'ra-negative-color' : '')} style={color ? {"color": color} : {}}>{stat1Icon?.length ? <img alt={stat1IconAlt} src={stat1Icon} /> : undefined}{stat1}</div>
        <div className={'cstb-2 ' + (negative ? 'ra-negative-color' : '')} style={color ? {"color": color} : {}}>{stat2Icon?.length ? <img alt='' src={stat2Icon} /> : undefined}{stat2}</div>
      </div>
    );
  }
}

export default withRouter(connect(() => ({}))(CStatBlock));
