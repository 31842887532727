import config from "../config";
import jwt from "jsonwebtoken";

export const NotificationTitle = (notif, T, equipmentNameOnly) => {
    switch (notif.type) {
    case 'alert':
        switch (notif.sub_type) {
            case 'ffa-replacement':
                return T(equipmentNameOnly ? 'Furnace (Forced Air)' : 'Furnace (Forced Air) Replacement');
            case 'boiler-replacement':
                return T(equipmentNameOnly ? 'Boiler' : 'Boiler Replacement');
            case 'woodstove-replacement':
                return T(equipmentNameOnly ? 'Wood Stove' : 'Wood Stove Replacement');
            case 'electricbaseboard-replacement':
                return T(equipmentNameOnly ? 'Electric Baseboard' : 'Electric Baseboard Replacement');
            case 'heatpump-replacement':
                return T(equipmentNameOnly ? 'Heat Pump' : 'Heat Pump Replacement');
            case 'fireplace-replacement':
                return T(equipmentNameOnly ? 'Fireplace' : 'Fireplace Replacement');
            case 'ctwh-replacement':
                return T(equipmentNameOnly ? 'Conventional/ Tank Water Heater' : 'Conventional/ Tank Water Heater Replacement');
            case 'tiwh-replacement':
                return T(equipmentNameOnly ? 'Tankless Instantaneous Water Heater' : 'Tankless Instantaneous Water Heater Replacement');
            case 'swh-replacement':
                return T(equipmentNameOnly ? 'Solar Water Heater' : 'Solar Water Heater Replacement');
            case 'hpwh-replacement':
                return T(equipmentNameOnly ? 'Heat Pump Water Heater' : 'Heat Pump Water Heater Replacement');
            case 'cwh-replacement':
                return T(equipmentNameOnly ? 'Condensing Water Heater' : 'Condensing Water Heater Replacement');
            case 'cb-replacement':
                return T(equipmentNameOnly ? 'Combination Boiler' : 'Combination Boiler Replacement');
            case 'csps-replacement':
                return T(equipmentNameOnly ? 'Central Single Package System' : 'Central Single Package System Replacement');
            case 'cssd-replacement':
                return T(equipmentNameOnly ? 'Central Split System (Ducted)' : 'Central Split System (Ducted) Replacement');
            case 'cac-replacement':
                return T(equipmentNameOnly ? 'Conventional A/C' : 'Conventional A/C Replacement');
            case 'mshp-replacement':
                return T(equipmentNameOnly ? 'Mini-Split Heat Pump (Ductless)' : 'Mini-Split Heat Pump (Ductless) Replacement');
            case 'wac-replacement':
                return T(equipmentNameOnly ? 'Window A/C' : 'Window A/C Replacement');
            default:
                break;
        }
        break;
    case 'notif':
        switch (notif.sub_type) {
        case 'report-updated':
            return T('Home Energy Report Updated');
            break;
        default:
            break;
        }
        break;
    default:
        break;
    }
    return T('Unknown');
};

export const GetAllNotificationTitles = (T) => {
    let ret = [];

    let notif = { type: 'alert' };
    let sub_types = [ 'ffa-replacement', 'boiler-replacement', 'woodstove-replacement', 'electricbaseboard-replacement', 'heatpump-replacement', 'fireplace-replacement', 'ctwh-replacement', 'tiwh-replacement', 'swh-replacement', 'hpwh-replacement', 'cwh-replacement', 'cb-replacement', 'csps-replacement', 'cssd-replacement', 'cac-replacement', 'mshp-replacement', 'wac-replacement' ]
    for (let sub_type of sub_types) {
        notif.sub_type = sub_type;
        ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationTitle(notif, T), equipmentNameOnly: false });
        ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationTitle(notif, T, true), equipmentNameOnly: true });
    }

    notif = { type: 'notif' };
    sub_types = [ 'report-updated' ];
    for (let sub_type of sub_types) {
        notif.sub_type = sub_type;
        ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationTitle(notif, T), equipmentNameOnly: false });
        ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationTitle(notif, T, true), equipmentNameOnly: true });
    }

    ret.push({ notif: null, tiutle: T('Unknown') });

    return ret;
};

export const NotificationDescription = (notif, T) => {
    switch (notif.type) {
    case 'alert':
        let eqName = '';
        switch (notif.sub_type) {
        case 'ffa-replacement':
            eqName = 'furnace (forced air)';
            break;
        case 'boiler-replacement':
            eqName = 'boiler';
            break;
        case 'woodstove-replacement':
            eqName = 'wood stove';
            break;
        case 'electricbaseboard-replacement':
            eqName = 'electric baseboard';
            break;
        case 'heatpump-replacement':
            eqName = 'heat pump';
            break;
        case 'fireplace-replacement':
            eqName = 'fireplace';
            break;
        case 'ctwh-replacement':
            eqName = 'conventional/tank water heater';
            break;
        case 'tiwh-replacement':
            eqName = 'tankless instantaneous water heater';
            break;
        case 'swh-replacement':
            eqName = 'solar water heater';
            break;
        case 'hpwh-replacement':
            eqName = 'heat pump water heater';
            break;
        case 'cwh-replacement':
            eqName = 'condensing water heater';
            break;
        case 'cb-replacement':
            eqName = 'combination boiler';
            break;
        case 'csps-replacement':
            eqName = 'central single package system';
            break;
        case 'cssd-replacement':
            eqName = 'central split system (ducted)';
            break;
        case 'cac-replacement':
            eqName = 'conventional a/c';
            break;
        case 'mshp-replacement':
            eqName = 'mini-split heat pump (ductless)';
            break;
        case 'wac-replacement':
            eqName = 'window a/c';
            break;
        default:
            break;
        }
        eqName = T(eqName);
        let eqNamePos = eqName + (eqName.endsWith('s') ? "'" : "'s");

        return `${T('A typical')} ${eqName} ${T('has an expected life of around')}` + ' ' +
                `${notif.notif_data?.max_age}` + ' ' + T('years.') +
                '\n\n' + `${T('Your')} ${eqNamePos} estimated age on file is` + ' ' +
                `${notif.notif_data?.cur_age}` + ' ' + T('years.') + ' ' +
                T('It is recommended you start looking into replacement equipment.');
        break;
    case 'notif':
        switch (notif.sub_type) {
        case 'report-updated':
            if (notif.triggered_by_type === 'rp-settings-change') {
                return T('Your Retrofit Scenarios were updated.');
            }
            else if (notif.triggered_by_type === 'survey') {
                return T('Your Home Rating and Retrofit Scenarios were updated.');
            }
            else if (notif.triggered_by_type === 'model-updates') {
                return T('Your Home Rating and Retrofit Scenarios were updated.');
            }
            else {
                return T('Your Home Rating and Retrofit Scenarios were updated.');
            }
            break;
        default:
            break;
        }
        break;
    default:
        break;
    }
    return '';
};

export const GetAllNotificationDescriptions = (T) => {
    let ret = [];

    let notif = { type: 'alert', notif_data: { max_age: 10, cur_age: 12 } };
    let sub_types = [ 'ffa-replacement', 'boiler-replacement', 'woodstove-replacement', 'electricbaseboard-replacement', 'heatpump-replacement', 'fireplace-replacement', 'ctwh-replacement', 'tiwh-replacement', 'swh-replacement', 'hpwh-replacement', 'cwh-replacement', 'cb-replacement', 'csps-replacement', 'cssd-replacement', 'cac-replacement', 'mshp-replacement', 'wac-replacement' ]
    for (let sub_type of sub_types) {
        notif.sub_type = sub_type;
        ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationDescription(notif, T) });
    }

    notif = { type: 'notif' };
    sub_types = [ 'report-updated' ];
    for (let sub_type of sub_types) {
        notif.sub_type = sub_type;
        notif.triggered_by_type = 'rp-settings-change'; ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationDescription(notif, T) });
        notif.triggered_by_type = 'survey'; ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationDescription(notif, T) });
        notif.triggered_by_type = 'model-updates'; ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationDescription(notif, T) });
        notif.triggered_by_type = ''; ret.push({ notif: JSON.parse(JSON.stringify(notif)), title: NotificationDescription(notif, T) });
    }

    ret.push({ notif: null, tiutle: T('Unknown') });

    return ret;
};

export const NotificationTriggeredByName = (notif, T) => {
    switch (notif.type) {
    case 'alert':
        return null;
        break;
    case 'notif':
        switch (notif.sub_type) {
        case 'report-updated':
            if (notif.triggered_by_type === 'rp-settings-change') {
                return T('Recommendation Personalization Settings Change');
            }
            else if (notif.triggered_by_type === 'survey') {
                return T('Survey Submission');
            }
            else if (notif.triggered_by_type === 'model-updates') {
                return T('Structure Data Automatically Updated');
            }
            break;
        default:
            break;
        }
        break;
    default:
        break;
    }
    return null;
};

export const GetAllNotificationTriggeredByNames = (T) => {
    return [
        T('Recommendation Personalization Settings Change'),
        T('Survey Submission'),
        T('Structure Data Automatically Updated')
    ];
}

export const GetRecIcon = (rec_name, T) => {
    let tok = `${rec_name || ''}`.split(" ")[0];
    switch (tok) {
        case "upgrade_ceiling":
            return "upgrade-ceiling";
            break;
        case "upgrade_foundation":
            return "upgrade-foundation";
            break;
        case "upgrade_walls":
            return "upgrade-walls";
            break;
        case "upgrade_windows_doors":
            return "upgrade-windows-doors";
            break;
        case "upgrade_air_sealing":
            return "upgrade-air-sealing";
            break;
        case "mini_split_heat_pump":
            return "upgrade-heat-pump";
            break;
        case "central_heat_pump":
            return "upgrade-heat-pump";
            break;
        case "high_eff_HRV":
            return "upgrade-hrv";
            break;
        case "drain_water_heat_recovery":
            return "upgrade-drain-water";
            break;
        case "heat_pump_water_heater":
            return "upgrade-water-heating";
            break;
        case "electric_storage_water_heater":
            return "upgrade-water-heating";
            break;
        default:
            return "upgrade-plan";
    }
};

export const GetRecName = (rec_name, T, model) => {
    let tok = `${rec_name || ''}`.split(" ")[0];
    switch (tok) {
        case "upgrade_ceiling":
            return T("Ceiling/Attic Insulation");
            break;
        case "upgrade_foundation":
            return T("Foundation Insulation");
            break;
        case "upgrade_walls":
            return T("Wall Insulation");
            break;
        case "upgrade_windows_doors":
            return T("Windows & Doors");
            break;
        case "upgrade_air_sealing":
            return T("Air Sealing");
            break;
        case "mini_split_heat_pump":
            return T("Mini-Split Heat Pump");
            break;
        case "central_heat_pump":
            return model?.property?.heat_pump_upgrade_type === 'geo' ? T('Geothermal Heat Pump') : T("Central Heat Pump");
            break;
        case "high_eff_HRV":
            return T("HRV");
            break;
        case "drain_water_heat_recovery":
            return T("Drain Water Heat Recovery");
            break;
        case "heat_pump_water_heater":
            return T("Heat Pump Water Heater");
            break;
        case "electric_storage_water_heater":
            return T("Water Heater");
            break;
        default:
            return T("???");
    }
};

export const GetRecNameModeled = (rec_name, T, model) => {
    let tok = `${rec_name || ''}`.split(" ")[0];
    switch (tok) {
        case "upgrade_ceiling":
            return T("Ceiling Insulation Modeled");
            break;
        case "upgrade_foundation":
            return T("Foundation Insulation Modeled");
            break;
        case "upgrade_walls":
            return T("Wall Insulation Modeled");
            break;
        case "upgrade_windows_doors":
            return T("Windows & Doors Modeled");
            break;
        case "upgrade_air_sealing":
            return T("Air Sealing Modeled");
            break;
        case "mini_split_heat_pump":
            return T("Mini-Split Heat Pump Modeled");
            break;
        case "central_heat_pump":
            return model?.property?.heat_pump_upgrade_type === 'geo' ? T('Geothermal Heat Pump Modelled') : T("Central Heat Pump Modeled");
            break;
        case "high_eff_HRV":
            return T("HRV Modeled");
            break;
        case "drain_water_heat_recovery":
            return T("Drain Water Heat Recovery Modeled");
            break;
        case "heat_pump_water_heater":
            return T("Heat Pump Water Heater Modeled");
            break;
        case "electric_storage_water_heater":
            return T("Water Heater Modeled");
            break;
        default:
            return T("???");
    }
};

export const GetRecData = (rec_name, rec, model, building, T) => {
    let tok = `${rec_name || ''}`.split(" ")[0];
    //console.log(rec_name, rec, model);
    switch (tok) {
        case "upgrade_ceiling":
            return [
                { title: T('Starting Insulation Level'), value: 'R' + Math.round(10 * model?.property?.nominalRCeilingPre) / 10 },
                { title: T('Insulation Added'), value: 'R' + Math.round(10 * (model?.property?.nominalRCeilingPost-model?.property?.nominalRCeilingPre)) / 10 },
                { title: T('New Insulation Level'), value: 'R' + Math.round(10 * model?.property?.nominalRCeilingPost) / 10 }
            ];
            break;
        case "upgrade_foundation":
            return [
                { title: T('Starting Insulation Level'), value: 'R' + Math.round(10 * model?.property?.nominalRFoundPre) / 10 },
                { title: T('Insulation Added'), value: 'R' + Math.round(10 * (model?.property?.nominalRFoundPost-model?.property?.nominalRFoundPre)) / 10 },
                { title: T('New Insulation Level'), value: 'R' + Math.round(10 * model?.property?.nominalRFoundPost) / 10 }
            ];
            break;
        case "upgrade_walls":
            return [
                { title: T('Starting Insulation Level'), value: 'R' + Math.round(10 * model?.property?.nominalRWallPre) / 10 },
                { title: T('Insulation Added'), value: 'R' + Math.round(10 * (model?.property?.nominalRWallPost-model?.property?.nominalRWallPre)) / 10 },
                { title: T('New Insulation Level'), value: 'R' + Math.round(10 * model?.property?.nominalRWallPost) / 10 }
            ];
            break;
        case "upgrade_windows_doors":           
            return [
                { title: T('# of Windows Replaced'), value: (building?.totalNumberOfInstalledEStarWindowsPostUpgrade - building?.totalNumberOfInstalledEStarWindows) + "/" + building?.totalNumberOfInstalledWindows },
                { title: T('# of Doors Replaced'), value: (building?.totalNumberOfInstalledEStarDoorsPostUpgrade - building?.totalNumberOfInstalledEStarDoors) + "/" + building?.totalNumberOfInstalledDoors }
            ];
            break;
        case "upgrade_air_sealing":
            return [];
            break;
        case "mini_split_heat_pump":
            return [
                { title: T('Type'), value: T('Mini-Split Heat Pump') },
                { title: T('Capacity'), value: Math.round(model?.property?.heatpump_capacity_system_capacity_watts * 3.412141633) + ' ' + T('btu/hr') },
                { title: T('Heating Efficiency Rating'), value: Math.round(10 * model?.property?.heatpump_cop / 0.293) / 10 + ' HSPF' }
            ];
            break;
        case "central_heat_pump":
            return [
                { title: T('Type'), value: model?.property?.heat_pump_upgrade_type === 'geo' ? T('Geothermal Heat Pump') : T('Central Heat Pump') },
                { title: T('Capacity'), value: Math.round(model?.property?.heatpump_capacity_system_capacity_watts * 3.412141633) + ' ' + T('btu/hr') },
                { title: T('Heating Efficiency Rating'), value: Math.round(10 * model?.property?.heatpump_cop / 0.293) / 10 + ' HSPF' }
            ];
            break;
        case "high_eff_HRV":
            return [];
            break;
        case "drain_water_heat_recovery":
            return [];
            break;
        case "heat_pump_water_heater":
            return [];
            break;
        case "electric_storage_water_heater":
            return [];
            break;
        default:
            return [];
    }
};

export const GetRecComments = (rec_name, rec, model) => {
    let tok = `${rec_name || ''}`.split(" ")[0];
    let obj = model?.report_override?.recommendations ?? {};
    switch (tok) {
        case "upgrade_ceiling":
            return obj?.upgrade_ceiling?.comment;
            break;
        case "upgrade_foundation":
            return obj?.upgrade_foundation?.comment;
            break;
        case "upgrade_walls":
            return obj?.upgrade_walls?.comment;
            break;
        case "upgrade_windows_doors":
            return obj?.upgrade_windows_doors?.comment;
            break;
        case "upgrade_air_sealing":
            break;
        case "mini_split_heat_pump":
            return obj?.upgrade_heating?.comment;
            break;
        case "central_heat_pump":
            return obj?.upgrade_heating?.comment;
            break;
        case "high_eff_HRV":
            break;
        case "drain_water_heat_recovery":
            break;
        case "heat_pump_water_heater":
            break;
        case "electric_storage_water_heater":
            break;
        default:
            break;
    }
};

export const GetRecDescription = (rec_name, T) => {
    let tok = `${rec_name || ''}`.split(" ")[0];
    switch (tok) {
        case "upgrade_ceiling":
            return T("Like a warm hat for your home; keeps it warmer in winter and cooler in summer, reducing energy bills.");
            break;
        case "upgrade_foundation":
            return T("Like winter boots for your home; leads to warmer floors, fewer drafts, and lower heating costs.");
            break;
        case "upgrade_walls":
            return T("Acts like a snug blanket around your home, locking in warmth and cutting energy expenses.");
            break;
        case "upgrade_windows_doors":
            return T("More modern windows have better weather seals and insulating glass panels, helping to lower heating and cooling costs.");
            break;
        case "upgrade_air_sealing":
            return T("Windproofs your home, ensuring a consistent, controllable temperature, fewer drafts, and reduced energy costs. Air sealing a drafty home is one of the best measures to make you feel more comfortable.");
            break;
        case "mini_split_heat_pump":
            return T("A reversible air conditioner; cools in summer, warms in winter. Generally 2-4x more energy-efficient than traditional systems.");
            break;
        case "central_heat_pump":
            return T("A reversible whole-home air conditioner; cools in summer, warms in winter. Generally 2-4x more energy-efficient than traditional systems.");
            break;
        case "high_eff_HRV":
            return T("Brings fresh, dry air in and removes stale, humid air at a constant rate, while conserving heat. This ensures a comfortable and cost-effective home atmosphere.");
            break;
        case "drain_water_heat_recovery":
            return T("Recycles heat from your drainwater, reducing the energy that your hot water heater uses. ");
            break;
        case "heat_pump_water_heater":
            return T("An efficient way to heat water using the warmth from the surrounding air or outdoors. Generally 2-4x more energy-efficient than traditional systems.");
            break;
        case "electric_storage_water_heater":
            return T("A giant insulated thermos bottle for hot water; similar efficiency to gas heaters but more eco-friendly.");
            break;
        default:
            return T("???");
    }
};

export const ParseFloat2 = (x) => {
    if (typeof x !== 'string') {
      return x;
    }
    let cleaned = `${x}`.replace(/[$,%]+/g, '');
    return parseFloat(cleaned);
};

export const RecolorIcon = (img, r, g, b) => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = true;
    ctx.webkitImageSmoothingEnabled = true;
    ctx.mozImageSmoothingEnabled = true;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0);
    const data = ctx.getImageData(0, 0, canvas.width, canvas.height);
    for (let i=0; i<data.data.length; i+=4) {
        //let alpha = data.data[i+3] / 255.;
        data.data[i+0] = r;
        data.data[i+1] = g;
        data.data[i+2] = b;
    }
    ctx.putImageData(data, 0, 0);
    return canvas;
};

// https://stackoverflow.com/questions/3916191/download-data-url-file
export const DownloadURI = (uri, name) => {
    let link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const isAuthenticated = () => {
    const token = localStorage.getItem("token");
    if (!config.isBackend && token) return true;
    if (!token) return;
    const date = new Date().getTime() / 1000;
    const data = jwt.decode(token);
    if (!data) return;
    return date < data.exp;
}

export const RandomKey = () => {
    return `cc-${Math.round(Math.random()*1e9)})-${Math.round(Math.random()*1e9)})`;
};

export const onBlurOutside = (e, cbk) => { // https://stackoverflow.com/questions/71529999/how-to-prevent-child-with-position-absolute-from-triggering-onblur-event-of
    e = e || window.event;
    const currentTarget = e.currentTarget;
    requestAnimationFrame(() => {
        if (!currentTarget.contains(document.activeElement)) {
            cbk();
        }
    });
};

export const shuffleArray = (arr) => {
    let ret = [];
    for (let v of arr) {
        ret.splice(Math.floor(Math.random() * ret.length), 0, v);
    }
    return ret;
};