import {
    RECEIVED_BUILDING,
    RECEIVED_BUILDINGS,
    RECEIVED_BUILDING_LM,
    RECEIVING_BUILDING_LM,
    RECEIVING_BUILDINGS,
    UPDATING_BUILDING,
    UPDATED_BUILDING,
    VA_STATUS_UPDATED,
    UPDATED_REPORT_B64
} from '../actions/buildings';

const defaultState = {
    curBuilding: {},
    curModel: {},
    data: [],
    isReceiving: false,
    isReceivingLM: false,
    isUpdating: false,
    vaStatus: 0,
    loaded_b64_report: null
};

export default function buildingsReducer(state = defaultState, action) {

    const fClone = (x) => (JSON.parse(JSON.stringify(x)));

    switch (action.type) {
        case RECEIVED_BUILDING:
            return Object.assign({}, state, {
                curBuilding: fClone(action.payload),
                statusText: action.payload?.building?.statusText,
                status: action.payload?.building?.status,
                isReceiving: false,
            });
        case RECEIVING_BUILDINGS:
            return Object.assign({}, state, {
                isReceiving: true
            });
        case RECEIVING_BUILDING_LM:
            return Object.assign({}, state, {
                isReceivingLM: true
            });
        case RECEIVED_BUILDINGS:
            return Object.assign({}, state, {
                data: fClone(action.payload),
                isReceiving: false,
            });
        case RECEIVED_BUILDING_LM:
            return Object.assign({}, state, {
                curBuilding: fClone({ ...(state.curBuilding), confidenceScore: action.payload.confidenceScore }),
                curModel: fClone(action.payload),
                isReceivingLM: false,
            });
        case UPDATED_BUILDING:
            if ((typeof action.payload) !== 'object') {
                return Object.assign({}, state, {
                    isUpdating: false
                });
            }
            else {
                let index = state.data.findIndex(p => p.id === action.payload.id);
                return Object.assign({}, state, {
                    data: state.data.map((p, i) => {
                        if (i === index) {
                            return Object.assign({}, p, action.payload);
                        }
                        return p;
                    }),
                    curBuilding: {...fClone(state.curBuilding), ...fClone(action.payload)},
                    isUpdating: false,
                });
            }
        case UPDATED_REPORT_B64:
            return Object.assign({}, state, {
                loaded_b64_report: action.payload
            });
        case UPDATING_BUILDING:
            return Object.assign({}, state, {
                isUpdating: true
            });
        case VA_STATUS_UPDATED:
            return Object.assign({}, state, {
                vaStatus: action.payload.status || 0
            });
        default:
            return state;
    }
}
