import { getBuildingRequest, getBuildingRequestLM } from "../../actions/buildings";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { withTranslation, Trans } from "react-i18next";

import "./SuccessPage.scss";
import Themes from "./Themes";
import "./Themes.scss";

/////

class SuccessPage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			rand: 0,
			buildingInfoLoaded: false,
		};

		this.theme = Themes.autodetect();
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

	componentDidMount() {
		const buildingId = this.props.currentUser?.currentBuildingId ?? this.props.currentUser?.primaryBuildingId;
		if (buildingId && this.props.show) {
			this.props.dispatch(getBuildingRequest(buildingId));
			this.props.dispatch(getBuildingRequestLM(buildingId));
		}
		setTimeout(() => {
			document.getElementById("csp-text-primary")?.focus();
		}, 1000);
	}

	componentDidUpdate(prevProps) {
		if (this.props.building && !this.state.buildingInfoLoaded) {
			this.setState({
				buildingInfoLoaded: true,
				rand: Math.random(),
			});
		}
		setTimeout(() => {
			document.getElementById("csp-text-primary")?.focus();
		}, 1000);
	}

	render() {
		
		if (!this.props.show) {
			return (<div/>)
		}
		else if (!this.props.currentUser) {
			window.location.href = "#/app/customer/view-report";
			return (<div/>)
		}

		document.body.className = "customer-page " + this.theme.className;

		const lang = `${this.T('hmp-lang-code')}`.toLowerCase();

		if (!this.state.buildingInfoLoaded) {
			return <div className={"customer-success-page " + this.theme.className}></div>;
		}

		let text1 = "",
			text2 = "";
		if (this.props.type == "request") {
			text1 = this.T("Your request has been submitted. A service provider will send you an offer soon as possible.");
			text2 = this.T("You will be notified via email when you get new offers. You will be able to see any offers on Energy Advisor.");
		} else if (this.props.type == "booking") {
			text1 = this.T("Success!<br/>You have requested a Video Home Energy Assessment.");
			text2 = this.T("Your energy advisor will reach out to you shortly to book your appointment.");
		} else if (this.props.type == "survey") {
			text1 = this.T("Home Energy Report updated.");
			text2 = this.T("Your home’s energy modelling has been updated based on the updated building information you submitted.");
		}

		if (this.props.show) {
			if (this.props.type === 'survey') {
				document.title = this.T('Home Energy Report updated.') + ' - ' + this.T('Climative Low Carbon Communities');
			}
			else {
				document.title = this.T('Action Successful') + ' - ' + this.T('Climative Low Carbon Communities');
			}			
		}

		return (
			<div className="customer-success-page">
				<div className="csp-non-footer">
					<div className="csp-body">
						<img alt="" src="/images/wizard-finished.svg" />
						<br />
						<div id="csp-text-primary" className="csp-text1" tabIndex={-1} dangerouslySetInnerHTML={{ __html: text1 }}></div><br/>
						<div className="csp-text2" dangerouslySetInnerHTML={{ __html: text2 }}></div><br/>
						<button className="csp-homepage-button" onClick={() => (window.location.href = "#/app/customer/view-report")}>
							{this.T('Go to Home Page')}
						</button>
					</div>
				</div>
				<footer className="csp-footer">
					{this.theme.isSimptek ? <br/> : undefined}
					<div className='cwp-fspace' />{this.theme.footer.copyright[lang]}<br />{!this.theme.isSimptek ? <span><Trans i18nKey="simptek-copyright">Powered by <a href="https://www.climative.ai">Climative</a></Trans></span> : undefined}
				</footer>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
	let model = state.buildings.curModel || {};
	let model2 = model.LPUB;
	let statusText = state.buildings.statusText;
	let status = state.buildings.status;
	return {
		modelType1: model,
		modelType2: model2,
		building,
		statusText,
		status,
		currentUser: state.auth.currentUser,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(SuccessPage)));
