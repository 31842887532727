import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import Themes from "../Themes";
import "../Themes.scss";

import './CHomeRatingCard.scss';
import '../CustomerReport.scss';
import CRatingBubble from './CRatingBubble';
import CFaqButton from './CFaqButton';

const { listsData } = require('../../../helpers/audit-enums');
const { RecolorIcon } = require('../../../helpers/utils');
const { RandomKey } = require('../../../helpers/utils');

let THEME;

class CHomeRatingCard extends React.Component {
  constructor(props) {
    super(props);

    this.T = props.t;

    this.key = RandomKey();
    this.state = {};
    this.idKey = Math.floor(1e9 + Math.random() * 1e9);

    THEME = this.theme = Themes.autodetect();

    this.ratings = listsData.homeRatings;

    this.areaDropdownOptions = [
        { key: "fsa", title: this.T('Neighborhood') },
        { key: "city", title: this.T('Municipality') },
        { key: "province", title: this.T('Province') }
    ];

    this.breakdownCanvasRef = React.createRef();
  }

  componentDidMount() {
    if (!this.bdImages) {
        let images = [new Image(), new Image(), new Image(), new Image()];
        let toLoad = images.length;
        images[0].onload = images[1].onload = images[2].onload = images[3].onload = () => {
            this.setState({ canvasKey: this.state.canvasKey + 1 });
            toLoad -= 1;
            if (toLoad <= 0) {
                for (let i=0; i<images.length; i++) {
                    images[i] = RecolorIcon(images[i], 255, 255, 255);
                }
            }
        };
        images[0].src = "/images/space-heating-blue.svg";
        images[1].src = "/images/space-cooling-blue.svg";
        images[2].src = "/images/water-blue.svg";
        images[3].src = "/images/lights-blue.svg";
        this.bdImages = images;
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    if (this.breakdownCanvasRef.current && this.canvas !== this.breakdownCanvasRef.current) {
        this.canvas = this.breakdownCanvasRef.current;
        this.ctx = this.canvas.getContext("2d");
    }
    this.renderBreakdown();
  }

  renderBreakdown() {
    let rInfo = this.props.rInfo;
    if (!(this.canvas && rInfo) || this.props.overrideStats) {
        return;
    }

    let images = this.bdImages;

    let ctx = this.ctx;
    ctx.imageSmoothingEnabled = true;
    ctx.webkitImageSmoothingEnabled = true;
    ctx.mozImageSmoothingEnabled = true;
    let breakdown = JSON.parse(JSON.stringify(rInfo.bdListUnsorted));
    if (this.props.isPreview) {
        this.canvas.width = 145;
        this.canvas.height = 145;
    }
    else {
        this.canvas.style.height = `${this.canvas.offsetWidth}px`;
        this.canvas.width = this.canvas.offsetWidth;
        this.canvas.height = this.canvas.offsetHeight;
    }
    let w = (this.canvas.width),
        h = (this.canvas.height);
    ctx.clearRect(0, 0, w, h);
    let cx = w * 0.5,
        cy = h * 0.5;
    let a = -Math.PI * 0.75;
    for (let i = 0; i < breakdown.length; i++) {
        let B = breakdown[i];
        let a2 = a + B.percent1 * Math.PI * 2;
        ctx.beginPath();
        ctx.moveTo(cx, cy);
        ctx.arc(cx, cy, w * 0.5, a, a2 + Math.PI * 0.01);
        ctx.lineTo(cx, cy);
        ctx.fillStyle = B.pclr;
        ctx.closePath();
        ctx.fill();
        a = a2;
    }
    a = -Math.PI * 0.75;
    for (let i = 0; i < breakdown.length; i++) {
        let B = breakdown[i];
        let a2 = a + B.percent1 * Math.PI * 2;
        if (Math.abs(a-a2) < 0.0001) {
            continue;				
        }
        let x1 = cx + Math.cos((a + a2) * 0.5) * (w * 0.5 - w * 0.25 * 0.5);
        let y1 = cy + Math.sin((a + a2) * 0.5) * (w * 0.5 - w * 0.25 * 0.5);
        let isz = w * 0.315 * 0.5;
        let aspect = images[i].width / images[i].height;
        ctx.drawImage(images[i], 0, 0, images[i].width, images[i].height, x1 - isz * 0.5 * aspect, y1 - isz * 0.5, isz * aspect, isz);
        a = a2;
    }

    ctx.beginPath();
    ctx.arc(cx, cy, w * 0.25, 0, Math.PI * 2);
    ctx.closePath();
    ctx.fillStyle = "#fff";
    ctx.fill();
  }

  renderBreakdownHTML() {
    let rInfo = this.props.rInfo;
    if (this.props.overrideStats) {
        const OS = this.props.overrideStats;
        rInfo = {
            bdListUnsorted: [],
            levels: {
                ghg_per_m2_level: {
                    city: OS.city_ghg_per_m2_level,
                    fsa: OS.fsa_ghg_per_m2_level,
                    province: OS.province_ghg_per_m2_level,
                    national_rating_placement: OS.ghg_level
                },
                gj_per_m2_level: {
                    city: OS.city_gj_per_m2_level,
                    fsa: OS.fsa_gj_per_m2_level,
                    province: OS.province_gj_per_m2_level,
                    national_rating_placement: OS.gj_level
                },
                total_heated_floor_area_sqft: OS.heated_floor_area,
                gj_per_m2: OS.gj_per_m2,
                ghg_per_m2: OS.ghg_per_m2,
            }
        }
    }
    if (!rInfo) {
        return <div />;
    }
    const bdListUnsorted = rInfo.bdListUnsorted;
    const levels = rInfo.levels;
	let thisHomeMeter = 0;
	let thisHomeValue;
    let activeRating = 0;
    if (this.props.homeRatingTab === 1) {
        activeRating = levels?.ghg_per_m2_level.national_rating_placement||0;
		thisHomeMeter = levels?.reference_home_levels?.ghg_per_m2_level?.national_rating_placement||0;
		thisHomeValue = levels?.reference_home_levels?.ref_energy_use_ghg||0;
    }
    else if (this.props.homeRatingTab >= 2) {
        activeRating = levels?.gj_per_m2_level.national_rating_placement||0;
		thisHomeMeter = levels?.reference_home_levels?.gj_per_m2_level?.national_rating_placement||0;
		thisHomeValue = levels?.reference_home_levels?.ref_energy_use_gj||0;
    }

    const areaM2 = levels?.total_heated_floor_area_sqft * 0.092903;
    let energyUse = levels?.gj_per_m2 * areaM2;
    let envImpact = levels?.ghg_per_m2 * areaM2;

    if (!areaM2 || (typeof levels?.gj_per_m2 !== 'number') || (typeof levels?.ghg_per_m2 !== 'number')) {
        energyUse = null;
        envImpact = null;
        activeRating = null;
    }

    let roundFEU = energyUse ? Math.max(Math.ceil(Math.log10(energyUse))-3, 0) : 1;
    //let roundFEI = envImpact ? Math.max(Math.ceil(Math.log10(envImpact))-2, 1) : 1;

    roundFEU = Math.pow(10, roundFEU);
    //roundFEI = 1/10;///Math.pow(10, -1);//roundFEI);

    const activeEnergyUse = (typeof energyUse === 'number') && (typeof activeRating === 'number') ? (Math.round(energyUse / roundFEU) * roundFEU) : this.T('Unknown');
    const activeEnvImpact = (typeof envImpact === 'number') && (typeof activeRating === 'number') ? (Math.round((envImpact/1000) * 10) / 10) : this.T('Unknown');

    const numTrees = Math.ceil((envImpact || 0) / 21.77);

    return (
        <div key={this.key} className="crp-card-cont" aria-label="Energy Use Breakdown">
            <div className="crp-card">
                <div className="crp-home-rating-header-cont">
                    <div className="crp-home-rating-header"><span role='heading' aria-level={2}>{this.T('Home Rating')}</span><CFaqButton ariaLabel={this.T('Home Rating FAQ')} extraClass="crp-faq-button" id='faq-rating-trigger' onClick={this.props.modalOpenClick("faq-rating")}/></div>
                    <div role='tablist' className={"crp-home-rating-tabs " + (this.props.hideBreakdown ? 'crp-hrt-hide-breakdown' : '')} id="crp-home-rating-tabs">
                        <button aria-controls="crp-home-rating-tabs" role="tab" aria-selected={this.props.homeRatingTab === 1 ? 'true' : 'false'} onClick={() => this.props.updateParentState({ homeRatingTab: 1 })} className={"crp-home-rating-button btn-left " + (this.props.homeRatingTab == 1 ? 'btn-selected' : '')}>
                            <img alt="" src="/images/hmp-env-impact.svg"/><br/>
                            {this.T('Emissions Rating')}
                        </button>
                        <button aria-controls="crp-home-rating-tabs" role="tab" aria-selected={this.props.homeRatingTab === 2 ? 'true' : 'false'} onClick={() => this.props.updateParentState({ homeRatingTab: 2 })} className={"crp-home-rating-button btn-middle " + (this.props.homeRatingTab == 2 ? 'btn-selected' : '')}>
                            <img alt="" src="/images/hmp-energy-use.svg"/><br/>
                            {this.T('Energy Rating')}
                        </button>
                        {!this.props.hideBreakdown ? <button aria-controls="crp-home-rating-tabs" role="tab" aria-selected={this.props.homeRatingTab === 3 ? 'true' : 'false'} onClick={() => this.props.updateParentState({ homeRatingTab: 3 })} className={"crp-home-rating-button btn-right " + (this.props.homeRatingTab == 3 ? 'btn-selected' : '')}>
                            <img alt="" src="/images/energy-breakdown.svg"/><br/>
                            {this.T('Energy Use Breakdown')}
                        </button> : undefined}
                    </div>
                </div>
                {this.props.homeRatingTab < 3 && !this.theme.hideHomeRatings ? (typeof activeRating === 'number' ? <div className={"res-meter-cont " + (!thisHomeValue ? "no-this-home" : "")} aria-label={(this.props.homeRatingTab === 2 ? this.T('rrt-energy-use') : this.T('rrt-environmental-impact')) + ": " + this.T('Rating')} id={"crating-meter-container-"+this.idKey}>
                    <CRatingBubble width={this.T('hmp-lang-code') === 'FR' ? 115 : 100} height={55} top={0} left={0} meterContainerId={"crating-meter-container-"+this.idKey} meterImgId={"crating-meter-img-"+this.idKey} meterValue={Math.max(0, Math.min(100, activeRating))} style={"above"} backgroundColor={"#4A3DF0"} textClass="crating-bubble-above-text">
                        {this.T('hmp-lang-code') === 'FR' ? 'Cette Maison' : 'This Home'}<br/>
                        <span>{this.props.homeRatingTab === 1 ? activeEnvImpact : activeEnergyUse}</span>
                    </CRatingBubble>
                    <div style={{height: "65px"}}></div>
                    <div className={"meter-label-left " + (this.props.homeRatingTab === 1 ? 'meter-label-short' : '')}>{this.props.homeRatingTab === 1 ? this.T("Lowest") : this.T("Uses Least Energy")}</div>
                    <div className="meter-inner-cont">
                        <img className="meter-img" id={"crating-meter-img-"+this.idKey} alt={""} src='/images/home-rating-scale.svg'/>
                    </div>
                    <div className={"meter-label-right " + (this.props.homeRatingTab === 1 ? 'meter-label-short' : '')}>{this.props.homeRatingTab === 1 ? this.T("Highest") : this.T("Uses Most Energy")}</div>
                    {thisHomeValue ? <CRatingBubble width={this.T('hmp-lang-code') === 'FR' ? 175 : 150} height={55} top={75 + 40} left={0} meterContainerId={"crating-meter-container-"+this.idKey} meterImgId={"crating-meter-img-"+this.idKey} meterValue={thisHomeMeter} style={"below"} backgroundColor={"#FFFFFF"} textClass="crating-bubble-below-text">
                        {this.T('hmp-lang-code') === 'FR' ? 'Maison Neuve Typique' : 'Typical New Home'}<br/>
                        <span>{thisHomeValue}</span>
                    </CRatingBubble> : undefined}
                    {this.props.homeRatingTab === 1 ? <div className="crating-units-text"><Trans i18nKey={"home-rating-units-env"}><img alt="" src="/images/info-black.svg" /> Numbers above are in tonnes of CO2e/year</Trans></div> : undefined}
                    {this.props.homeRatingTab === 2 ? <div className="crating-units-text"><Trans i18nKey={"home-rating-units-eng"}><img alt="" src="/images/info-black.svg" /> Numbers above are in GJ/year</Trans></div> : undefined}
                </div> : <div className="res-meter-cont res-meter-cont-unknown" aria-label={(this.props.homeRatingTab === 1 ? this.T('rrt-energy-use') : this.T('rrt-environmental-impact')) + ": " + this.T(this.ratings[activeRating-1].text)}>
                    <div className="meter-label-unknown-medium">{this.T("Unknown")}</div><br/>
                    <div className={"meter-label-left meter-label-unknown " + (this.props.homeRatingTab === 1 ? 'meter-label-short' : '')}>{this.props.homeRatingTab === 1 ? this.T("Lowest") : this.T("Uses Least Energy")}</div>
                    <div className="meter-inner-cont">
                        <img className="meter-img" id="crating-meter-img" alt={""} src='/images/unknown-home-rating-scale.svg'/>
                    </div>
                    <div className={"meter-label-right meter-label-unknown " + (this.props.homeRatingTab === 1 ? 'meter-label-short' : '')}>{this.props.homeRatingTab === 1 ? this.T("Highest") : this.T("Uses Most Energy")}</div>
                </div>) : undefined}
                {this.props.homeRatingTab == 3 && <div className={"crp-breakdown-tab-content " + (this.props.reportPage ? "crp-breakdown-report-page" : "")}>
                    <canvas className="crp-breakdown-canvas" width="290" height="290" ref={this.breakdownCanvasRef}></canvas>
                    <div className="crp-breakdown-items">
                        <div className="crp-breakdown-item" style={{color: "#C5271F"}}>
                            <div className="crp-bd-img-cont">
                                <img alt="" src="/images/space-heating-blue.svg" className="space-heating-i" />
                            </div>
                            <div className="crp-bd-title">{this.T('Space Heating')}</div>
                            <div className="crp-bd-percent">{bdListUnsorted[0].percent}%</div>
                        </div>
                        <div className="crp-breakdown-item" style={{color: "#003FCF"}}>
                            <div className="crp-bd-img-cont">
                                <img alt="" src="/images/space-cooling-blue.svg" className="space-cooling-i" />
                            </div>
                            <div className="crp-bd-title">{this.T('Space Cooling')}</div>
                            <div className="crp-bd-percent">{bdListUnsorted[1].percent}%</div>
                        </div>
                        <div className="crp-breakdown-item" style={{color: "#D04539"}}>
                            <div className="crp-bd-img-cont">
                                <img alt="" src="/images/water-blue.svg" className="water-heating-i" />
                            </div>
                            <div className="crp-bd-title">{this.T('Water Heating')}</div>
                            <div className="crp-bd-percent">{bdListUnsorted[2].percent}%</div>
                        </div>
                        <div className="crp-breakdown-item crp-bd-shift-down" style={{color: "#A56100"}}>
                            <div className="crp-bd-img-cont">
                                <img alt="" src="/images/lights-blue.svg" className="lights-and-appliance-i" />
                            </div>
                            <div className="crp-bd-title">{this.T('Lights, Appliances & Other')}</div>
                            <div className="crp-bd-percent" style={{position: 'absolute', marginTop: '5px'}}>{bdListUnsorted[3].percent}%</div>
                        </div>
                    </div>
                </div>}
                {this.props.homeRatingTab == 1 && <div className="crp-env-impact-tab-content">
                    {numTrees ? <div className="rrti-trees"><Trans i18nKey="rrti-trees"><img alt={""} src="/images/rrti-trees.svg"/> It takes {{numTrees}} trees to clean that much carbon from the air</Trans></div> : undefined}
                </div>}
            </div>
        </div>
    );
  }

  render() {
    return this.renderBreakdownHTML();
  }
}

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
	return {
		building,
		currentUser: state.auth.currentUser
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CHomeRatingCard)));