import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getBuildingRequestLM, getBuildingRequest } from "../../actions/buildings";
import { getCustomerBuildings } from "../../actions/auth";
import CDropdown from "./components/CDropdown";
import Themes from "./Themes";
import "./Themes.scss";
import moment from "moment";
import axios from "axios";
import FocusLock from 'react-focus-lock';

import { withTranslation, Trans } from "react-i18next";

import "./CustomerNotifications.scss";

const helpers = require('../../helpers/utils');

/////

class CustomerNotificationsPage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			modalOpen: null,
			modalY: 0,
            snoozeBy: "1mo"
		};

        this.modalTitleRef = {};
		this.modalCloseClick = this.modalCloseClick.bind(this);
		this.modalOpenClick = this.modalOpenClick.bind(this);

        this.snoozeByOptions = [
			{ key: "1wk", title: this.T('1 Week'), offset: [1, 'weeks'] },
			{ key: "1mo", title: this.T('1 Month'), offset: [1, 'months'] },
			{ key: "3mo", title: this.T('3 Months'), offset: [3, 'months'] },
            { key: "6mo", title: this.T('6 Months'), offset: [6, 'months'] },
            { key: "1yr", title: this.T('1 Year'), offset: [1, 'years'] }
		];

		this.theme = Themes.autodetect();

		if (window.location.href.includes('/notifications')) {
			if (localStorage.getItem('token') !== null) {
				props.dispatch(getCustomerBuildings(true));
			}
			else {
				props.dispatch(getBuildingRequest());
				props.dispatch(getBuildingRequestLM());
			}
		}

		helpers.GetAllNotificationDescriptions(this.T);
		helpers.GetAllNotificationTitles(this.T);
		helpers.GetAllNotificationTriggeredByNames(this.T);
	}

	componentDidUpdate(prevProps) {
		if (!prevProps.show && this.props.show) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			const el = document.getElementById('ph-header-cont');
			if (el) {
				el.scrollTop = 0;
			}
		}
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

	modalCloseClick() {
		return () => {
			this.modalJustOpened = false;
			this.setState({
				modalOpen: null,
				rand: Math.random(),
			});
		};
	}

	modalOpenClick(stateName) {

		if (!this.modalTitleRef[stateName]) {
			this.modalTitleRef[stateName] = React.createRef();
		}

		return () => {
			this.modalJustOpened = true;
			setTimeout(()=>{
				if (this.modalJustOpened && this.modalTitleRef[stateName] && this.modalTitleRef[stateName].current) {
					this.modalTitleRef[stateName].current.focus();
					this.modalJustOpened = false;
				}
			}, 100);
			this.setState({
				faqExpand: null,
				modalOpen: stateName,
				rand: Math.random(),
				modalY: this.props.isPreview ? document.getElementById('report-preview-cont').scrollTop : document.documentElement.scrollTop,
			});
		};
	}

	renderModal(stateName, title, contentFn) {
		return (
			<div
				className="crp-modal-cont"
				style={this.state.modalOpen == stateName ? { display: "block" } : { display: "none" }}
				//onClick={this.modalCloseClick(stateName)}
				aria-label={title + " Modal"}
				role="dialog"
				aria-labelledby="crp-modal-title"
				aria-live="polite" accessibilityLiveRegion="polite"
			>
				{this.state.modalOpen == stateName ? <FocusLock>
					<div
						className="cnp-modal"
						style={{ top: this.state.modalY + "px" }}
						onClick={(e) => {
							(e || window.event).stopPropagation();
							return false;
						}}
					>
						<div ref={this.modalTitleRef[stateName]} className="crp-modal-header">
							<h2 className="crp-modal-title">{title}</h2>
							<button onClick={this.modalCloseClick(stateName)}><img alt="Close Modal" src="/images/x-icon-black.svg" /></button>
						</div>
						<div className="crp-modal-body">{contentFn()}</div>
					</div>
				</FocusLock> : undefined}
			</div>
		);
	}

    modalSnoozeAlert() {

		return (<div className="cnp-snooze-modal-cont">
            <div className="cnp-snooze-by-label">{this.T('Snooze by')}</div>
            <CDropdown
                options={this.snoozeByOptions}
								aria-label={this.T('Snooze by')}
                error={null}
                defaultValue={this.snoozeByOptions.filter((e) => this.state.snoozeBy == e.key)[0]}
                emptyText={this.T("Select Comparison Area")}
                onChange={(val) => {
                    this.setState({ snoozeBy: val.key });
                }}
            />
            <br/>
            <button onClick={() => {
                let snoozed_until = moment();
                let offset = this.snoozeByOptions.filter((e) => this.state.snoozeBy == e.key)[0].offset;
                if (offset) {
                    snoozed_until = snoozed_until.add(offset[0], offset[1]);
                }
                snoozed_until = snoozed_until.toISOString();
                axios.put(`/buildings/snooze-notification/${this.state.selectedNotifId}`, {snoozed_until}).then(res => {
                    this.props.dispatch(getBuildingRequestLM(this.props.building.buildingId));
                    this.modalCloseClick()();
                });
            }} className="cnp-snooze-by-btn">{this.T('Done')}</button>
		</div>);
	}

    componentDidMount() {
    }

	render() {
		if (this.props.show) {
			document.title = this.T('Notifications') + ' - ' + this.T('Climative Low Carbon Communities');
		}

		if (!this.props.show) {
			return (<div/>);
		}

		if (!this.props.currentUser) {
			window.location.href = "#/public/homeowner-map";
			return (<div/>)
		}

		this.T('Modelling Level:')
		this.T("Snooze Alert")		
		this.T('Update Age')
		this.T("Go to survey to update")
		this.T("age")
		this.T('Triggered by:')
		this.T("age")
		this.T('Snooze')

        const lang = `${this.T('hmp-lang-code')}`.toLowerCase();

        if (this.props.notif) {
            for (let notif of this.props.notif) {
                let mDate = moment.utc(notif.updated_at);
                notif.dateStr = mDate.format('MMM. D, YYYY');
                notif.daysAgo = moment().endOf('day').diff(mDate, 'days');
                notif.daysAgoStr = notif.daysAgo < 1 ? this.T('Today') : notif.daysAgo === 1 ? this.T('Yesterday') : `${notif.daysAgo} ${this.T('days ago')}`;
            }
        }
        else {
            return (<div className="cnotif-page-cont" autoFocus={true} />);
        }

		return (
			<div className="cnotif-page-cont">
                {this.renderModal("snooze-alert", this.T("Snooze Alert"), () => this.modalSnoozeAlert())}
                <div className="cnotif-page-inner">
                    <h1 className="cnp-title">{this.T('Notifications')}</h1>
                    <div className="cnp-notif-card">
                        {!this.props.notif?.length ? <div className="cnp-no-notif">{this.T('There are currently no outstanding notifications for this building.')}</div> : this.props.notif.map((notif) => (<div className="cnp-notif-block">
                            {notif.type === 'alert' ? [
                                <div autoFocus={true} className="cnp-alert-title"><img alt="" src="/images/notif-icon-yellow.svg" /> {helpers.NotificationTitle(notif, this.T)}</div>,
                                <div className="cnp-alert-desc">{helpers.NotificationDescription(notif, this.T)}</div>,
                                <div className="cnp-btn-cont">
                                    <button autoFocus={true} aria-label={this.T("Snooze Alert")} onClick={(function(ntf){ return () => {
                                        this.setState({ snoozeBy: "1mo", selectedNotifId: ntf.id });
                                        setTimeout(() => {
                                            this.modalOpenClick('snooze-alert')();
                                        }, 1);
                                    }}).bind(this)(notif)}>{this.T('Snooze')}</button>
                                    <button aria-label={this.T("Go to survey to update") + " " + helpers.NotificationTitle(notif, this.T, true) + " " + this.T("age")} onClick={() => {
                                        window.location.href = "#/app/customer/wizard";
                                    }}>{this.T('Update Age')}</button>
                                </div>,
                                <div className="cnp-days-ago">{notif.daysAgo <= 6 ? notif.daysAgoStr : notif.dateStr}</div>
                            ] : [
                                <div autoFocus={true} className="cnp-notif-title"><img alt="" src="/images/notif-icon-blue.svg" /> {helpers.NotificationTitle(notif, this.T)}</div>,
                                <div className="cnp-alert-desc">{helpers.NotificationDescription(notif, this.T)}</div>,
                                <div className="cnp-days-ago">{notif.daysAgo <= 6 ? notif.daysAgoStr : notif.dateStr}</div>,
                                helpers.NotificationTriggeredByName(notif, this.T) ? <div className="cnp-trigged-by">{this.T('Triggered by:')} {helpers.NotificationTriggeredByName(notif, this.T)}</div> : undefined,
                                notif.modelling_level ? <div className="cnp-modelling-level">{this.T('Modelling Level:')} {notif.modelling_level}</div> : undefined
                            ]}
                        </div>))}
                    </div>
                </div>
				<footer className="cwp-footer">
					{this.theme.isSimptek ? <div style={{height: '16px'}} /> : undefined}
					<div className='cwp-fspace' />{this.theme.footer.copyright[lang]}<br />{!this.theme.isSimptek ? <span><Trans i18nKey="simptek-copyright">Powered by <a href="https://www.climative.ai">Climative</a></Trans></span> : undefined}
				</footer>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
    let model = state.buildings.curModel || {};
	return {
		base64_report: state.buildings.loaded_b64_report && state.buildings.loaded_b64_report.base64_report,
        notif: model.notifications,
		building,
		currentUser: state.auth.currentUser,
		lastPublished: state.buildings.curModel.lastPublished,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomerNotificationsPage)));
