import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation, Trans } from "react-i18next";
import axios from 'axios';

import './CFAQSection.scss';
import themes from '../Themes';

const { RandomKey } = require('../../../helpers/utils');

const MAX_FAQ = 4;

class CFAQSection extends React.Component {
  constructor(props) {
    super(props);

    this.theme = themes.autodetect();

    this.T = props.t;
    this.key = RandomKey();
    this.state = {
        data: {},
        showMore: false,
        expanded: -1
    };
  }

  componentDidMount() {
    axios.get('/public/get-faq-data').then((res) => {
        this.setState({ data: res?.data ?? {}, rnd: Math.random() });
    });    
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let faqs;
    let themeFaqId = this.theme?.faqId;
    if (typeof themeFaqId === 'object') {
        const langCode = `${this.T('hmp-lang-code')}`.toLowerCase();
        themeFaqId = themeFaqId[langCode];
    }
    try {
        faqs = [...((this.state.data[themeFaqId ?? 'climative'] ?? this.state.data.climative)[this.props.section] ?? [])];
    }
    catch (err) {
        console.error('Using old FAQ structure for section ' + this.props.section);
        faqs = [...(this.state.data[this.props.section] ?? [])];
    }
    if (!faqs.length) {
        return (<div/>);
    }
    for (let faq of faqs) {
        let body = `${faq.body}`;
        while (body.indexOf('<ac') >= 0 && body.indexOf('</ac') > body.indexOf('<ac')) {
            let idx1 = body.indexOf('<ac');
            let idx2 = body.indexOf('</ac', idx1);
            let idx3 = body.indexOf('>', idx2);
            body = body.substring(0, idx1) + body.substring(idx3 + 1);
        }
        faq.body = body;
    }
    const origLength = faqs.length;
    if (!this.state.showMore) {
        faqs.length = Math.min(faqs.length, MAX_FAQ);
    }
    let ret = (
      <div key={this.key} className={'cust-faq-section'}>
        {!this.props.needsCard ? <h3 className='cust-faq-header'>{this.T("FAQ")}</h3> : undefined}
        {faqs.map((faq, idx) => (
            <div className={'cust-faq ' + (this.state.expanded === idx ? 'cf-expanded' : '') }>
                <button className='cust-faq-title' aria-expanded={this.state.expanded === idx ? 'true' : 'false'} onClick={() => this.setState({ expanded: this.state.expanded === idx ? -1 : idx })}>{faq.title}</button>
                {this.state.expanded === idx ? <div className='cust-faq-summary'>
                    <div className='cust-faq-body' dangerouslySetInnerHTML={{__html: faq.body}}></div>
                    {/*<button role='link' className='cust-faq-read-more' onClick={((i) => {
                        return () => {
                            window.open(faqs[i].link, '_blank').focus();
                        };
                    })(idx)}>{this.T("Read more")}</button>*/}
                </div> : undefined}
            </div>
        ))}
        {origLength > MAX_FAQ ? (
					<button
						className="cust-faq-more-less"
						onClick={(e) => {
                            this.setState({ showMore: !this.state.showMore });
							if (!this.state.showMore) {
								const elementParent = e.currentTarget.parentElement;
                                const faqs = elementParent.querySelectorAll('.cust-faq > button');
                                faqs[MAX_FAQ-1].focus();
							}
						}}
					>
						{this.state.showMore ? this.T('SHOW LESS') : this.T('SHOW MORE')}
					</button>
				) : (
					undefined
				)}
      </div>
    );
    if (this.props.needsCard) {
        return <div className='cust-faq-inner'>
            {ret}
        </div>
    }
    else {
        return ret;
    }
  }
}

function mapStateToProps(state, ownProps) {
    return {};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CFAQSection)));
