import React from 'react';
import ReactDOM from 'react-dom';
import {routerMiddleware} from 'connected-react-router';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider} from 'react-redux'
import ReduxThunk from 'redux-thunk'
import * as serviceWorker from './serviceWorker';
import axios from 'axios';

import App from './components/App';
import config from './config';
import createRootReducer from './reducers';
import {doInit} from './actions/auth';
import {createHashHistory} from 'history';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

const history = createHashHistory();

export function getHistory() {
    return history;
}

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = "application/json";
const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}
axios.interceptors.request.use(function (config) {
    config.headers['capi-full-referrer-url'] = window.location.href;
    return config;
}, function (error) {
    return Promise.reject(error);
});

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(routerMiddleware(history), ReduxThunk),
)

export const store = createStore(
    createRootReducer(history),
    enhancer
);

store.dispatch(doInit());

window.TRACK_EVENT = (info) => {
    let token = localStorage.getItem('token');
    if (!token) {
        axios.post("/user/track-event", info || {});
    }
    else {
        axios.post("/users/track-event", info || {});
    }
};

window.TRACK_EVENT({eventId: 'page-change'});

window.MISSING_KEYS = {};

i18next.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'sessionStorage', 'navigator', 'querystring', 'cookie', 'htmlTag', 'path', 'subdomain'],
        
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
        
        // cache user language on
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
        
        // optional expire and domain for set cookie
        cookieMinutes: 10,
        cookieDomain: 'myDomain',
        
        // optional htmlTag with lang attribute, the default is:
        htmlTag: document.documentElement,
        
        // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
        cookieOptions: { path: '/', sameSite: 'strict' }
    },
    resources: {
        "en": {
            "translation": {
                "Climative Low Carbon Communities": "Climative Low Carbon Communities",
                "Loading Animation": "Loading Animation",
                "Loading": "Loading",
                "Home Energy Report": "Home Energy Report",
                "Climative | Low Carbon Communities": "Climative | Low Carbon Communities",
                "Low Carbon Communities": "Low Carbon Communities",
                "Search": "Search",
                "Enter your home address": "Enter your home address",
                "Intro Model": "Intro Model",
                "People discussing energy charts on a computer": "People discussing energy charts on a computer",
                "Next": "Next",
                "FAQ Modal": "FAQ Modal",
                "Close FAQ": "Close FAQ",
                "Expand FAQ Item": "Expand FAQ Item",
                "Energy Use Icon": "Energy Use Icon",
                "Environment Impact Icon": "Environment Impact Icon",
                "Icon portraying various types of energy": "Icon portraying various types of energy",
                "Charts on a computer screen": "Charts on a computer screen",
                "Grey Home Icon": "Grey Home Icon",
                "Close Result": "Close Result",
                "Emissions": "Emissions",
                "Energy": "Energy",
                "Find your home": "Find your home",
                "hmp-intro-step-no-1": "{{_step}} of {{NUM_STEPS}}",
                "hmp-intro-skip": "Skip",
                "hmp-how-title": "Frequently Asked Questions",
                "hmp-faq-title-0": "How Was My Home's Energy Use Estimated?",
                "hmp-faq-content-0": "<0>We used <1>publicly-available building info</1> about your home, and <3>compared it against 1000s of similar homes</3> to estimate your home's energy use.</0>",
                "hmp-faq-title-1": "How Was My Home's Emissions Rating Estimated?",
                "hmp-faq-content-1": "<0>Your home's Emissions Rating depends on its <1>estimated Energy Use</1> (see question above) and the <3>fuel types</3> used in your home.</0>",
                "hmp-faq-title-2": "Why is My Energy Use Good but Emissions Rating Bad? (Or Vice-Versa)",
                "hmp-faq-content-2": "<0>Your home's Emissions Rating is significantly impacted by the <1>emissions of the fuel types</1> your home uses. If your home uses a relatively small amount of energy but that energy comes from a fuel source with high emissions, your emissions rating can still be bad.</0>",
                "hmp-faq-title-3": "I Think My Home's Rating is Wrong. Can I Fix It?",
                "hmp-faq-content-3": "<0>Yes! The info you're seeing so far is based on what's <1>normal for a home like yours</1>. If your home has been upgraded, or if it uses a different fuel source than we estimated, that can make a big impact on your home's rating.<br/><br/><5>You can increase the accuracy of your home's rating, get energy savings recommendations and more by <1>Signing Up</1>, all for free.</5></0>",
                "hmp-faq-title-4": "Can I Get More Accurate and Detailed Info Easily?",
                "hmp-faq-content-4": "<0>Yes! The info you're seeing so far is based on what's <1>normal for a home like yours</1>. If your home has been upgraded, or if it uses a different fuel source than we estimated, that can make a big impact on your home's rating.<br/><br/>You can increase the accuracy of your home's rating, get energy savings recommendations and more by <6>Signing Up</6>, all for free.</0>",
                "hmp-how-footer": "Sign Up to see your detailed free assessment in 60 seconds, including recommendations and savings estimates.",
                "Address Not Found": "Address Not Found",
                "Please check address and try again": "Please check address and try again",
                "People reviewing a home assessment": "People reviewing a home assessment",
                "Done": "Done",
                "hmp-intro-step-no-2": "{{_step}} of {{NUM_STEPS}}",
                "hmp-intro-back": "Back",
                "Search Result": "Search Result",
                "Home Icon": "Home Icon",
                "Question Mark Icon": "Question Mark Icon",
                "Environmental Impact Icon": "Emissions Icon",
                "Dail from Green (Excellent) to Yellow (Average) to Red (Very Poor)": "Dail from Green (Excellent) to Yellow (Average) to Red (Very Poor)",
                "Very Poor": "Very Poor",
                "Trees Icon": "Trees Icon",
                "Check Icon 1": "Check Icon 1",
                "Check Icon 2": "Check Icon 2",
                "Check Icon 3": "Check Icon 3",
                "Check Icon 4": "Check Icon 4",
                "hmp-home-rating": "Home Rating",
                "FAQ": "FAQ",
                "rrt-environmental-impact": "Emissions",
                "rrt-energy-use": "Energy Use",
                "rrti-main-co2-per-year": "{{activeEnvImpact}} tonnes of CO2e/year",
                "rrti-sub-co2-per-year": "Carbon Emissions",
                "rrti-trees": "<0></0> It takes {{numTrees}} trees to clean that much carbon from the air",
                "hmp-cta-title": "Your Energy Saving Journey Starts Here",
                "hmp-cta-button-title": "Sign in to Get My Personalized Energy Recommendations",
                "hmp-cta-footer": "Free Energy Savings Insights in 60 seconds",
                "hmp-cta-check-1": "Retrofit Savings Estimates",
                "hmp-cta-check-2": "Estimated Retrofit Costs",
                "hmp-cta-check-3": "Energy Use Breakdown",
                "hmp-cta-check-4": "Increase Accuracy by Updating Building Info",
                "Better than Average": "Better than Average",
                "rrti-main-gj-per-year": "{{activeEnergyUse}} GJ/year",
                "rrti-sub-gj-per-year": "Annual Energy Use",
                "Collapse FAQ Item": "Collapse FAQ Item",
                "Unknown": "Unknown",
                "Good": "Good",
                "Worse than Average": "Worse than Average",
                "Poor": "Poor",
                "Average": "Average",
                "Excellent": "Excellent",
                "Header": "Header",
                "Search Result (None)": "Search Result (None)",
                "hmp-desktop-banner": "hmp-title-desktop.png",
                "hmp-mobile-banner": "hmp-title-mobile.png",
                "hmp-lang-code": "EN",
                "Language Icon": "Language Icon - English",
                "Language Switcher": "Language Switcher",
                "Dial Pointer": "Dial Pointer",
                "Map Switcher": "Map Switcher",
                "of": "of",
                "Home Details": "Home Details",
                "Insulation": "Insulation",
                "What type of house do you have?": "What type of house do you have?",
                "Enter year": "Enter year",
                "Approximately what year was your house built?": "Approximately what year was your house built?",
                "How drafty is your home?": "How drafty is your home?",
                "How many heated floors/storeys does your house have?": "How many heated floors/storeys does your house have?",
                "Area": "Area",
                "What is the total floor area of your home? Only include areas you heat.": "What is the total floor area of your home? Only include areas you heat.",
                "What is the area of your first floor? This helps us know the size of your building's footprint.": "What is the area of your first floor? This helps us know the size of your building's footprint.",
                "What type of foundation do you have?": "What type of foundation do you have?",
                "CONTINUE": "CONTINUE",
                "Back to Previous Step": "Back to Previous Step",
                "cwp-info-text": "Thank you for providing information about your home.<br/><2>Note: All inputs are completely optional. You can change/ provide any of this information in your Assessment.</2>",
                "cwp-sub-title-year_built": "If you are unsure, you can select from the following estimated year ranges below",
                "cwp-sub-title-total_floor_area_sqft": "If you are unsure, you can select from the following estimated area ranges below",
                "Good Morning": "Good Morning",
                "Good Afternoon": "Good Afternoon",
                "Good Evening": "Good Evening",
                "View Report": "View Report",
                "Energy Savings Potential Report": "Energy Savings Potential Report",
                "Your Efficiency Roadmap": "Your Efficiency Roadmap",
                "Book Home Energy Assessment": "Book Home Energy Assessment",
                "STEP": "STEP",
                "PENDING": "PENDING",
                "Home Information Survey": "Building Questionnaire",
                "OPTIONAL STEP": "OPTIONAL STEP",
                "OPTIONAL": "OPTIONAL",
                "Enter Home Information": "Enter Home Information",
                "Perform Remote Energy Assessment": "Perform Remote Energy Assessment",
                "View Recommendations in your Energy Advice Report": "View Recommendations in your Energy Advice Report",
                "report-updated-on": "Updated: {{lastPublishedDate}}",
                "cdb-step-no": "STEP {{number}}",
                "cdb-step1-sub-step-after-va-auto-insights": "<0></0><1>Request to Book Home Energy Assessment</1>",
                "cdb-step1-sub-step-before-pre-awaiting-audit": "<0></0><1>Energy Auditor Book HEA</1><2>Your Energy Navigator will reach out to you to book your appointment.</2>",
                "cdb-sc-desc-booked": "Your energy navigator will perform an energy assessment of your home while on a video call with you.",
                "cdb-sc-desc-post-survey": "Thank you for taking the time to provide more information about your home.<br/><br/><3>Your Energy Savings Potential Report has been updated. You can access it at the top of this page, or by clicking here.</3><br/><br/>Still want to provide more information?<br/>",
                "Heating and Cooling": "Heating and Cooling",
                "Insulation R-Value": "Insulation R-Value",
                "How much insulation is in your ceiling/attic?": "How much insulation is in your ceiling/attic?",
                "How much insulation is in your walls?": "How much insulation is in your walls?",
                "Doors": "Doors",
                "How many exterior doors do you have?": "How many exterior doors do you have?",
                "Windows": "Windows",
                "How many windows do you have?": "How many windows do you have?",
                "How many of your windows are ENERGY STAR (or energy efficient)?": "How many of your windows are ENERGY STAR (or energy efficient)?",
                "How many of your doors are ENERGY STAR (or energy efficient)?": "How many of your doors are ENERGY STAR (or energy efficient)?",
                "Finish": "Finish",
                "What kind of primary heating system do you have?": "What kind of primary heating system do you have?",
                "Do you have a secondary heating system?": "Do you have a secondary heating system?",
                "What type of cooling / air conditioner do you have?": "What type of cooling / air conditioner do you have?",
                "What type of water heating system do you have?": "What type of water heating system do you have?",
                "What fuel type does your water heating system use?": "What fuel type does your water heating system use?",
                "FINISH": "FINISH",
                "cwp-finished-title": "Thank you for your helpful submission.",
                "cwp-finished-body": "This information will be shared with your energy navigator.<br/><br/>Also, your Energy Savings Potential Report has been updated. You can access it at the top of the dashboard page, or by clicking below.",
                "Total Energy Savings": "Retrofit Scenario Savings",
                "How we came up with this": "How we came up with this",
                "Logout Button": "Logout Button",
                "Dashboard": "Dashboard",
                "Your Energy Savings Potential Report": "Your Energy Savings Potential",
                "Upgrade Package Savings": "Retrofit Scenario Savings",
                "Individual Upgrades": "Individual Retrofits",
                "Footer": "Footer",
                "crp-how-we": "How we came up with this",
                "crp-read-more": "Read More <1></1>",
                "Success!<br/>You have requested a Video Home Energy Assessment.": "Success!<br/>You have requested a Video Home Energy Assessment.",
                "Your energy navigator will reach out to you shortly to book your appointment.": "Your energy navigator will reach out to you shortly to book your appointment.",
                "Go to Home Page": "Go to Updated Report",
                "What fuel type does your primary heating system use?": "What fuel type does your primary heating system use?",
                "What Type of Primary Heat Pump do you have?": "What Type of Primary Heat Pump do you have?",
                "What kind of secondary heating system do you have?": "What kind of secondary heating system do you have?",
                "Your request has been submitted. A service provider will send you an offer soon as possible.": "Your request has been submitted. A service provider will send you an offer soon as possible.",
                "You will be notified via email when you get new offers. You will be able to see any offers on Energy Advisor.": "You will be notified via email when you get new offers. You will be able to see any offers on Energy Navigator.",
                "Select a unit": "Select a unit",
                "Home Information Wizard": "Building Questionnaire",
                "Home Details": "Home Details",
                "Insulation": "Insulation",
                "Heating and Cooling": "Heating and Cooling",
                "Space Heating": "Space Heating",
                "Space Cooling": "Space Cooling",
                "Water Heating": "Water Heating",
                "Lights, Appliances & Other": "Appliances, Lights & Other",
                "Emissions Rating": "Emissions\nRating",
                "Energy Rating": "Energy\nRating",
                "Energy Use Breakdown": "Energy Use\nBreakdown",
                "Low Energy Use": "Low Energy Use",
                "High Energy Use": "High Energy Use",
                "Your Home": "Your Home",
                "Other Homes": "Other Homes",
                "Estimated Costs": "Estimated Costs",
                "Efficiency NS - Average Rebates": "Efficiency NS - Average Rebates",
                "Greener Homes - Average Rebates": "Greener Homes - Average Rebates",
                "Estimated Annual Savings": "Estimated Annual Savings",
                "20-Year Savings": "20-Year Savings",
                "15-Year Savings": "15-Year Savings",
                "10-Year Savings": "10-Year Savings",
                "Recommendations & Estimated Savings": "Recommendations & Estimated Savings",
                "We analyzed your home’s energy usage and basic building information to evaluate which building retrofits could save you the most energy. We’ve also estimated the potential monetary savings that could come from each upgrade.": "We analyzed your home’s energy usage and basic building information to evaluate which building retrofits could save you the most energy. We’ve also estimated the potential monetary savings that could come from each upgrade.",
                "The estimated costs estimates were based on a large sample of building retrofits performed on Canadian homes and the amount of that was automatically recommended.": "The estimated costs estimates were based on a large sample of building retrofits performed on Canadian homes and the amount of that was automatically recommended.",
                "The average rebate value shown is the average rebate homeowners received for performing this upgrade.": "The average rebate value shown is the average rebate homeowners received for performing this upgrade.",
                "Unknown": "Unknown",
                "Payback Period": "Payback Period",
                "Unavailable": "Unavailable",
                "Energy Advisor's Comments": "Energy Navigator's Comments",
                "Your Certified Energy Advisor modeled the building upgrades you completed. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Your Certified Energy Navigator modeled the building retrofits you completed. We’ve also estimated the potential monetary savings that could come from each upgrade.",
                "Your Certified Energy Advisor analyzed your energy use and building information you provided to model the best building upgrades to save you energy. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Your Certified Energy Navigator analyzed your energy use and building information you provided to model the best building retrofits to save you energy. We’ve also estimated the potential monetary savings that could come from each upgrade.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that upgrade you implemented.": "The estimated costs estimates were based on a large sample of building retrofits performed on Canadian homes and the amount of that upgrade you implemented.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that upgrade your Energy Advisor recommended.": "The estimated costs estimates were based on a large sample of building retrofits performed on Canadian homes and the amount of that upgrade your Energy Navigator recommended.",
                "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade you implemented.": "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade you implemented.",
                "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade your Energy Advisor recommended.": "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade your Energy Navigator recommended.",
                "book-remote-audit-cta": "For even more accurate results and actionable advice, we recommend you <1>book a Remote Home Energy Audit today.</1>",
                "COMPLETE": "COMPLETE",
                "Update Home Information": "Update Home Information",
                "Please wait ...": "Please wait ...",
                "Energy Auditor Book HEA": "Energy Auditor Book HEA",
                "cdb-step1-booking-disabled": "<0>We are no longer able to accept requests for video audit at this time as the pilot period has ended.</0><1>We thank you for your interest and apologize for the inconvenience.</1>",
                "cdb-step1-sub-step-eq-upg-upgrades-completed-1": "<0></0><1>Request to Book Home Energy Assessment</1>",
                "cdb-step1-sub-step-eq-upg-upgrades-completed-2": "<0>Request Home Energy Assessment</0>",
                "cdb-step1-sub-step-after-upg-upgrades-completed": "<0></0><1>Request to Book Home Energy Assessment</1>",
                "cdb-step1-sub-step-before-post-awaiting-audit": "<0></0><1>Energy Auditor Book HEA</1><2>Your Energy Navigator will reach out to you to book your appointment.</2>",
                "cdb-step1-sub-step-gte-post-awaiting-audit": "<0></0><1>Energy Auditor Book HEA</1><br/><3></3>",
                "cdb-step1-sub-step-eq-pre-auto-insights-2": "<0>Request Home Energy Assessment!</0>",
                "cdb-sc-desc-call-booked": "Your energy advisor will perform an energy assessment of your home to validate your retrofits while on a video call with you.",               
                "cdb-sc-desc-val-report-ready": "<0>You now have an Upgrade Validation Report. You can access it at the top of this page, or by clicking here.</0><br/><br/>",
                "cdb-sc-desc-report-ready-with-fsp": "<0>You now have an Energy Advice Report. You can access it at the top of this page, or by clicking here.</0><br/><br/><3>Ready to start saving energy?</3><4>Find a Service Provider</4>",
                "cdb-sc-desc-report-ready-no-fsp": "<0>You now have an Energy Advice Report. You can access it at the top of this page, or by clicking here.</0><br/>",
                "cdb-sc-desc-pre-survey": "Please tell us as much about your home as you can in preparation for your Energy Assessment.<br/><br/>This info will be shared with your energy navigator, and this will help determine which retrofits are the best fit for your home in your updated Energy Savings Potential Report.",
                "1940 or before": "1940 or before",
                "1941 to 1960": "1941 to 1960",
                "1961 to 1980": "1961 to 1980",
                "1981 to 2000": "1981 to 2000",
                "2000-3000 ft²": "2000-3000 ft²",
                "2001 to 2010": "2001 to 2010",
                "2011 to present": "2011 to present",
                "3000-4000 ft²": "3000-4000 ft²",
                "4000-5000 ft²": "4000-5000 ft²",
                "- Unspecified -": "- Unspecified -",
                "A little drafty": "A little drafty",
                "Above average - R16+": "Above average - R16+",
                "Above average - R24+": "Above average - R24+",
                "Above average - R60+": "Above average - R60+",
                "Air-to-Water": "Air-to-Water",
                "All": "All",
                "Average - Approx. R10": "Average - Approx. R10",
                "Average - Approx. R18": "Average - Approx. R18",
                "Average - Approx. R30": "Average - Approx. R30",
                "Basement": "Basement",
                "Below average - Approx. R5": "Below average - Approx. R5",
                "Below average - Approx. R12": "Below average - Approx. R12",
                "Below average - Approx. R15": "Below average - Approx. R15",
                "Central": "Central",
                "Central Split System (Ducted)": "Central Split System (Ducted)",
                "Central single package system": "Central single package system",
                "Combination boiler": "Combination boiler",
                "Condensing water heater": "Condensing water heater",
                "Conventional A/C": "Conventional A/C",
                "Conventional/Tank Water Heater": "Conventional/Tank Water Heater",
                "Crawlspace": "Crawlspace",
                "Electric Baseboard": "Electric Baseboard",
                "Electric Baseboard / Space Heater": "Electric Baseboard / Space Heater",
                "Electricity": "Electricity",
                "Enter R-Value": "Enter R-Value",
                "Enter area": "Enter area",
                "Enter number of exterior doors": "Enter number of exterior doors",
                "Enter number of windows": "Enter number of windows",
                "Fireplace": "Fireplace",
                "Furnace (Forced Air)": "Furnace (Forced Air)",
                "Geothermal": "Geothermal",
                "Heat Pump": "Heat Pump",
                "Heat Pump (hybrid) water heater": "Heat Pump (hybrid) water heater",
                "Hot Water Radiators and Boiler": "Hot Water Radiators and Boiler",
                "How much insulation is on your foundation walls?": "How much insulation is on your foundation walls?",
                "In-Floor Radiant & Boiler": "In-Floor Radiant & Boiler",
                "Is your basement heated?": "Is your basement heated?",
                "Is your crawlspace heated?": "Is your crawlspace heated?",
                "Mini-Split": "Mini-Split",
                "Mini-split Heat Pump (ductless)": "Mini-split Heat Pump (ductless)",
                "N/A": "N/A",
                "Natural Gas": "Natural Gas",
                "No": "No",
                "None": "None",
                "Not Installed": "Not Installed",
                "Not drafty": "Not drafty",
                "Oil": "Oil",
                "Other": "Other",
                "Propane": "Propane",
                "Slab": "Slab",
                "Solar": "Solar",
                "Solar water heater": "Solar water heater",
                "Tankless/Instantaneous Water Heater": "Tankless/Instantaneous Water Heater",
                "Unsure": "Unsure",
                "Very drafty": "Very drafty",
                "Window A/C": "Window A/C",
                "Wood": "Wood",
                "Wood / Pellet Stove": "Wood / Pellet Stove",
                "Wood Stove": "Wood Stove",
                "YYYY": "YYYY",
                "Yes": "Yes",
                "ft²": "ft²",
                "less than 100 m²": "less than 100 m²",
                "less than 1000 ft²": "less than 1000 ft²",
                "m²": "m²",
                "type_of_house__mobile_home": "<0><0></0><br/>Mobile<br/>Home</0>",
                "type_of_house__row_house_end_unit": "<0><0></0><br/>Row house -<br/>End unit</0>",
                "type_of_house__row_house_middle_unit": "<0><0></0><br/>Row house -<br/>Middle unit</0>",
                "type_of_house__semi_detached": "<0><0></0><1></1><br/>Double or Semi-<br/>Detached</0>",
                "type_of_house__single_detached": "<0><0></0><br/>Single<br/>Detached</0>",
                "Next": "Next",
                "Sign In To Energy Navigator": "Sign In To Energy Navigator",
                "Phone Number": "Phone Number",
                "Pin Number": "Pin Number",
                "Login": "Login",
                "simptek-login-footer": "<1>© Climative 2023</1>",
                "Loading...": "Loading...",
                "Could not find a customer matching this phone number & pin number.": "Could not find a customer matching this phone number & pin number.",
                "Please fill out this field.": "Please fill out this field.",
                "Estimated Rebates": "Estimated Rebates",
                "Average Rebates": "Average Rebates",
                "Want a more accurate rating?": "Want a more accurate rating?",
                "Ready to start saving energy?": "Ready to start saving energy?",
                "Upgrade Packages": "Retrofit Scenarios",
                "Take Building Info Survey": "Take Building Questionnaire!",
                "FAQ - Energy & Emissions Ratings": "FAQ - Energy & Emissions Ratings",
                "FAQ – Upgrades": "FAQ – Retrofits",
                "crp-faq-rating-title-0": "How Was My Home’s Energy Rating Estimated?",
                "crp-faq-rating-content-0": "<0><0>We use all of the data sources below when available to compare your home against thousands of similar homes to estimate your energy use.</0><1><0>•</0><1>Widely-Available Data (property assessment, weather data & others)</1></1><2><0>•</0><1>Your Questionnaire Submission</1></2><3><0>•</0><1>Assessment by professional Energy Navigator (either remote energy audit or on-site EnerGuide assessment)</1></3><4><0>•</0><1>Energy Consumption Data</1></4><5>When more data becomes available (we only obtain more data with user consent), we rerun the analysis.</5><6>The data sources in use for your home are listed in the Data Sources section on the Energy Savings Report page.</6></0>",
                "crp-faq-rating-title-1": "How Was My Home’s Emissions Rating Estimated?",
                "crp-faq-rating-content-1": "<0><0>Your home’s Emissions Rating depends on its estimated Energy Rating (see question above) and the fuel types used in your home.</0><1>Which fuel type(s), and which utility provider(s) your energy comes from affects your emissions rating a lot. Electricity varies the most, where some electric utilities mostly use clean sources like hydro, but others use high-emissions sources like coal to generate electricity.</1></0>",
                "crp-faq-rating-title-2": "Why is My Energy Rating Good but Emissions Rating Bad? (Or Vice-Versa)",
                "crp-faq-rating-content-2": "<0><0>Yes! Since we start by assessing every home with widely-available data, the ratings start as being what’s normal for a home like yours – this often won’t take existing retrofits into account. You can increase the accuracy of your energy and emissions ratings by providing more data through the building questionnaire.</0><1>The data sources in use for your home are listed in the Data Sources section on the Energy Savings Report page.</1></0>",
                "crp-faq-rating-title-3": "What is CO2e?",
                "crp-faq-rating-content-3": "<0><0>There are various different greenhouse gases (emissions) which are produced as a result of energy use. The main one is carbon dioxide (CO2), but there are others. CO2e accounts for the other gases as well, to give a full picture of your home’s emissions impact on climate change.</0></0>",
                "crp-faq-upgrades-title-0": "How was/were my Retrofit Scenario(s) Determined",
                "crp-faq-upgrades-content-0": "<0><0>We compared the data available for your home against thousands of other similar homes that completed retrofits and estimated the best combination of building retrofits based on your energy priorities. You can set your energy priorities in the building questionnaire.</0><1>The data sources in use for your home are listed in the Data Sources section on the Energy Savings Report page</1></0>",
                "crp-faq-upgrades-title-1": "How Was the Energy Saving of Each Upgrade Estimated?",
                "crp-faq-upgrades-content-1": "<0><0>Climative uses a combination of artificial intelligence (AI) and building science to estimate the energy saved from each upgrade.</0></0>",
                "crp-faq-upgrades-title-2": "How Was the Emissions Reduction from Retrofits Estimated",
                "crp-faq-upgrades-content-2": "<0><0>The amount of emissions reduction for each upgrade depends on the energy savings (see section above) for that upgrade and the fuel types used in your home.</0></0>",
                "crp-faq-upgrades-title-3": "How Were the Costs of Retrofits Estimated?",
                "crp-faq-upgrades-content-3": "<0><0>We used data from NRCan and contractors to estimate the cost of retrofits (equipment + installation). Note that the cost of retrofits can vary significantly based on the specific details of a home.</0></0>",
                "crp-faq-upgrades-title-4": "How Were the Financial Projections Estimated?",
                "crp-faq-upgrades-content-4": "<0><0>The financial projections for your Retrofit Scenarios were based on: estimated retrofit costs after rebates, projected carbon tax rates and projected energy costs</0></0>",
                "crp-faq-upgrades-title-5": "Can I Get More Accurate Recommendations?",
                "crp-faq-upgrades-content-5": "<0><0>Yes! Since we start by assessing every home with widely-available data, the ratings start as being what’s normal for a home like yours – this often won’t take existing retrofits into account. You can increase the accuracy of your upgrade recommendations by providing more data through the building questionnaire.</0><1>The data sources in use for your home are listed in the Data Sources section on the Energy Savings Report page.</1></0>",
                "cdb-step1-sub-step-eq-pre-auto-insights-1": "<0></0><1>Request to Book Home Energy Assessment</1>",
                "Data Sources": "Data Sources",
                "Publicly-Available Data": "Publicly-Available Data",
                "Utility Consumption Data": "Utility Consumption Data",
                "crp-data-updated-on": "Updated: {{lastUpdatedDate}}",
                "EnerGuide Label": "EnerGuide Label",
                "Your Energy Rating and Upgrade Packages are based on:": "Your Energy Rating and Retrofit Scenarios are based on:",
                "Best": "Best",
                "Better": "Better",
                "Current": "Current",
                "Home Rating": "Home Rating",
                "Your Survey Submission": "Your Questionnaire Submission",
                "cwp-sub-title-r-select": "If you are unsure, you can select from the following estimated R-Value ranges below",
                "Share Utility Data": "Share Utility Data",
                "Current state: ": "Current state: ",
                "Unchecked": "Unchecked",
                "Cancel": "Cancel",
                "Share EnerGuide Report": "Share EnerGuide Report",
                "Get Your Upgrades Validated": "Get Your Retrofits Validated",
                "Close": "Close",
                "View Energy Savings Potential Report": "View Energy Savings Potential Report",
                "Increase Accuracy of Energy Rating and Retrofit Guidance": "Increase Accuracy of Energy Rating and Retrofit Guidance",
                "ONGOING": "ONGOING",
                "Here are the things you can do to increase accuracy. Do one or more.": "Here are the things you can do to increase accuracy. Do one or more.",
                "Survey": "Questionnaire",
                "Share": "Share",
                "Utility Data": "Utility Data",
                "EnerGuide Label": "EnerGuide Label",
                "Make Actionable Retrofit Plan": "Make Actionable Retrofit Plan",
                "NOT STARTED": "NOT STARTED",
                "Building Upgrades and Update Energy Rating": "Building Retrofits and Update Energy Rating",
                "DONE": "DONE",
                "Expand": "Expand",
                "Upgrades completed? Update your Energy Rating and Retrofit Guidance.": "Retrofits completed? Update your Energy Rating and Retrofit Guidance.",
                "Get Upgrades Vaildated \nby a Pro": "Get Retrofits Vaildated \nby a Pro",
                "More info": "More info",
                "Update": "Update",
                "report-updated-on-generic": "Last updated {{lastUpdatedDate}}",
                "Collapse": "Collapse",
                "Pending": "Pending",
                "Done with this step?": "Done with this step?",
                "Current: Yes": "Current: Yes",
                "cdb-vendor-make-plan": "Make a plan with a pro. Take advantage of {{vendorNamePossesive}} great incentives.",
                "cdb-vendor-link": "Open {{vendorNamePossesive}} website",
                "Climative": "Climative",
                "Compared Against Similar Homes in my": "Compared Against Similar Homes in my",
                "Municipality": "Municipality",
                "Neighborhood": "Neighborhood",
                "Province": "Province",
                "Select Comparison Area": "Select Comparison Area",
                "Good Upgrade Package": "Light Retrofit Scenario",
                "Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.": "Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.",
                "Better Upgrade Package": "Medium Retrofit Scenario",
                "Best Upgrade Package": "Deep Retrofit Scenario",
                "Helpful Links": "Helpful Links",
                "Better Homes Ottawa Services": "Better Homes Ottawa Services",
                "Get help from professionals who will help you what to do next.": "Get help from professionals who will help you what to do next.",
                "Better Homes Ottawa Loan Program": "Better Homes Ottawa Loan Program",
                "The City of Ottawa is piloting a three - year, zero-interest loan program for home energy efficiency retrofits!": "The City of Ottawa is piloting a three - year, zero-interest loan program for home energy efficiency retrofits!",
                "Rebate & Incentive Programs": "Rebate & Incentive Programs",
                "There are lots of programs available to help make your deep retrofit journey more affordable.": "There are lots of programs available to help make your deep retrofit journey more affordable.",
                "Select Package": "Select Scenario",
                "Select Upgrade Package": "Select Upgrade Scenario",
                "Update Preferences": "Update Preferences",
                "Go to ": "Go to ",
                "cdb-accept-bills-para": "Your Home Energy Report and public Home Rating (displayed on the Low Carbon Communities map) can be updated with your home’s energy consumption data from utilities.<br/><br/><3>Please set your preferences below:</3>",
                "cdb-accept-eng-checkbox-bc-on": "Retrieve available energy use data from Hydro One",
                "cdb-accept-eng-checkbox-2-on": "Retrieve available energy use data from Enbridge",
                "cdb-accept-eng-checkbox-3": "Continue retrieving energy use data from my utilities in the future",
                "cdb-accept-eng-checkbox-4": "Update public Home Rating based on retrieved utility data",
                "cdb-accept-eng-para": "Your Home Energy Report and public Home Rating (displayed on the Low Carbon Communities map) can be updated with EnerGuide reports.<br/><br/><3>Please set your preferences below:</3>",
                "cdb-accept-bills-checkbox-on": "Retrieve any existing EnerGuide reports for your home from Better Homes Ottawa",
                "cdb-accept-bills-checkbox-2-on": "Retrieve any future EnerGuide reports for your home from Better Homes Ottawa",
                "cdb-accept-bills-checkbox-3": "Update public Home Rating based on retrieved EnerGuide reports",
                "Your Priorities": "Your Priorities",
                "When making decisions about energy efficiency upgrades, what is most important to you?": "When making decisions about energy efficiency retrofits, what is most important to you?",
                "Most important to me": "Most important to me",
                "Move Down": "Move Down",
                "Saving Money": "Saving Money",
                "Move Up": "Move Up",
                "Important to me": "Important to me",
                "Reducing Carbon Emissions": "Reducing Carbon Emissions",
                "Improving Comfort": "Improving Comfort",
                "Somewhat important to me": "Somewhat important to me",
                "Improving my EnerGuide Rating": "Improving my EnerGuide Rating",
                "Does not affect my decisions": "Does not affect my decisions",
                "This Section is Empty": "This Section is Empty",
                "Save Priorities": "Save Priorities",
                "Personalize Your Recommendations": "Personalize Your Recommendations",
                "Everyone has different priorities when making energy efficiency upgrades.": "Everyone has different priorities when making energy efficiency retrofits.",
                "You can get upgrade packages that are customized based on what you care about by sharing a little info.": "You can get upgrade retrofit scenarios that are customized based on what you care about by sharing a little info.",
                "This is optional and can be updated at any time.": "This is optional and can be updated at any time.",
                "cdb-accept-eng-checkbox-bc": "Retrieve available energy use data from BC Hydro",
                "cdb-accept-eng-checkbox-2-bc": "Retrieve available energy use data from Fortis BC",
                "cdb-accept-bills-checkbox-bc": "Retrieve any existing EnerGuide reports for your home from CleanBC",
                "cdb-accept-bills-checkbox-2-bc": "Retrieve any future EnerGuide reports for your home from CleanBC",
                "Rating After Upgrades (vs. ": "Rating After Retrofits (vs. ",
                "Personalize my Recommendations": "Personalize my Recommendations",
                "Free Energy Coaching": "Free Energy Coaching",
                "CleanBC Better Homes provides free energy coaching services for homeowners.": "CleanBC Better Homes provides free energy coaching services for homeowners.",
                "Find a Contractor": "Find a Contractor",
                "CleanBC Better Homes has a search tool to help you find a registered contractor for your home upgrade needs.": "CleanBC Better Homes has a search tool to help you find a registered contractor for your home upgrade needs.",
                "CleanBC Rebates": "CleanBC Rebates",
                "CleanBC’s Better Homes Program offers professional advice, qualified contractors and money-saving incentives.": "CleanBC’s Better Homes Program offers professional advice, qualified contractors and money-saving incentives.",
                "Welcome to Climative": "Welcome to Climative",
                "Welcome": "Welcome",
                "Survey for Increased Accuracy": "Questionnaire for Increased Accuracy",
                "Personalized Recommendations": "Personalized Recommendations",
                "Welcome to your Climative Home Energy Report! This is the first step towards savings money and reducing your home’s emissions.": "Welcome to your Climative Home Energy Report! This is the first step towards savings money and reducing your home’s emissions.",
                "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.  ": "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.  ",
                "We also modeled different potential upgrade packages for you to consider. We estimated the change to your Energy & Emissions Ratings, as well as financial impact.": "We also modeled different potential upgrade retrofit scenarios for you to consider. We estimated the change to your Energy & Emissions Ratings, as well as financial impact.",
                "The Data Sources section at the bottom of the Home Energy Report 1) tells you what data is currently being used in your energy report, and 2) lets you add more info so you get the best results.": "The Data Sources section at the bottom of the Home Energy Report 1) tells you what data is currently being used in your energy report, and 2) lets you add more info so you get the best results.",
                "We start by assessing homes with widely-available data, so the ratings start as being what’s normal for a home like yours. You can increase the accuracy of your Home Energy Report by providing more data through the survey.": "We start by assessing homes with widely-available data, so the ratings start as being what’s normal for a home like yours. You can increase the accuracy of your Home Energy Report by providing more data through the building questionnaire.",
                "Everyone has different priorities when making energy efficiency retrofits.\n\nWith the Personalize My Recommendations feature, you can get upgrade packages customized based on your priorities.": "Everyone has different priorities when making energy efficiency retrofits.\n\nWith the Personalize My Recommendations feature, you can get upgrade retrofit scenarios customized based on your priorities.",
                "Back": "Back",
                "Estimated door age": "Estimated door age",
                "Enter age in years": "Enter age in years",
                "0-10 years": "0-10 years",
                "10-20 years": "10-20 years",
                "20-30 years": "20-30 years",
                "30-50 years": "30-50 years",
                "50+ years": "50+ years",
                "Approximately how old are your exterior doors?": "Approximately how old are your exterior doors?",
                "Estimated window age": "Estimated window age",
                "Approximately how old are your windows?": "Approximately how old are your windows?",
                "cwp-sub-title-exterior_doors_age": "If you are unsure, you can select from the following estimated ranges below",
                "cwp-sub-title-windows_age": "If you are unsure, you can select from the following estimated ranges below",
                "Primary heating system age": "Primary heating system age",
                "0-5 years": "0-5 years",
                "5-10 years": "5-10 years",
                "10-15 years": "10-15 years",
                "15-20 years": "15-20 years",
                "30+ years": "30+ years",
                "Approximately how old is your primary heating system?": "Approximately how old is your primary heating system?",
                "Water heating system age": "Water heating system age",
                "Approximately how old is your water heating system?": "Approximately how old is your water heating system?",
                "cwp-sub-title-primary_heating_system_age": "If you are unsure, you can select from the following estimated ranges below",
                "cwp-sub-title-water_heating_system_age": "If you are unsure, you can select from the following estimated ranges below",
                "Home Energy Report updated.": "Home Energy Report updated.",
                "Your home’s energy modelling has been updated based on the updated building information you submitted.": "Your home’s energy modelling has been updated based on the updated building information you submitted.",
                "Map": "Map",
                "Report": "Report",
                "Sign In": "Sign In",
                "Sign Out": "Sign Out",
                "Layers": "Layers",
                "cdb-accept-eng-checkbox-2": "Retrieve available energy use data",
                "rrt-emissions": "Emissions",
                "rrt-energy": "Energy",
                "Energy Use Breakdown (one line)": "Energy Use Breakdown",
                "Data Privacy": "Data Privacy",
                "Share Nothing": "Share Nothing",
                "Share Some": "Share Some",
                "Share More": "Share More",
                "Building Colour on Map": "Building Colour on Map",
                "Energy and Emissions Rating": "Energy and Emissions Rating",
                "Retrofit Scenarios": "Retrofit Scenarios",
                "We weren’t able to find a building with that address.": "We weren’t able to find a building with that address.",
                "We couldn’t place this building on the map, but all other features should work normally.": "We couldn’t place this building on the map, but all other features should work normally.",
                "add-my-home-cta": "Go to <1>Climative FAQ: Find My Home</1> for more info.",
                "Energy Navigator": "Energy Navigator",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "Waiting for verification ...": "Waiting for verification ...",
                "Skip to main Content": "Skip to main Content",
                "Report Unselected": "Report Unselected",
                "Sign  In": "Sign  In",
                "Change Language": "Change Language",
                "Side Menu": "Side Menu",
                "Selected Map": "Selected Map",
                "Unselected Report": "Unselected Report",
                "Selected": "Selected",
                "Unselected": "Unselected",
                "Avg. Annual Energy Bill Savings": "Avg. Annual Energy Bill Savings",
                "10-Yr Financial Impact": "10-Yr Financial Impact",
                "20-Yr Financial Impact": "20-Yr Financial Impact",
                "Estimated Costs after Rebates": "Estimated Costs after Rebates",
                "1 Week": "1 Week",
                "1 Month": "1 Month",
                "3 Months": "3 Months",
                "6 Months": "6 Months",
                "1 Year": "1 Year",
                "Home Map": "Home Map",
                "Climative  Low Carbon Communities": "Climative  Low Carbon Communities",
                "FAQ – Retrofits": "FAQ – Retrofits",
                "Upload EnerGuide Label": "Upload EnerGuide Label",
                "Welcome to Your Home Energy Report": "Welcome to Your Home Energy Report",
                "You Are Not Signed In": "You Are Not Signed In",
                "Sign in to view your home energy report.": "Sign in to view your home energy report.",
                "Your Energy Rating and Retrofit Scenarios are based on:": "Your Energy Rating and Retrofit Scenarios are based on:",
                "Checked": "Checked",
                "Update your Home Information Survey": "Update your Home Information Survey",
                "Upload": "Upload",
                "Suggested Programs": "Suggested Programs",
                "Choosing a Contractor": "Choosing a Contractor",
                "Choosing the right contractor will determine the success of your energy retrofit project.": "Choosing the right contractor will determine the success of your energy retrofit project.",
                "Home Rating FAQ": "Home Rating FAQ",
                "Rating": "Rating",
                "Uses Least Energy": "Uses Least Energy",
                "Uses Most Energy": "Uses Most Energy",
                "home-rating-units-eng": "<0></0> Numbers above are in GJ/year",
                "Retrofit Scenarios FAQ": "Retrofit Scenarios FAQ",
                "Select Retrofit Scenario": "Select Retrofit Scenario",
                "Included Retrofits": "Included Retrofits",
                "Central Heat Pump": "Central Heat Pump",
                "Drain Water Heat Recovery": "Drain Water Heat Recovery",
                "Retrofit Scenario Details": "Retrofit Scenario Details",
                "Checkmark": "Checkmark",
                "Report Selected": "Report Selected",
                "Unselected Map": "Unselected Map",
                "Selected Report": "Selected Report",
                "Claim My Home": "Claim My Home",
                "Selected Notifications": "Selected Notifications",
                "Selected Settings": "Selected Settings",
                "Notifications": "Notifications",
                "Settings": "Settings",
                "PERSONALIZE MY RECOMMENDATIONS": "PERSONALIZE MY RECOMMENDATIONS",
                "Get Personalized Retrofit Scenarios": "Get Personalized Retrofit Scenarios",
                "Mini-Split Heat Pump": "Mini-Split Heat Pump",
                "Air Sealing": "Air Sealing",
                "Unselected Notifications": "Unselected Notifications",
                "Unselected Settings": "Unselected Settings",
                "SWITCH HOME": "SWITCH HOME",
                "More Homeowner Actions": "More Homeowner Actions",
                "Remove Property": "Remove Property",
                "Lowest": "Lowest",
                "Highest": "Highest",
                "home-rating-units-env": "<0></0> Numbers above are in tonnes of CO2e/year",
                "SHOW MORE": "SHOW MORE",
                "Financial Summary": "Financial Summary",
                "COST AFTER\nREBATES": "COST AFTER\nREBATES",
                "SAVINGS AFTER\nREBATES": "SAVINGS AFTER\nREBATES",
                "SAVINGS OVER\n20 YEARS": "SAVINGS OVER\n20 YEARS",
                "COST OVER\n20 YEARS": "COST OVER\n20 YEARS",
                "View Financial Breakdown": "View Financial Breakdown",
                "Projected Energy Bills": "Projected Energy Bills",
                "Energy Costs are rising. Here’s how these upgrades impact your energy bills.": "Energy Costs are rising. Here’s how these upgrades impact your energy bills.",
                "Do-Nothing Case": "Do-Nothing Case",
                "Retrofit Scenario": "Retrofit Scenario",
                "Equipment End of Life": "Equipment End of Life",
                "Financial": "Financial",
                "Retrofits": "Retrofits",
                "Basic": "Basic",
                "Advanced": "Advanced",
                "Updated Emissions Rating": "Updated Emissions Rating",
                "Savings": "Savings",
                "Annual Emissions Change": "Annual Emissions Change",
                "Lifetime Emissions Impact": "Lifetime Emissions Impact",
                "TONNES OF EMISSIONS SAVED (LIFETIME IMPACT)": "TONNES OF EMISSIONS SAVED (LIFETIME IMPACT)",
                "Trees": "Trees",
                "like-planting-n-trees": "That's like planting {{nTrees}} trees",
                "Updated Energy Rating": "Updated Energy Rating",
                "Lifetime Energy Impact": "Lifetime Energy Impact",
                "GJ OF ENERGY SAVED": "GJ OF ENERGY SAVED",
                "Car": "Car",
                "like-driving-n-times": "That’s the same energy it would take to drive around the Earth {{nDrives}} times.",
                "Energy Advisor’s Comments": "Energy Advisor’s Comments",
                "Recommended Retrofits": "Recommended Retrofits",
                "The following upgrades are included in this Retrofit Scenario:": "The following upgrades are included in this Retrofit Scenario:",
                "A reversible air conditioner; cools in summer, warms in winter. Generally 2-4x more energy-efficient than traditional systems.": "A reversible air conditioner; cools in summer, warms in winter. Generally 2-4x more energy-efficient than traditional systems.",
                "Mini-Split Heat Pump Modeled": "Mini-Split Heat Pump Modeled",
                "Type": "Type",
                "Capacity": "Capacity",
                "48000 btu/hr": "48000 btu/hr",
                "Show Energy Advisor’s Comments": "Show Energy Advisor’s Comments",
                "Windproofs your home, ensuring a consistent, controllable temperature, fewer drafts, and reduced energy costs. Air sealing a drafty home is one of the best measures to make you feel more comfortable.": "Windproofs your home, ensuring a consistent, controllable temperature, fewer drafts, and reduced energy costs. Air sealing a drafty home is one of the best measures to make you feel more comfortable.",
                "Air Sealing Modeled": "Air Sealing Modeled",
                "Recycles heat from your drainwater, reducing the energy that your hot water heater uses. ": "Recycles heat from your drainwater, reducing the energy that your hot water heater uses. ",
                "Drain Water Heat Recovery Modeled": "Drain Water Heat Recovery Modeled",
                "Hide Energy Advisor’s Comments": "Hide Energy Advisor’s Comments",
                "Energy bills in": "Energy bills in",
                "years: ": "years: ",
                "/year after retrofits": "/year after retrofits",
                "Expected End of Life:": "Expected End of Life:",
                "Cost After Rebates": "Cost After Rebates",
                "Estimated Retrofit Costs": "Estimated Retrofit Costs",
                "10 Yr Savings": "10 Yr Savings",
                "Hide Financial Breakdown": "Hide Financial Breakdown",
                "Homeowner Survey": "Homeowner Survey",
                "Basic Info": "Basic Info",
                "Windows & Doors": "Windows & Doors",
                "": "",
                "What year was your house built?": "What year was your house built?",
                "Do not count basement/ crawlspace.": "Do not count basement/ crawlspace.",
                "For split-level homes, include the lower level.": "For split-level homes, include the lower level.",
                "1.5": "1.5",
                "2.5": "2.5",
                "3.5": "3.5",
                "How many floors above grade does your home have?": "How many floors above grade does your home have?",
                "square feet (ft²)": "square feet (ft²)",
                "square meters (m²)": "square meters (m²)",
                "What is your preferred unit for floor areas?": "What is your preferred unit for floor areas?",
                "Area (ft²)": "Area (ft²)",
                "1500-2000 ft²": "1500-2000 ft²",
                "1000-1500 ft²": "1000-1500 ft²",
                "What is the area of your first floor?": "What is the area of your first floor?",
                "NEXT": "NEXT",
                "How this data will be used": "How this data will be used",
                "cdd-header-homeowner-survey-0": "<0>Update <1>optional</1> info about your building to improve your home's energy modelling.</0>",
                "type_of_house__duplex": "<0><0></0><br/>Duplex</0>",
                "foundtype__basement": "<0><0></0><br/>Basement</0>",
                "foundtype__crawlspace": "<0><0></0><br/>Crawlspace</0>",
                "foundtype__slab": "<0><0></0><br/>Slab on Grade</0>",
                "foundtype__split": "<0><0></0><br/>Split Level</0>",
                "foundtype__unknown": "<0><br/><br/><2>?</2><br/><br/>Unknown</0>",
                "If you don't see your system type, choose the most similar": "If you don't see your system type, choose the most similar",
                "1920 to 1940": "1920 to 1940",
                "Do you know when your primary heating system was last upgraded?": "Do you know when your primary heating system was last upgraded?",
                "cwp-sub-title-primary_heating_system_year": "If you are unsure, you can select from the following estimated year ranges below",
                "Do you have a cooling system/ air conditioning?": "Do you have a cooling system/ air conditioning?",
                "Do you know when your cooling system was last upgraded?": "Do you know when your cooling system was last upgraded?",
                "cwp-sub-title-air_conditioner_year": "If you are unsure, you can select from the following estimated year ranges below",
                "Do you know when your water heating system was last upgraded?": "Do you know when your water heating system was last upgraded?",
                "Rented": "Rented",
                "Owned": "Owned",
                "I Don’t Know": "I Don’t Know",
                "Is your water heated rented or owned?": "Is your water heated rented or owned?",
                "cwp-sub-title-water_heating_system_year": "If you are unsure, you can select from the following estimated year ranges below",
                "No, they're original": "No, they're original",
                "I think so": "I think so",
                "I don't know": "I don't know",
                "Were your windows ever upgraded?": "Were your windows ever upgraded?",
                "Were your exterior doors ever upgraded?": "Were your exterior doors ever upgraded?",
                "cwp-sub-title-windows": "Count very large or solarium-type windows in terms of how many average-sized windows they represent",
                "cwp-sub-title-exterior_doors": "Include Sliding Doors",
                "Do you know when your windows were upgraded?": "Do you know when your windows were upgraded?",
                "cwp-sub-title-windows_year": "If you are unsure, you can select from the following estimated year ranges below",
                "Do you know when your exterior doors were upgraded?": "Do you know when your exterior doors were upgraded?",
                "cwp-sub-title-exterior_doors_year": "If you are unsure, you can select from the following estimated year ranges below",
                "No, it is original": "No, it is original",
                "Was your ceiling/attic insulation ever upgraded?": "Was your ceiling/attic insulation ever upgraded?",
                "Was your foundation insulation ever upgraded?": "Was your foundation insulation ever upgraded?",
                "Was your wall insulation ever upgraded?": "Was your wall insulation ever upgraded?",
                "Do you know when your ceiling/attic insulation was upgraded?": "Do you know when your ceiling/attic insulation was upgraded?",
                "cwp-sub-title-ceiling_insulation_year": "If you are unsure, you can select from the following estimated year ranges below",
                "Do you know when your foundation insulation was upgraded?": "Do you know when your foundation insulation was upgraded?",
                "cwp-sub-title-foundation_insulation_year": "If you are unsure, you can select from the following estimated year ranges below",
                "Do you know when your wall insulation was upgraded?": "Do you know when your wall insulation was upgraded?",
                "cwp-sub-title-wall_insulation_year": "If you are unsure, you can select from the following estimated year ranges below",
                "Does your home have an electric vehicle charger?": "Does your home have an electric vehicle charger?",
                "Does your home have a solar photovoltaic (PV) system?": "Does your home have a solar photovoltaic (PV) system?",
                "SUBMIT": "SUBMIT",
                "cwp-sub-title-has_solar_pv_system": "Solar PV sytems convert solar energy to electricity.",
                "Your Home Energy Report is Being Updated ...": "Your Home Energy Report is Being Updated ...",
                "BACK TO MY HOME": "BACK TO MY HOME",
                "Fill in your Home Information Survey": "Fill in your Home Information Survey",
                "Improve the Accuracy of Your Report": "Improve the Accuracy of Your Report",
                "Water Heater": "Water Heater",
                "Ceiling/Attic Insulation": "Ceiling/Attic Insulation",
                "HRV": "HRV",
                "Your Recommendation Settings": "Your Recommendation Settings",
                "Reducing my Energy Use": "Reducing my Energy Use",
                "cdd-header-homeowner-priorities": "<0>Update this <1>optional</1> section to personalize your Retrofit Scenarios.</0>",
                "Snooze Alert": "Snooze Alert",
                "There are currently no outstanding notifications for this building.": "There are currently no outstanding notifications for this building.",
                "Delete my Profile": "Delete my Profile",
                "Can't Share More": "Can't Share More",
                "Building Settings": "Building Settings",
                "You are in control of how much data you share publicly": "You are in control of how much data you share publicly",
                "Profile Settings": "Profile Settings",
                "Delete My Account": "Delete My Account",
                "Delete": "Delete",
                "cst-del-step-1": "Delete your <1>Profile</1> deletes any information about you (login information, priorities, etc.).<br/><br/><5>Are you sure you want to delete your Profile?</5><br/><7><0></0> This cannot be undone.</7>",
                "cst-del-step-1-conf-no": "<0><0>No</0>, Keep my profile.</0>",
                "cst-del-step-1-conf-yes": "<0><0>Yes</0>, delete my profile.</0>",
                "Welcome to your Climative Home Energy Report! This is the start of your retrofit journey, helping you reduce your home’s emissions, make it more comfortable, and save money.": "Welcome to your Climative Home Energy Report! This is the start of your retrofit journey, helping you reduce your home’s emissions, make it more comfortable, and save money.",
                "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.": "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.",
                "Learn how different retrofit scenarios will impact your home. Each retrofit scenario shows the estimated change to your home’s energy and emissions, as well as the financial impact.": "Learn how different retrofit scenarios will impact your home. Each retrofit scenario shows the estimated change to your home’s energy and emissions, as well as the financial impact.",
                "Your home is first assessed using publicly-available data, so the ratings and recommendations start with what’s typical for a home like yours. Increase the accuracy of your Home Energy Report by providing more info through the survey.": "Your home is first assessed using publicly-available data, so the ratings and recommendations start with what’s typical for a home like yours. Increase the accuracy of your Home Energy Report by providing more info through the survey.",
                "Everyone has different priorities when making energy efficiency upgrades.\n\nWith the Personalize My Recommendations feature, you can get retrofit scenarios customized based on your priorities.": "Everyone has different priorities when making energy efficiency upgrades.\n\nWith the Personalize My Recommendations feature, you can get retrofit scenarios customized based on your priorities.",
                "cdb-dont-show-again": "Don’t Show this Again",
                "hmp-intro-step-title-1": "Get Your Free Personalized Home Energy Report",
                "hmp-intro-step-1-content-line-0": "Save money, make your home more comfortable, and lower your emissions with your personalized Home Energy Report.",
                "hmp-intro-step-title-2": "First: Claim Your Home to Access Your Home Energy Report",
                "hmp-intro-step-2-content-line-0": "Enter an address or click a home on the Map to view its Home Rating. Log in to view your detailed Report that includes retrofit recommendations.",
                "hmp-intro-step-no-3": "{{_step}} of {{NUM_STEPS}}",
                "hmp-intro-step-title-3": "Then: Improve Your Retrofit Recommendations with the Building Questionnaire",
                "hmp-intro-step-3-content-line-0": "Fill in more information to improve how well your Report and recommendations represent your home.",
                "GO CLAIM MY HOME": "GO CLAIM MY HOME",
                "hmp-intro-step-no-4": "{{_step}} of {{NUM_STEPS}}",
                "hmp-intro-step-title-4": "Finally: Take Action!",
                "hmp-intro-step-4-content-line-0": "Once you’ve understood your retrofit recommendations, it’s time to take action! The app will direct you to resources like funding support and energy efficiency professionals.",
                "furnace (forced air)": "furnace (forced air)",
                "A typical": "A typical",
                "has an expected life of around": "has an expected life of around",
                "years.": "years.",
                "Your": "Your",
                "It is recommended you start looking into replacement equipment.": "It is recommended you start looking into replacement equipment.",
                "boiler": "boiler",
                "wood stove": "wood stove",
                "electric baseboard": "electric baseboard",
                "heat pump": "heat pump",
                "fireplace": "fireplace",
                "conventional/tank water heater": "conventional/tank water heater",
                "tankless instantaneous water heater": "tankless instantaneous water heater",
                "solar water heater": "solar water heater",
                "heat pump water heater": "heat pump water heater",
                "condensing water heater": "condensing water heater",
                "combination boiler": "combination boiler",
                "central single package system": "central single package system",
                "central split system (ducted)": "central split system (ducted)",
                "conventional a/c": "conventional a/c",
                "mini-split heat pump (ductless)": "mini-split heat pump (ductless)",
                "window a/c": "window a/c",
                "Your Retrofit Scenarios were updated.": "Your Retrofit Scenarios were updated.",
                "Your Home Rating and Retrofit Scenarios were updated.": "Your Home Rating and Retrofit Scenarios were updated.",
                "Furnace (Forced Air) Replacement": "Furnace (Forced Air) Replacement",
                "Boiler Replacement": "Boiler Replacement",
                "Boiler": "Boiler",
                "Wood Stove Replacement": "Wood Stove Replacement",
                "Electric Baseboard Replacement": "Electric Baseboard Replacement",
                "Heat Pump Replacement": "Heat Pump Replacement",
                "Fireplace Replacement": "Fireplace Replacement",
                "Conventional/ Tank Water Heater Replacement": "Conventional/ Tank Water Heater Replacement",
                "Conventional/ Tank Water Heater": "Conventional/ Tank Water Heater",
                "Tankless Instantaneous Water Heater Replacement": "Tankless Instantaneous Water Heater Replacement",
                "Tankless Instantaneous Water Heater": "Tankless Instantaneous Water Heater",
                "Solar Water Heater Replacement": "Solar Water Heater Replacement",
                "Solar Water Heater": "Solar Water Heater",
                "Heat Pump Water Heater Replacement": "Heat Pump Water Heater Replacement",
                "Heat Pump Water Heater": "Heat Pump Water Heater",
                "Condensing Water Heater Replacement": "Condensing Water Heater Replacement",
                "Condensing Water Heater": "Condensing Water Heater",
                "Combination Boiler Replacement": "Combination Boiler Replacement",
                "Combination Boiler": "Combination Boiler",
                "Central Single Package System Replacement": "Central Single Package System Replacement",
                "Central Single Package System": "Central Single Package System",
                "Central Split System (Ducted) Replacement": "Central Split System (Ducted) Replacement",
                "Conventional A/C Replacement": "Conventional A/C Replacement",
                "Mini-Split Heat Pump (Ductless) Replacement": "Mini-Split Heat Pump (Ductless) Replacement",
                "Mini-Split Heat Pump (Ductless)": "Mini-Split Heat Pump (Ductless)",
                "Window A/C Replacement": "Window A/C Replacement",
                "Home Energy Report Updated": "Home Energy Report Updated",
                "Report Uploaded on": "Report Uploaded on",
                "Click Here to Upload EnerGuide Label": "Click Here to Upload EnerGuide Label",
                "pdf format, Maximum of 2 MB": "pdf format, Maximum of 2 MB",
                "cdb-sem-info": "If you had on EnerGuide evaluation performed for your home and provided them with your email address, you were likely emailed a pdf copy of the EnerGuide Label.",
                "Your EnerGuide Label": "Your EnerGuide Label",
                "Report Uploaded on 2024-06-13": "Report Uploaded on 2024-06-13",
                "Download": "Download",
                "Verified with EnerGuide label": "Verified with EnerGuide label",
                "Verified": "Verified",
                "View/Replace": "View/Replace",
                "cdb-sem-info-update-2": "If you have had a more recent EnerGuide evaluation performed, upload your EnerGuide Label to update your home.<br/><br/>This will replace the existing EnerGuide Label on file.",
                "Recommendation Personalization Settings Change": "Recommendation Personalization Settings Change",
                "Survey Submission": "Survey Submission",
                "Structure Data Automatically Updated": "Structure Data Automatically Updated",
                "Modelling Level:": "Modelling Level:",
                "Update Age": "Update Age",
                "Go to survey to update": "Go to survey to update",
                "age": "age",
                "Triggered by:": "Triggered by:",
                "Snooze": "Snooze",
                "Today": "Today",
                "Unable to claim your home, the address is used by another building.": "Unable to claim your home, the address is used by another building.",
                "Unable to claim your home, please try again later.": "Unable to claim your home, please try again later.",
                "Sucessfully claimed your home.": "Sucessfully claimed your home.",
                "You are unable to claim this home, because it is currently associated with another user’s account.": "You are unable to claim this home, because it is currently associated with another user’s account.",
                "You are unable to claim this home, because the address is used by another building.": "You are unable to claim this home, because the address is used by another building.",
                "Unable to claim your home, please try again later.": "Unable to claim your home, please try again later.",
                "Successfuly removed a property from your account.": "Successfuly removed a property from your account.",
                "Your email was verified": "Your email was verified",
                "Password has been updated": "Password has been updated",
                "Email with resetting instructions has been sent": "Email with resetting instructions has been sent",
                "You've been registered successfully. Please check your email for verification link": "You've been registered successfully. Please check your email for verification link",
                "Customer info has been Updated!": "Customer info has been Updated!",
                "Failed to update customer info.": "Failed to update customer info.",
                "EnerGuide Report uploaded.": "EnerGuide Report uploaded.",
                "Error uploading EnerGuide Report": "Error uploading EnerGuide Report",
                "EnerGuide Report deleted.": "EnerGuide Report deleted.",
                "Error deleting EnerGuide Report": "Error deleting EnerGuide Report",
                "Report has been published!": "Report has been published!",
                "Failed to publish reporo.": "Failed to publish report.",
                "Building Updated": "Building Updated",
                "Building has been Updated!": "Building has been Updated!",
                "Notes have been saved!": "Notes have been saved!",
                "Priorities have been saved!": "Priorities have been saved!",
                "Report has been published!": "Report has been published!",
                "Failed to publish report.": "Failed to publish report.",
                "Report has been Generated!": "Report has been Generated!",
                "Report has been Generated!": "Report has been Generated!",
                "Test complete.": "Test complete.",
                "Building has been Updated!": "Building has been Updated!",
                "Changes have been saved!": "Changes have been saved!",
                "Report has been Updated!": "Report has been Updated!",
                "Sucessfully updated your priorities.": "Sucessfully updated your priorities.",
                "No Home Selected": "No Home Selected",
                "Add your home to view your home energy report.": "Add your home to view your home energy report.",
                "Find My Home": "Find My Home",
                "This may take a minute, please wait...": "This may take a minute, please wait...",
                "new-search-info-bubble": "To get started, Enter your home address or navigate the map to find and claim your home",
                "Step 1": "Step 1",
                "simptek-copyright": "Powered by <1>Climative</1>",
                "Dashboard Unselected": "Dashboard Unselected",
                "Unselected Dashboard": "Unselected Dashboard",
                "Dashboard Selected": "Dashboard Selected",
                "Selected Dashboard": "Selected Dashboard",
                "Video Home Energy Assessment Sign Up ": "Video Home Energy Assessment Sign Up ",
                "Dashboard Frequently Asked Questions": "Dashboard Frequently Asked Questions",
                "Dashboard FAQ": "Dashboard FAQ",
                "Set Up Profile": "Set Up Profile",
                "Home Energy Assessment - Plan Retrofits": "Home Energy Assessment - Plan Retrofits",
                "Cost: FREE": "Cost: FREE",
                "Sign Up Here": "Sign Up Here",
                "Perform Retrofits": "Perform Retrofits",
                "Home Energy Assessment - Validate Retrofits": "Home Energy Assessment - Validate Retrofits",
                "Receive Financial Incentives": "Receive Financial Incentives",
                "cdb-step1-1-title-ae": "Claim My Home",
                "cdb-step2-1-title-ae": "Sign Up for Video Home Energy Assessment",
                "cdb-substep-2-1-unchecked": "<0>Cost: FREE</0><1>Sign Up Here</1>",
                "cdb-step2-2-title-ae": "Book Appointment with Energy Advisor",
                "cdb-substep-2-2-unchecked-ae": "Your Energy Advisor will reach out to you to book your appointment.",
                "cdb-step2-3-title-ae": "Video Home Energy Assessment",
                "cdb-substep-2-3-unchecked-ae": "A Registered Energy Advisor will model your home on a video call",
                "cdb-step2-4-title-ae": "Get Published Home Energy Report",
                "cdb-substep-2-4-unchecked-ae": "Once your energy advisor publishes your report, you can see it here!",
                "Wall Insulation": "Wall Insulation",
                "Close Modal": "Close Modal",
                "None selected": "None selected",
                "Choose your service provider": "Choose your service provider",
                "For more information, go to:": "For more information, go to:",
                "Confirm": "Confirm",
                "Service Provider:": "Service Provider:",
                "Successfully submitted request.": "Successfully submitted request.",
                "The Retrofits Have Started": "The Retrofits Have Started",
                "cdb-step3-0-title-ae": "Start Retrofits",
                "Upgrade Package": "Upgrade Package",
                "Remote Auditor Upgrade Package.": "Remote Auditor Upgrade Package.",
                "Energy Advice Report": "Energy Advice Report",
                "Your Energy Advice Report": "Your Energy Advice Report",
                "The Retrofits Are Complete": "The Retrofits Are Complete",
                "cdb-step3-1-title-ae": "Retrofit Completion",
                "Retrofit Validation Report": "Retrofit Validation Report",
                "Your Retrofit Validation Report": "Your Retrofit Validation Report",
                "cdb-step4-1-title-ae": "Sign Up for Video Home Energy Assessment",
                "cdb-step4-2-title-ae": "Book Appointment with Energy Advisor",
                "cdb-substep-4-2-unchecked-ae": "Your Energy Advisor will reach out to you to book your appointment.",
                "cdb-step4-3-title-ae": "Video Home Energy Assessment",
                "cdb-substep-4-3-unchecked-ae": "A Registered Energy Advisor will model your home on a video call",
                "cdb-step4-4-title-ae": "Get Published Home Energy Report",
                "cdb-substep-4-4-unchecked-ae": "Once your energy advisor publishes your report, you can see it here!",
                "Financial Incentives Received": "Financial Incentives Received",
                "cdb-step5-0-title-ae": "Receive Financial Incentives",
                "/year in do-nothing case": "/year in do-nothing case",
                "btu/hr": "btu/hr",
                "Heating Efficiency Rating": "Heating Efficiency Rating",
                "Financial Impact Projection": "Financial Impact Projection",
                "This graph shows the complete financial impact over years. This includes all financial aspects considered in your retrofit scenario.": "This graph shows the complete financial impact over years. This includes all financial aspects considered in your retrofit scenario.",
                "Individual Retrofit Impact": "Individual Retrofit Impact",
                "Select Metric": "Select Metric",
                "crp-rebeate-warning": "<0>Actual rebates may differ from estimates shown.</0>The estimated rebates shown do not account for specific details of your home or equipment which may impact actual rebate amounts.",
                "year financial impact:": "year financial impact:",
                "in savings": "in savings",
                "Get Your Upgrades Validated": "Get Your Upgrades Validated",
                "SHOW LESS": "SHOW LESS",
                "Pompe à chaleur mini-split": "Pompe à chaleur mini-split",
                "Save As My Home": "Save As My Home",
                "hmp-cbh-msg-top": "We do not have information for<br/>this building yet.",
                "hmp-cbh-msg-bottom": "You can update this home and get a Home Energy Report with a short questionnaire.<br/><br/>Note: If this building on the map is associated with the incorrect address, you can fix this in the questionnaire",
                "Update this short questionnaire to get a Home Energy Report": "Update this short questionnaire to get a Home Energy Report",
                "Confirm address": "Confirm address",
                "Submit Survey": "Submit Survey",
                "Confirm this is your Home": "Confirm this is your Home",
                "Yes, this is my home": "Yes, this is my home",
                "hmp-confirm-claim-title": "Confirm claiming this home?",
                "Step 2": "Step 2",
                "Claim Your Home": "Claim Your Home",
                "hmp-cta-title-e1": "Your Carbon- & Money-Saving Journey Starts Here",
                "hmp-cta-button-title-e1": "Claim My Home",
                "hmp-intro-step-title-1-e1": "Welcome to Your Personal Energy Navigator!",
                "hmp-intro-step-1-content-line-0-e1": "This application will help you book a Video Home Energy Assessment, see the results in an easy-to-understand way, and more!",
                "hmp-intro-step-title-2-e1": "First: Create Account to Sign Up for Your Video Home Energy Assessment",
                "hmp-intro-step-2-content-line-0-e1": "Once you create an account and claim your home with your account, you will be able to book a Video Home Energy Assessment.",
                "hmp-intro-step-title-3-e1": "Next: Video Home Energy Assessment with an Energy Advisor",
                "hmp-intro-step-3-content-line-0-e1": "Next, you will have your video home energy assessment, a video call with an energy advisor who will model a home energy retrofit plan for you.",
                "hmp-intro-step-title-4-e1": "Then: Get Your Report and Take Action!",
                "hmp-intro-step-4-content-line-0-e1": "After your energy advisor publishes the results of your energy assessment, you can see them here. After you perform your retrofits, you can come back and follow the same process for the post-upgrade video home energy assessment too!",
                "Please select a service organization": "Please select a service organization",
                "20 yrs": "20 yrs",
                "Reduced": "Reduced",
                "EMISSIONS": "EMISSIONS",
                "ENERGY USE": "ENERGY USE",
                "Retrofit Package Savings": "Retrofit Package Savings",
                "Foundation Insulation": "Foundation Insulation",
                "Annual Energy Bills ($)": "Annual Energy Bills ($)",
                "Range: 1 to 20 Years": "Range: 1 to 20 Years",
                "Financial Impact ($)": "Financial Impact ($)",
                "Range: 1 to 25 Years": "Range: 1 to 25 Years",
                "Tonnes": "Tonnes",
                "Acts like a snug blanket around your home, locking in warmth and cutting energy expenses.": "Acts like a snug blanket around your home, locking in warmth and cutting energy expenses.",
                "Starting Insulation Level": "Starting Insulation Level",
                "Insulation Added": "Insulation Added",
                "New Insulation Level": "New Insulation Level",
                "Wall Insulation Modeled": "Wall Insulation Modeled",
                "More modern windows have better weather seals and insulating glass panels, helping to lower heating and cooling costs.": "More modern windows have better weather seals and insulating glass panels, helping to lower heating and cooling costs.",
                "# of Windows Replaced": "# of Windows Replaced",
                "# of Doors Replaced": "# of Doors Replaced",
                "Windows & Doors Modeled": "Windows & Doors Modeled",
                "Like a warm hat for your home; keeps it warmer in winter and cooler in summer, reducing energy bills.": "Like a warm hat for your home; keeps it warmer in winter and cooler in summer, reducing energy bills.",
                "Ceiling Insulation Modeled": "Ceiling Insulation Modeled",
                "Like winter boots for your home; leads to warmer floors, fewer drafts, and lower heating costs.": "Like winter boots for your home; leads to warmer floors, fewer drafts, and lower heating costs.",
                "Foundation Insulation Modeled": "Foundation Insulation Modeled",
                "Initial financial impact:": "nitial financial impact:",
                "in costs": "in costs",
                "Years": "Years",
                "Projected Savings": "Projected Savings",
                "Break-even point: ": "Break-even point: ",
                "years": "years",
                "Fenêtres et portes": "Fenêtres et portes",
                "years or less": "years or less",
                "Close": "Close",
                "cdd-modal-homeowner-priorities-point-1": "Data you enter on this screen may be anonymized and shared on a regional level<br/><br/><i>For example, your local government may see that 73% of their residents said saving money is the most important factor in making decisions about energy efficiency.</i>",
                "cdd-modal-homeowner-priorities-point-2": "Individual data you enter on this screen will NOT be accessible by other users. It will ONLY be accessible by the user who inputted the data (you).",
                "cdd-modal-homeowner-survey-0-point-1": "Your Home Rating and Retrofit Scenarios will be updated based on data provided on this screen.",
                "cdd-modal-homeowner-survey-0-point-2": "Building Data you enter about your building, and any derived data may be shared with future homeowners, and with organizations aiming to reduce carbon emissions in your area (like your regional government)",
                "cdd-modal-homeowner-survey-0-point-3": "There is no Personal Data in this survey.",
                "Areas": "Areas",
                "1970 to 1980": "1970 to 1980",
                "Exterior Doors": "Exterior Doors",
                "Known Building Information": "Known Building Information",
                "This data was provided by a high-confidence data provider and is not editable": "This data was provided by a high-confidence data provider and is not editable",
                "Type Of House": "Type Of House",
                "Single Detached": "Single Detached",
                "Double/Semi-Detached": "Double/Semi-Detached",
                "Row House, Middle Unit": "Row House, Middle Unit",
                "Row House, End Unit": "Row House, End Unit",
                "Duplex": "Duplex",
                "Mobile Home": "Mobile Home",
                "Year Of Construction": "Year Of Construction",
                "Floors Above Grade": "Floors Above Grade",
                "Total Heated Floor Area": "Total Heated Floor Area",
                "Building Footprint": "Building Footprint",
                "Heated Basement": "Heated Basement",
                "No": "No",
                "Yes": "Yes",
                "Read more": "Read more",
                "Preferred Contact Phone Number": "Preferred Contact Phone Number",
                "Enter your contact number": "Enter your contact number",
                "preferred-phone-desc": "We will share the following information with your energy advisor so they can contact you to schedule your remote audit.<br/><br/>The email address on your account andyour preferred contact phone number.",
                "I agree to the terms and conditions stated above": "I agree to the terms and conditions stated above",
                "Save and Continue": "Save and Continue",
                "Preferred Language": "Preferred Language",
                "Select Language": "Select Language",
                "Please enter your preferred phone number and language": "Please enter your preferred phone number and language",
                "Energy Bill Savings (1st Year)": "Energy Bill Savings (1st Year)",
                "Energy Bill Costs (1st Year)": "Energy Bill Costs (1st Year)",
                "hmp-cbh-addr-msg-top": "We were unable to automatically match the address to a building on the map. Try clicking on the map to find a match for your address.",
                "hmp-cbh-addr-msg-bottom": "You can click anywhere on the map (whether there is a building there or not). Most of the time, this resolves this issue<br/>"
            }
        },
        "fr": {
            "translation": {
                "Climative Low Carbon Communities": "Climative Communautés à Faible Émissions",
                "Loading Animation": "Animation de chargement",
                "Climative | Low Carbon Communities": "Climative | Communautés à Faible Émissions",
                "Home Energy Report": "Rapport sur l'énergie domestique",
                "Low Carbon Communities": "Communautés à Faible Émissions",
                "Search": "Rechercher",
                "Enter your home address": "Entrez votre addresse",
                "Intro Model": "Modèle d'introduction",
                "People discussing energy charts on a computer": "Les gens discutent de graphiques d'énergie sur un ordinateur",
                "Next": "Suivant",
                "FAQ Modal": "Modal FAQ",
                "Close FAQ": "Fermer FAQ",
                "Expand FAQ Item": "Développer l'élément FAQ",
                "Energy Use Icon": "Icône d'utilisation de l'énergie",
                "Environment Impact Icon": "Icône d'impact environnemental",
                "Icon portraying various types of energy": "Icône représentant divers types d'énergie",
                "Charts on a computer screen": "Graphiques sur l'écran d'un ordinateur",
                "Grey Home Icon": "Icône de maison grise",
                "Close Result": "Fermer résultat",
                "Emissions": "Émissions",
                "Energy": "Énergie",
                "Find your home": "Trouvez votre maison",
                "hmp-intro-step-no-1": "{{_step}} de {{NUM_STEPS}}",
                "hmp-intro-skip": "Sauter",
                "hmp-how-title": "Questions fréquentes",
                "hmp-faq-title-0": "Comment a été estimé l'utilisation d'énergie de ma maison ?",
                "hmp-faq-content-0": "<0>Nous avons utilisé <1> des informations sur les bâtiments disponibles publiquement</1> sur votre maison, et <3> les avons comparées à des milliers de maisons similaires</3> pour estimer l'utilisation d'énergie de votre maison.</0>",
                "hmp-faq-title-1": "Comment a été estimé la cote d'émissions de ma maison?",
                "hmp-faq-content-1": "<0>La cote d'émissions de votre maison dépend de son <1>utilisation estimée d'énergie</1> (voir la question ci-dessus) et des <3>types d'énergie</3> utilisés dans votre maison.</0>",
                "hmp-faq-title-2": "Pourquoi mon utilisation d'énergie est bonne mais mon ma cote d'émission est mauvaise ? (ou vice versa)",
                "hmp-faq-content-2": "<0>La cote d'émissions de votre maison est considérablement affectée par les <1>émissions des types d'énergie</1> utilisés par votre maison. Si votre maison utilise une quantité relativement faible d'énergie mais que ce type d'énergie provient d'une source d'énergie à haute émissions, votre cote émissions peut encore être mauvais.",
                "hmp-faq-title-3": "Je pense que le classement de ma maison est faux. Puis-je le corriger?",
                "hmp-faq-content-3": "<0>Oui ! Les informations que vous voyez jusqu'à présent sont basées sur ce qui est <1>normal pour une maison comme la vôtre</1>. Si votre maison a été modernisée ou si elle utilise une source de combustible différente de celle que nous avons estimée, cela peut avoir un grand impact sur le classement de votre maison.<br/><br/><5>Vous pouvez augmenter la précision du classement de votre maison, obtenir des recommandations d'économies d'énergie et plus encore en <1>s'inscrivant</1>, tout cela gratuitement.</5></0>",
                "hmp-faq-title-4": "Puis-je obtenir facilement des informations plus précises et détaillées ?",
                "hmp-faq-content-4": "<0>Oui ! Les informations que vous voyez jusqu'à présent sont basées sur ce qui est <1>normal pour une maison comme la vôtre</1>. Si votre maison a été modernisée ou si elle utilise une source de combustible différente de celle que nous avons estimée, cela peut avoir un grand impact sur le classement de votre maison.<br/><br/>Vous pouvez augmenter la précision du classement de votre maison, obtenir des recommandations d'économies d'énergie et plus encore en <6>s'inscrivant</6>, tout cela gratuitement.</0>",
                "hmp-how-footer": "Inscrivez-vous pour voir votre évaluation détaillée gratuite en 60 secondes, y compris des recommandations et des estimations d'économies.",
                "Address Not Found": "Adresse non trouvée",
                "Please check address and try again": "Veuillez vérifier l'adresse et réessayer",
                "People reviewing a home assessment": "Les gens examinent une évaluation de maison",
                "Done": "Terminé",
                "hmp-intro-step-no-2": "{{_step}} de {{NUM_STEPS}}",
                "hmp-intro-back": "Retour",
                "Search Result": "Résultat de recherche",
                "Home Icon": "Icône de maison",
                "Question Mark Icon": "Icône de point d'interrogation",
                "Environmental Impact Icon": "Icône d'Émissions",
                "Dail from Green (Excellent) to Yellow (Average) to Red (Very Poor)": "Jauge allant du vert (excellent) au jaune (moyen) au rouge (très mauvais)",
                "Very Poor": "Très mauvais",
                "Trees Icon": "Icône d'arbres",
                "Check Icon 1": "Icône de vérification 1",
                "Check Icon 2": "Icône de vérification 2",
                "Check Icon 3": "Icône de vérification 3",
                "Check Icon 4": "Icône de vérification 4",
                "hmp-home-rating": "Classement de maison",
                "FAQ": "FAQ",
                "rrt-environmental-impact": "Émissions",
                "rrt-energy-use": "Utilisation d'énergie",
                "rrti-main-co2-per-year": "{{activeEnvImpact}} tonnes de CO2e/an",
                "rrti-sub-co2-per-year": "Émissions de carbone",
                "rrti-trees": "<0></0> Il faut {{numTrees}} arbres pour éliminer autant de carbone de l'air",
                "hmp-cta-title": "Votre voyage d'économie d'énergie commence ici",
                "hmp-cta-button-title": "Obtenez mes recommandations énergétiques personnalisées",
                "hmp-cta-footer": "Des connaissances gratuits d'économies d'énergie en 60 secondes",
                "hmp-cta-check-1": "Estimations d'économies de rénovation",
                "hmp-cta-check-2": "Coûts estimés de rénovation",
                "hmp-cta-check-3": "Décomposition de l'utilisation d'énergie",
                "hmp-cta-check-4": "Augmentez la précision en mettant à jour les informations du bâtiment",
                "Better than Average": "Mieux que la moyenne",
                "rrti-main-gj-per-year": "{{activeEnergyUse}} GJ/an",
                "rrti-sub-gj-per-year": "Consommation d'énergie annuelle",
                "Collapse FAQ Item": "Réduire l'élément FAQ",
                "Unknown": "Inconnu",
                "Good": "Bon",
                "Worse than Average": "Pire que la moyenne",
                "Poor": "Mauvais",
                "Average": "Moyen",
                "Excellent": "Excellent",
                "Header": "En-tête",
                "Search Result (None)": "Résultat de recherche (aucun)",
                "hmp-desktop-banner": "hmp-title-desktop-fr.png",
                "hmp-mobile-banner": "hmp-title-mobile-fr.png",
                "hmp-lang-code": "FR",
                "Language Icon": "Icône de la langue - Français",
                "Language Switcher": "Sélecteur de langue",
                "hmp-lang-radio-fr": "bouton radio français",
                "hmp-lang-radio-en": "bouton radio anglais",
                "hmp-lang-radio-fr-sel": "bouton radio français (choisie)",
                "hmp-lang-radio-en-sel": "bouton radio anglais (choisie)",
                "Dial Pointer": "Pointeur du cadran",
                "Map Switcher": "Changeur de carte",
                "of": "de",
                "Home Details": "Détails de la maison",
                "Insulation": "Isolation",
                "What type of house do you have?": "Quel type de maison avez-vous ?",
                "Enter year": "Entrez l'année",
                "Approximately what year was your house built?": "Vers quelle année votre maison a-t-elle été construite ?",
                "How drafty is your home?": "À quel point votre maison est-elle sujette aux courants d'air ?",
                "How many heated floors/storeys does your house have?": "Combien d'étages chauffés possède votre maison ?",
                "Area": "Surface",
                "What is the total floor area of your home? Only include areas you heat.": "Quelle est la surface totale de votre maison ? Ne comptez que les zones que vous chauffez.",
                "What is the area of your first floor? This helps us know the size of your building's footprint.": "Quelle est la surface de votre premier étage ? Cela nous aide à connaître la taille de l'empreinte de votre bâtiment.",
                "What type of foundation do you have?": "Quel type de fondation avez-vous ?",
                "CONTINUE": "CONTINUER",
                "Back to Previous Step": "Retournez",
                "cwp-info-text": "Merci d'avoir fourni des informations sur votre maison.<br/><2>Remarque : toutes les entrées sont complètement optionnelles. Vous pouvez changer/fournir n'importe quelle de ces informations dans votre évaluation.</2>",
                "cwp-sub-title-year_built": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages d'années estimées suivantes ci-dessous",
                "cwp-sub-title-total_floor_area_sqft": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages de superficies estimées suivantes ci-dessous",
                "Good Morning": "Bonjour",
                "Good Afternoon": "Bon après-midi",
                "Good Evening": "Bonsoir",
                "View Report": "Voir le rapport",
                "Energy Savings Potential Report": "Rapport sur le potentiel d'économies d'énergie",
                "Your Efficiency Roadmap": "Votre feuille de route d'efficacité",
                "Book Home Energy Assessment": "Réserver une évaluation énergétique à domicile",
                "STEP": "ÉTAPE",
                "PENDING": "EN ATTENTE",
                "Pending": "En Attente",
                "Home Information Survey": "Questionnaire sur le bâtiment",
                "OPTIONAL STEP": "ÉTAPE FACULTATIVE",
                "OPTIONAL": "FACULTATIF",
                "Enter Home Information": "Entrez les informations de la maison",
                "Perform Remote Energy Assessment": "Effectuer une évaluation énergétique à distance",
                "View Recommendations in your Energy Advice Report": "Voir les recommandations dans votre rapport de conseils énergétiques",
                "report-updated-on": "Mis à jour : {{lastPublishedDate}}",
                "cdb-step-no": "ÉTAPE {{number}}",
                "cdb-step1-sub-step-after-va-auto-insights": "<0></0><1>Demande de réservation pour une évaluation énergétique à domicile</1>",
                "cdb-step1-sub-step-before-pre-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><2>Votre conseiller en énergie vous contactera pour prendre rendez-vous.</2>",
                "cdb-step1-sub-step-gte-post-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><2><br/></3>",
                "cdb-step1-sub-step-pre-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><br/><3></3>",
                "cdb-sc-desc-booked": "Votre conseiller en énergie effectuera une évaluation énergétique de votre maison lors d'un appel vidéo avec vous.",
                "cdb-sc-desc-post-survey": "Merci d'avoir pris le temps de fournir plus d'informations sur votre maison.<br/><br/><3>Votre rapport sur le potentiel d'économies d'énergie a été mis à jour. Vous pouvez y accéder en haut de cette page, ou en cliquant ici.</3><br/><br/>Vous voulez toujours fournir plus d'informations ?<br/>",
                "Heating and Cooling": "Chauffage et climatisation",
                "Insulation R-Value": "Valeur R de l'isolation",
                "How much insulation is in your ceiling/attic?": "Combien d'isolant y a-t-il dans votre plafond/grenier ?",
                "How much insulation is in your walls?": "Combien d'isolant y a-t-il dans vos murs ?",
                "Doors": "Portes",
                "How many exterior doors do you have?": "Combien de portes extérieures avez-vous ?",
                "Windows": "Fenêtres",
                "How many windows do you have?": "Combien de fenêtres avez-vous ?",
                "How many of your windows are ENERGY STAR (or energy efficient)?": "Combien de vos fenêtres sont ENERGY STAR (ou économes en énergie) ?",
                "How many of your doors are ENERGY STAR (or energy efficient)?": "Combien de vos portes sont ENERGY STAR (ou économes en énergie) ?",
                "Finish": "Fin",
                "What kind of primary heating system do you have?": "Quel type de système de chauffage principal avez-vous ?",
                "Do you have a secondary heating system?": "Avez-vous un système de chauffage secondaire ?",
                "What type of cooling / air conditioner do you have?": "Quel type de climatisation / climatiseur avez-vous ?",
                "What type of water heating system do you have?": "Quel type de système de chauffage de l'eau avez-vous ?",
                "What fuel type does your water heating system use?": "Quel type de combustible utilise votre système de chauffage de l'eau ?",
                "FINISH": "FIN",
                "cwp-finished-title": "Merci pour votre soumission utile.",
                "cwp-finished-body": "Ces informations seront partagées avec votre conseiller en énergie.<br/><br/>De plus, votre Rapport de potentiel d'économies d'énergie a été mis à jour. Vous pouvez y accéder en haut de la page du tableau de bord ou en cliquant ci-dessous.",
                "Total Energy Savings": "Économies de forfait de mise à niveau",
                "How we came up with this": "Comment nous en sommes arrivés là",
                "Logout Button": "Bouton de déconnexion",
                "Dashboard": "Tableau de bord",
                "Your Energy Savings Potential": "Votre potentiel d'économies d'énergie",
                "Upgrade Package Savings": "Économies de forfait de mise à niveau",
                "Individual Upgrades": "Mises à niveau individuelles",
                "Footer": "Bas de page",
                "crp-how-we": "Comment nous en sommes arrivés là",
                "crp-read-more": "En savoir plus <1></1>",
                "Success!<br/>You have requested a Video Home Energy Assessment.": "Succès !<br/>Vous avez demandé une évaluation vidéo de l'efficacité énergétique de votre maison.",
                "Your energy advisor will reach out to you shortly to book your appointment.": "Votre conseiller en énergie vous contactera sous peu pour prendre rendez-vous.",
                "Go to Home Page": "Aller au rapport",
                "What fuel type does your primary heating system use?": "Quel type de combustible utilise votre système de chauffage principal ?",
                "What Type of Primary Heat Pump do you have?": "Quel type de pompe à chaleur avez-vous comme chauffage principal ?",
                "What kind of secondary heating system do you have?": "Quel type de système de chauffage secondaire avez-vous ?",
                "Your request has been submitted. A service provider will send you an offer soon as possible.": "Votre demande a été soumise. Un fournisseur de services vous enverra une offre dès que possible.",
                "You will be notified via email when you get new offers. You will be able to see any offers on Energy Advisor.": "Vous serez notifié par email lorsque vous recevrez de nouvelles offres. Vous pourrez voir toutes les offres sur le conseiller en énergie.",
                "Select a unit": "Sélectionnez une unité",
                "Home Information Wizard": "Questionnaire sur le bâtiment",
                "Home Details": "Détails de la maison",
                "Insulation": "Isolation",
                "Heating and Cooling": "Chauffage et refroidissement",
                "Space Heating": "Chauffage d'espace",
                "Space Cooling": "Refroidissement de l'espace",
                "Water Heating": "Chauffage à l'eau",
                "Lights, Appliances & Other": "Appareils, Lumières et Autres",
                "Emissions Rating": "Cote\nd'émissions",
                "Energy Rating": "Cote\nénergétique",
                "Energy Use Breakdown": "Consommateurs\nd’énergie",
                "Energy Use Breakdown (one line)": "Consommateurs d’énergie",
                "Low Energy Use": "Faible Utilisation",
                "High Energy Use": "Utilisation Élevée",
                "Your Home": "Votre domicile",
                "Other Homes": "Autres maisons",
                "Estimated Costs": "Coûts estimés",
                "Efficiency NS - Average Rebates": "Efficiency NS - Remboursements Moyens",
                "Greener Homes - Average Rebates": "Greener Homes - Remboursements Moyens",
                "Estimated Annual Savings": "Économies annuelles estimatives",
                "20-Year Savings": "Économies sur 20 ans",
                "15-Year Savings": "Économies sur 15 ans",
                "10-Year Savings": "Économies sur 10 ans",
                "Recommendations & Estimated Savings": "Recommandations et économies estimées",
                "We analyzed your home’s energy usage and basic building information to evaluate which building upgrades could save you the most energy. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Nous avons analysé la consommation d'énergie de votre maison et les informations de base sur le bâtiment pour évaluer quelles améliorations du bâtiment pourraient vous faire économiser le plus d'énergie. Nous avons également estimé les économies monétaires potentielles qui pourraient découler de chaque mise à niveau.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that was automatically recommended.": "Les estimations des coûts estimés étaient basées sur un large échantillon de rénovations de bâtiments effectuées sur des maisons canadiennes et le montant de celles-ci était automatiquement recommandé.",
                "The average rebate value shown is the average rebate homeowners received for performing this upgrade.": "La valeur de remise moyenne indiquée est la remise moyenne que les propriétaires ont reçue pour avoir effectué cette mise à niveau.",
                "Unknown": "Inconnu",
                "Payback Period": "Période de récupération",
                "Unavailable": "Indisponible",
                "Energy Advisor's Comments": "Commentaires du conseiller en énergie",
                "Your Certified Energy Advisor modeled the building upgrades you completed. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Votre conseiller en énergie certifié a modélisé les mises à niveau du bâtiment que vous avez effectuées. Nous avons également estimé les économies monétaires potentielles qui pourraient découler de chaque mise à niveau.",
                "Your Certified Energy Advisor analyzed your energy use and building information you provided to model the best building upgrades to save you energy. We’ve also estimated the potential monetary savings that could come from each upgrade.": "Votre conseiller en énergie certifié a analysé votre consommation d'énergie et les informations sur le bâtiment que vous avez fournies pour modéliser les meilleures améliorations de bâtiment pour vous faire économiser de l'énergie. Nous avons également estimé les économies monétaires potentielles qui pourraient découler de chaque mise à niveau.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that upgrade you implemented.": "Les estimations des coûts estimés étaient basées sur un large échantillon de rénovations de bâtiments effectuées sur des maisons canadiennes et sur le montant de cette rénovation que vous avez mise en œuvre.",
                "The estimated costs estimates were based on a large sample of building upgrades performed on Canadian homes and the amount of that upgrade your Energy Advisor recommended.": "Les estimations des coûts estimés étaient basées sur un large échantillon de mises à niveau de bâtiments effectuées sur des maisons canadiennes et sur le montant de cette mise à niveau recommandée par votre conseiller en énergie.",
                "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade you implemented.": "Les remises estimées fournies étaient basées sur les montants de remise standard pour ce type de mise à niveau et le montant de cette mise à niveau que vous avez mise en œuvre.",
                "The estimated rebates provided were based on standard rebate amounts for that type of upgrade, and the amount of that upgrade your Energy Advisor recommended.": "Les remises estimées fournies étaient basées sur les montants de remise standard pour ce type de mise à niveau et sur le montant de cette mise à niveau recommandé par votre conseiller en énergie.",
                "book-remote-audit-cta": "Pour des résultats encore plus précis et des conseils pratiques, nous vous recommandons de <1>réserver un audit énergétique domestique à distance dès aujourd'hui.</1>",
                "COMPLETE": "COMPLÈTE",
                "Update Home Information": "Mettre à jour les informations sur la maison",
                "Please wait ...": "S'il vous plaît, attendez ...",
                "Energy Auditor Book HEA": "Évaluateur énergétique Réserver l'ÉED",
                "cdb-step1-booking-disabled": "<0>Nous ne sommes plus en mesure d'accepter les demandes d'audit vidéo pour le moment car la période pilote est terminée.</0><1>Nous vous remercions de votre intérêt et nous nous excusons pour la gêne occasionnée.</1>",
                "cdb-step1-sub-step-eq-upg-upgrades-completed-1": "<0></0><1>Demande de réservation d'une évaluation énergétique de la maison</1>",
                "cdb-step1-sub-step-eq-upg-upgrades-completed-2": "<0>Demander une évaluation énergétique de la maison</0>",
                "cdb-step1-sub-step-after-upg-upgrades-completed": "<0></0><1>Demande de réservation d'une évaluation énergétique de la maison</1>",
                "cdb-step1-sub-step-before-post-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><2>Votre conseiller en énergie vous contactera pour prendre rendez-vous.</2>",
                "cdb-step1-sub-step-gte-post-awaiting-audit": "<0></0><1>Évaluateur énergétique Réserver l'ÉED</1><br/><3></3>",
                "cdb-step1-sub-step-eq-pre-auto-insights-2": "<0>Demandez une évaluation énergétique de votre maison !</0>",
                "cdb-sc-desc-call-booked": "Votre conseiller en énergie effectuera une évaluation énergétique de votre maison pour valider vos mises à niveau lors d'un appel vidéo avec vous.",               
                "cdb-sc-desc-val-report-ready": "<0>Vous avez maintenant un rapport de validation de mise à niveau. Vous pouvez y accéder en haut de cette page, ou en cliquant ici.</0><br/><br/>",
                "cdb-sc-desc-report-ready-with-fsp": "<0>Vous disposez maintenant d'un rapport de conseils énergétiques. Vous pouvez y accéder en haut de cette page ou en cliquant ici.</0><br/><br/><3>Prêt à commencer à économiser de l'énergie ?</3><4>Trouver un fournisseur de services</4>",
                "cdb-sc-desc-report-ready-no-fsp": "<0>Vous disposez maintenant d'un rapport de conseils énergétiques. Vous pouvez y accéder en haut de cette page, ou en cliquant ici.</0><br/>",
                "cdb-sc-desc-pre-survey": "Veuillez nous en dire le plus possible sur votre maison en préparation de votre évaluation énergétique.<br/><br/>Cette information sera partagée avec votre conseiller en énergie, et cela aidera à déterminer quelles améliorations conviennent le mieux à votre maison dans votre rapport sur le potentiel d'économies d'énergie mis à jour.",
                "1940 or before": "1940 ou avant",
                "1941 to 1960": "1941 à 1960",
                "1961 to 1980": "1961 à 1980",
                "1981 to 2000": "1981 à 2000",
                "2000-3000 ft²": "2000-3000 pi²",
                "2001 to 2010": "2001 à 2010",
                "2011 to present": "2011 à aujourd'hui",
                "3000-4000 ft²": "3000-4000 pi²",
                "4000-5000 ft²": "4000-5000 pi²",
                "- Unspecified -": "- Non spécifié -",
                "A little drafty": "Un peu de courants d'air",
                "Above average - R16+": "Au dessus de la moyenne - R16+",
                "Above average - R24+": "Au dessus de la moyenne - R24+",
                "Above average - R60+": "Au dessus de la moyenne - R60+",
                "Air-to-Water": "Air-Eau",
                "All": "Tout",
                "Average - Approx. R10": "Moyen - Env. R10",
                "Average - Approx. R18": "Moyen - Env. R18",
                "Average - Approx. R30": "Moyen - Env. R30",
                "Basement": "Sous-sol",
                "Below average - Approx. R5": "Sous la moyenne - env. R5",
                "Below average - Approx. R12": "Sous la moyenne - env. R12",
                "Below average - Approx. R15": "Sous la moyenne - env. R15",
                "Central": "Central",
                "Central Split System (Ducted)": "Système de séparation central (canalisé)",
                "Central single package system": "Système de paquet unique central",
                "Combination boiler": "Chaudière mixte",
                "Condensing water heater": "Chauffe-eau à condensation",
                "Conventional A/C": "Climatisation conventionnelle",
                "Conventional/Tank Water Heater": "Chauffe-eau conventionnel/réservoir",
                "Crawlspace": "Vide sanitaire",
                "Electric Baseboard": "Plinthe électrique",
                "Electric Baseboard / Space Heater": "Plinthe électrique / Chaufferette",
                "Electricity": "Électricité",
                "Enter R-Value": "Entrez la valeur R",
                "Enter area": "Entrez zone",
                "Enter number of exterior doors": "Entrez le nombre de portes extérieures",
                "Enter number of windows": "Entrez le nombre de fenêtres",
                "Fireplace": "Cheminée",
                "Furnace (Forced Air)": "Fournaise (air pulsé)",
                "Geothermal": "Géothermie",
                "Heat Pump": "Pompe à chaleur",
                "Heat Pump (hybrid) water heater": "Chauffe-eau à pompe à chaleur (hybride)",
                "Hot Water Radiators and Boiler": "Radiateurs à eau chaude et chaudière",
                "How much insulation is on your foundation walls?": "Quel est le degré d'isolation de vos murs de fondation?",
                "In-Floor Radiant & Boiler": "Radiant et chaudière au sol",
                "Is your basement heated?": "Votre sous-sol est-il chauffé ?",
                "Is your crawlspace heated?": "Votre vide sanitaire est-il chauffé ?",
                "Mini-Split": "Mini-Split",
                "Mini-split Heat Pump (ductless)": "Thermopompe mini-split (sans conduit)",
                "N/A": "N/A",
                "Natural Gas": "Gaz naturel",
                "No": "Non",
                "None": "Aucune",
                "Not Installed": "Pas installé",
                "Not drafty": "Pas de courants d'air",
                "Oil": "Huile",
                "Other": "Autre",
                "Propane": "Propane",
                "Slab": "Dalle",
                "Solar": "Solaire",
                "Solar water heater": "Chauffe-eau solaire",
                "Tankless/Instantaneous Water Heater": "Chauffe-eau sans réservoir/instantané",
                "Unsure": "Incertaine",
                "Very drafty": "Très courant d'air",
                "Window A/C": "Climatisation de fenêtre",
                "Wood": "Bois",
                "Wood / Pellet Stove": "Poêle à Bois / Pellets",
                "Wood Stove": "Poêle à bois",
                "YYYY": "AAAA",
                "Yes": "Oui",
                "ft²": "pi²",
                "less than 100 m²": "moins que 100 m²",
                "less than 1000 ft²": "moins que 1000 pi²",
                "m²": "m²",
                "type_of_house__mobile_home": "<0><0></0><br/>Mobil-<br/>Home</0>",
                "type_of_house__row_house_end_unit": "<0><0></0><br/>Maison en rangée -<br/>Unité de bout</0>",
                "type_of_house__row_house_middle_unit": "<0><0></0><br/>Maison en rangée -<br/>Unité du milieu</0>",
                "type_of_house__semi_detached": "<0><0></0><1></1><br/>Double ou Semi-<br/>Détaché</0>",
                "type_of_house__single_detached": "<0><0></0><br/>Individuelle<br/>Isolée</0>",
                "1500-2000 ft²": "1500-2000 pi²",
                "1000-1500 ft²": "1000-1500 pi²",
                "Next": "Suivant",
                "Sign In To Energy Navigator": "Connectez-vous au Navigateur D'Énergie",
                "Phone Number": "Numéro de téléphone",
                "Pin Number": "Code PIN",
                "Login": "Connexion",
                "simptek-login-footer": "<1>© Climative 2023</1>",
                "Loading...": "Chargement...",
                "Could not find a customer matching this phone number & pin number.": "Impossible de trouver un client correspondant à ce numéro de téléphone et à ce code PIN.",
                "Please fill out this field.": "Veuillez remplir ce champ.",
                "Estimated Rebates": "Rabais estimés",
                "Average Rebates": "Remises moyennes",
                "Want a more accurate rating?": "Voulez-vous une évaluation plus précise ?",
                "Ready to start saving energy?": "Prêt à commencer à économiser de l'énergie ?",
                "Upgrade Packages": "Scénarios de rénovation",
                "Your Energy Savings Potential Report": "Votre Potentiel d'Économies d'Énergie",
                "Take Building Info Survey": "Remplir le Questionnaire !",
                "FAQ - Energy & Emissions Ratings": "FAQ - Cotes d'énergie et d'émissions",
                "FAQ – Upgrades": "FAQ – Mises à jour",
                "crp-faq-rating-title-0": "Comment la cote énergétique de ma maison a-t-elle été estimée ?",
                "crp-faq-rating-content-0": "<0><0>Nous utilisons toutes les sources de données ci-dessous lorsqu'elles sont disponibles pour comparer votre maison à des milliers de maisons similaires afin d'estimer votre consommation d'énergie.</0><1><0>•</0><1>Largement -Données disponibles (évaluation de la propriété, données météorologiques et autres)</1></1><2><0>•</0><1>Votre soumission d'enquête</1></2><3><0 >•</0><1>Évaluation par un conseiller en énergie professionnel (audit énergétique à distance ou évaluation ÉnerGuide sur place)</1></3><4><0>•</0><1>Consommation d'énergie Données</1></4><5>Lorsque davantage de données deviennent disponibles (nous n'obtenons plus de données qu'avec le consentement de l'utilisateur), nous réexécutons l'analyse.</5><6>Les sources de données utilisées pour votre maison sont répertoriées dans la section Sources de données de la page Rapport sur les économies d'énergie.</6></0>",
                "crp-faq-rating-title-1": "Comment la cote d'émissions de ma maison a-t-elle été estimée ?",
                "crp-faq-rating-content-1": "<0><0>La cote d'émissions de votre maison dépend de sa cote énergétique estimée (voir la question ci-dessus) et des types de combustibles utilisés dans votre maison.</0><1>Quel(s) type(s) de combustible et quel(s) fournisseur(s) de services publics ) d'où provient votre énergie affecte beaucoup votre taux d'émissions. L'électricité varie le plus, certains services publics d'électricité utilisant principalement des sources propres comme l'hydroélectricité, tandis que d'autres utilisent des sources à fortes émissions comme le charbon pour produire de l'électricité.</1></0>",
                "crp-faq-rating-title-2": "Pourquoi ma cote énergétique est-elle bonne mais ma cote d'émissions est-elle mauvaise ? (Ou vice versa)",
                "crp-faq-rating-content-2": "<0><0>Oui ! Étant donné que nous commençons par évaluer chaque maison avec des données largement disponibles, les cotes commencent comme étant ce qui est normal pour une maison comme la vôtre - cela ne prendra souvent pas en compte les mises à niveau existantes. Vous pouvez augmenter la précision de vos cotes d'énergie et d'émissions en fournissant plus de données via l'enquête.</0><1>Les sources de données utilisées pour votre maison sont répertoriées dans la section Sources de données de la page Rapport sur les économies d'énergie.</ 1></0>",
                "crp-faq-rating-title-3": "Qu'est-ce que le CO2e ?",
                "crp-faq-rating-content-3": "<0><0>Il existe différents gaz à effet de serre (émissions) qui sont produits à la suite de la consommation d'énergie. Le principal est le dioxyde de carbone (CO2), mais il y en a d'autres. Le CO2e tient également compte des autres gaz, pour donner une image complète de l'impact des émissions de votre maison sur le changement climatique.</0></0>",
                "crp-faq-upgrades-title-0": "Comment mon ou mes forfaits de mise à niveau ont-ils été déterminés ?",
                "crp-faq-upgrades-content-0": "<0><0>Nous avons comparé les données disponibles pour votre maison à des milliers d'autres maisons similaires qui ont effectué des rénovations et avons estimé la meilleure combinaison de rénovations de bâtiments en fonction de vos priorités énergétiques. Vous pouvez définir vos priorités énergétiques dans l'enquête.</0><1>Les sources de données utilisées pour votre maison sont répertoriées dans la section Sources de données de la page Rapport sur les économies d'énergie</1></0>",
                "crp-faq-upgrades-title-1": "Comment l'économie d'énergie de chaque mise à niveau a-t-elle été estimée ?",
                "crp-faq-upgrades-content-1": "<0><0>Climative utilise une combinaison d'intelligence artificielle (IA) et de science du bâtiment pour estimer l'énergie économisée grâce à chaque mise à niveau.</0></0>",
                "crp-faq-upgrades-title-2": "Comment la réduction des émissions due aux mises à niveau a-t-elle été estimée",
                "crp-faq-upgrades-content-2": "<0><0>Le montant de la réduction des émissions pour chaque mise à niveau dépend des économies d'énergie (voir la section ci-dessus) pour cette mise à niveau et des types de combustibles utilisés dans votre maison.</0></0>",
                "crp-faq-upgrades-title-3": "Comment les coûts des mises à niveau ont-ils été estimés ?",
                "crp-faq-upgrades-content-3": "<0><0>Nous avons utilisé les données de RNCan et des entrepreneurs pour estimer le coût des mises à niveau (équipement + installation). Notez que le coût des mises à niveau peut varier considérablement en fonction des détails spécifiques d'une maison.</0></0>",
                "crp-faq-upgrades-title-4": "Comment les projections financières ont-elles été estimées ?",
                "crp-faq-upgrades-content-4": "<0><0>Les projections financières de vos scénarios de rénovation étaient basées sur : les coûts de rénovation estimés après remises, les taux de taxe sur le carbone projetés et les coûts énergétiques projetés.</0></0>",
                "crp-faq-upgrades-title-5": "Puis-je obtenir des recommandations plus précises ?",
                "crp-faq-upgrades-content-5": "<0><0>Oui ! Étant donné que nous commençons par évaluer chaque maison avec des données largement disponibles, les cotes commencent comme étant ce qui est normal pour une maison comme la vôtre - cela ne prendra souvent pas en compte les mises à niveau existantes. Vous pouvez augmenter la précision de vos recommandations de mise à niveau en fournissant plus de données via l'enquête.</0><1>Les sources de données utilisées pour votre maison sont répertoriées dans la section Sources de données de la page Rapport sur les économies d'énergie.</1> </0>",
                "cdb-step1-sub-step-eq-pre-auto-insights-1": "<0></0><1>Demande de réservation d'une évaluation énergétique de la maison</1>",
                "Data Sources": "Les sources de données",
                "Publicly-Available Data": "Données accessibles au public",
                "Utility Consumption Data": "Données de consommation des services publics",
                "crp-data-updated-on": "Mis à jour : {{lastUpdatedDate}}",
                "EnerGuide Label": "Étiquette ÉnerGuide",
                "Your Energy Rating and Upgrade Packages are based on:": "Votre évaluation énergétique et vos forfaits de mise à niveau sont basés sur :",
                "Best": "Meilleur",
                "Better": "Mieux",
                "Current": "Présent",
                "Home Rating": "Évaluation de la maison",
                "Your Survey Submission": "Votre soumission d'enquête",
                "cwp-sub-title-r-select": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages de valeurs R estimées suivantes ci-dessous",
                "Climative": "Climative",
                "Compared Against Similar Homes in my": "Comparé à des maisons similaires dans ma",
                "Municipality": "Municipalité",
                "Neighborhood": "Quartier",
                "Province": "Province",
                "Select Comparison Area": "Sélectionnez la zone de comparaison",
                "Good Upgrade Package": "Scénario de rénovation légère",
                "Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.": "Better Homes Ottawa offre des conseils professionnels, des entrepreneurs qualifiés et des incitatifs pour économiser de l'argent.",
                "Better Upgrade Package": "Scénario de rénovation moyenne",
                "Best Upgrade Package": "Scénario de rénovation approfondie",
                "Helpful Links": "Liens utiles",
                "Better Homes Ottawa Services": "Better Homes Services d'Ottawa",
                "Get help from professionals who will help you what to do next.": "Obtenez l'aide de professionnels qui vous aideront à savoir quoi faire ensuite.",
                "Better Homes Ottawa Loan Program": "Programme de prêts Better Homes Ottawa",
                "The City of Ottawa is piloting a three - year, zero-interest loan program for home energy efficiency retrofits!": "La Ville d'Ottawa met à l'essai un programme de prêt sans intérêt de trois ans pour les rénovations éconergétiques domiciliaires!",
                "Rebate & Incentive Programs": "Programmes de rabais et d'incitation",
                "There are lots of programs available to help make your deep retrofit journey more affordable.": "De nombreux programmes sont disponibles pour vous aider à rendre votre parcours de rénovation en profondeur plus abordable.",
                "Select Package": "Choisir un scénario",
                "Select Upgrade Package": "Sélectionnez le scénario de mise à niveau",
                "Update Preferences": "Préférences de mise à jour",
                "Go to ": "Aller à ",
                "cdb-accept-bills-para": "Votre rapport énergétique domestique et votre évaluation publique (affichés sur la carte des communautés à faible émission de carbone) peuvent être mis à jour avec les données de consommation d'énergie de votre maison fournies par les services publics.<br/><br/><3>Veuillez définir vos préférences ci-dessous :</3>",
                "cdb-accept-eng-checkbox-bc-on": "Récupérer les données disponibles sur la consommation d'énergie d'Hydro One",
                "cdb-accept-eng-checkbox-2-on": "Récupérer les données disponibles sur la consommation d'énergie d'Enbridge",
                "cdb-accept-eng-checkbox-3": "Continuer à récupérer les données de consommation d'énergie de mes services publics à l'avenir",
                "cdb-accept-eng-checkbox-4": "Mettre à jour l'évaluation de la maison publique en fonction des données de service récupérées",
                "cdb-accept-eng-para": "Votre rapport sur l'énergie domestique et la cote publique de votre maison (affichés sur la carte des communautés à faibles émissions de carbone) peuvent être mis à jour avec les rapports ÉnerGuide.<br/><br/><3>Veuillez définir vos préférences ci-dessous :</3>",
                "cdb-accept-bills-checkbox-on": "Récupérez tous les rapports ÉnerGuide existants pour votre maison auprès de Better Homes Ottawa",
                "cdb-accept-bills-checkbox-2-on": "Récupérez tous les futurs rapports ÉnerGuide pour votre maison auprès de Better Homes Ottawa",
                "cdb-accept-bills-checkbox-3": "Mettre à jour la cote publique des maisons en fonction des rapports ÉnerGuide récupérés",
                "Your Priorities": "Vos priorités",
                "When making decisions about energy efficiency upgrades, what is most important to you?": "Lorsque vous prenez des décisions concernant des mises à niveau d'efficacité énergétique, qu'est-ce qui est le plus important pour vous ?",
                "Most important to me": "Le plus important pour moi",
                "Move Down": "Descendre",
                "Saving Money": "Économiser de l'argent",
                "Move Up": "Déplacer vers le haut",
                "Important to me": "Important pour moi",
                "Reducing Carbon Emissions": "Réduction des émissions de carbone",
                "Improving Comfort": "Améliorer le confort",
                "Somewhat important to me": "Un peu important pour moi",
                "Improving my EnerGuide Rating": "Améliorer ma cote ÉnerGuide",
                "Does not affect my decisions": "N'affecte pas mes décisions",
                "This Section is Empty": "Cette section est vide",
                "Save Priorities": "Enregistrer",
                "Personalize Your Recommendations": "Personnalisez vos recommandations",
                "Everyone has different priorities when making energy efficiency upgrades.": "Tout le monde a des priorités différentes lorsqu'il s'agit d'améliorer l'efficacité énergétique.",
                "You can get upgrade packages that are customized based on what you care about by sharing a little info.": "Vous pouvez obtenir des packages de mise à niveau personnalisés en fonction de ce qui vous intéresse en partageant quelques informations.",
                "This is optional and can be updated at any time.": "Ceci est facultatif et peut être mis à jour à tout moment.",
                "Cancel": "Annuler",
                "Share Utility Data": "Partager les données de l'utilitaire",
                "Current state: ": "État actuel: ",
                "Unchecked": "Décoché",
                "Share EnerGuide Report": "Partager le rapport ÉnerGuide",
                "Survey": "Enquête",
                "Share": "Partager",
                "Utility Data": "Données utilitaires",
                "cdb-accept-eng-checkbox-bc": "Récupérer les données disponibles sur la consommation d'énergie de BC Hydro",
                "cdb-accept-eng-checkbox-2-bc": "Récupérer les données disponibles sur la consommation d'énergie de Fortis BC",
                "cdb-accept-bills-checkbox-bc": "Récupérez tous les rapports ÉnerGuide existants pour votre maison auprès de CleanBC",
                "cdb-accept-bills-checkbox-2-bc": "Récupérez tous les futurs rapports ÉnerGuide pour votre maison auprès de CleanBC",
                "Update": "Mise à jour",
                "Rating After Upgrades (vs. ": "Classement après rénos (vs. ",
                "Personalize my Recommendations": "Personnaliser mes recommandations",
                "Free Energy Coaching": "Coaching énergétique gratuit",
                "CleanBC Better Homes provides free energy coaching services for homeowners.": "CleanBC Better Homes offre des services de coaching énergétique gratuits aux propriétaires.",
                "Find a Contractor": "Trouver un entrepreneur",
                "CleanBC Better Homes has a search tool to help you find a registered contractor for your home upgrade needs.": "CleanBC Better Homes dispose d'un outil de recherche pour vous aider à trouver un entrepreneur enregistré pour vos besoins de rénovation domiciliaire.",
                "CleanBC Rebates": "Rabais CleanBC",
                "CleanBC’s Better Homes Program offers professional advice, qualified contractors and money-saving incentives.": "Le programme Better Homes de CleanBC offre des conseils professionnels, des entrepreneurs qualifiés et des incitatifs pour économiser de l'argent.",
                "Welcome to Climative": "Bienvenue chez Climative",
                "Welcome": "Bienvenu",
                "Survey for Increased Accuracy": "Enquête pour une précision accrue",
                "Personalized Recommendations": "Recommandations personnalisées",
                "Welcome to your Climative Home Energy Report! This is the first step towards savings money and reducing your home’s emissions.": "Bienvenue dans votre bilan énergétique climatique de la maison ! C'est la première étape vers des économies d'argent et la réduction des émissions de votre maison.",
                "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.  ": "Nous avons utilisé une combinaison d'intelligence artificielle (IA) et de science du bâtiment pour estimer les émissions, la cote énergétique et la répartition des principaux consommateurs d'énergie de votre maison.",
                "We also modeled different potential upgrade packages for you to consider. We estimated the change to your Energy & Emissions Ratings, as well as financial impact.": "Nous avons également modélisé différents packages de mise à niveau potentiels à prendre en compte. Nous avons estimé la modification de vos cotes d'énergie et d'émissions, ainsi que l'impact financier.",
                "The Data Sources section at the bottom of the Home Energy Report 1) tells you what data is currently being used in your energy report, and 2) lets you add more info so you get the best results.": "La section Sources de données au bas du rapport sur l'énergie domestique 1) vous indique quelles données sont actuellement utilisées dans votre rapport sur l'énergie, et 2) vous permet d'ajouter plus d'informations afin d'obtenir les meilleurs résultats.",
                "We start by assessing homes with widely-available data, so the ratings start as being what’s normal for a home like yours. You can increase the accuracy of your Home Energy Report by providing more data through the survey.": "Nous commençons par évaluer les maisons avec des données largement disponibles, de sorte que les cotes commencent comme étant ce qui est normal pour une maison comme la vôtre. Vous pouvez augmenter la précision de votre rapport sur l'énergie domestique en fournissant plus de données via l'enquête.",
                "Everyone has different priorities when making energy efficiency upgrades.\n\nWith the Personalize My Recommendations feature, you can get upgrade packages customized based on your priorities.": "Tout le monde a des priorités différentes lorsqu'il s'agit d'améliorer l'efficacité énergétique.\n\nGrâce à la fonction Personnaliser mes recommandations, vous pouvez obtenir des forfaits d'amélioration personnalisés en fonction de vos priorités.",
                "Back": "Dos",
                "cdb-dont-show-again": "Ne plus afficher ceci",
                "Checked": "Vérifié",
                "1500-2000 ft²": "1500-2000 ft²",
                "1000-1500 ft²": "1000-1500 ft²",
                "Estimated door age": "Âge estimé de la porte",
                "Enter age in years": "Entrez l'âge en années",
                "0-10 years": "0-10 ans",
                "10-20 years": "10-20 ans",
                "20-30 years": "20-30 ans",
                "30-50 years": "30-50 ans",
                "50+ years": "50+ ans",
                "Approximately how old are your exterior doors?": "Quelle est l'âge approximatif de vos portes extérieures ?",
                "Estimated window age": "Âge estimé de la fenêtre",
                "Approximately how old are your windows?": "Quel âge ont vos fenêtres environ ?",
                "cwp-sub-title-exterior_doors_age": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages estimées suivantes ci-dessous",
                "cwp-sub-title-windows_age": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages estimées suivantes ci-dessous",
                "Primary heating system age": "Âge du système de chauffage primaire",
                "0-5 years": "0-5 ans",
                "5-10 years": "5-10 ans",
                "10-15 years": "10-15 ans",
                "15-20 years": "15-20 ans",
                "30+ years": "30+ ans",
                "Approximately how old is your primary heating system?": "Quel est l'âge approximatif de votre système de chauffage principal ?",
                "Water heating system age": "Âge du système de chauffage de l'eau",
                "Approximately how old is your water heating system?": "Quel âge a environ votre système de chauffage de l'eau ?",
                "cwp-sub-title-primary_heating_system_age": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages estimées suivantes ci-dessous",
                "cwp-sub-title-water_heating_system_age": "Si vous n'êtes pas sûr, vous pouvez choisir parmi les plages estimées suivantes ci-dessous",
                "Home Energy Report updated.": "Rapport sur l'énergie domestique mis à jour.",
                "Your home’s energy modelling has been updated based on the updated building information you submitted.": "La modélisation énergétique de votre maison a été mise à jour en fonction des informations mises à jour sur le bâtiment que vous avez soumises.",
                "Map": "Carte",
                "Report": "Rapport",
                "Sign In": "Connexion",
                "cdb-accept-eng-checkbox-2": "Récupérer les données disponibles sur la consommation d'énergie",
                "rrt-emissions": "Émissions",
                "rrt-energy": "Énergie",
                "Data Privacy": "Confidentialité des données",
                "Share Nothing": "Partager rien",
                "Share Some": "Partagez-en",
                "Share More": "Partager plus",
                "Building Colour on Map": "Couleur du bâtiment sur la carte",
                "Energy and Emissions Rating": "Évaluation de l'énergie et des émissions",
                "Retrofit Scenarios": "Scénarios de rénovation",
                "We weren’t able to find a building with that address.": "Nous n’avons pas pu trouver de bâtiment avec cette adresse.",
                "We couldn’t place this building on the map, but all other features should work normally.": "Nous n’avons pas pu placer ce bâtiment sur la carte, mais toutes les autres fonctionnalités devraient fonctionner normalement.",
                "add-my-home-cta": "Accédez à la <1>FAQ climatique : Trouver ma maison</1> pour plus d'informations.",
                "Energy Navigator": "Navigateur Énergétique",
                "Sign Out": "Déconnexion",
                "Layers": "Couches",
                "1": "1",
                "2": "2",
                "3": "3",
                "4": "4",
                "Waiting for verification ...": "En attente de vérification...",
                "Skip to main Content": "Passer au contenu principal",
                "Report Unselected": "Rapport non sélectionné",
                "Sign  In": "Se  connecter",
                "Change Language": "Changer de langue",
                "Side Menu": "Menu latéral",
                "Selected Map": "Carte sélectionnée",
                "Unselected Report": "Rapport non sélectionné",
                "Selected": "Choisi",
                "Unselected": "Désélectionné",
                "Avg. Annual Energy Bill Savings": "Moyenne. Économies annuelles sur la facture d’énergie",
                "10-Yr Financial Impact": "Impact financier sur 10 ans",
                "20-Yr Financial Impact": "Impact financier sur 20 ans",
                "Estimated Costs after Rebates": "Coûts estimés après remises",
                "1 Week": "1 Semaine",
                "1 Month": "1 Mois",
                "3 Months": "3 Mois",
                "6 Months": "6 Mois",
                "1 Year": "1 An",
                "Home Map": "Carte d'accueil",
                "Climative  Low Carbon Communities": "Climative  Communautés à faible émission de carbone",
                "FAQ – Retrofits": "FAQ – Rénovations",
                "Upload EnerGuide Label": "Télécharger l'étiquette ÉnerGuide",
                "Welcome to Your Home Energy Report": "Bienvenue dans le rapport énergétique de votre maison",
                "You Are Not Signed In": "Vous n'êtes pas connecté",
                "Sign in to view your home energy report.": "Connectez-vous pour consulter le rapport énergétique de votre maison.",
                "Your Energy Rating and Retrofit Scenarios are based on:": "Vos scénarios de cote énergétique et de rénovation sont basés sur :",
                "Checked": "Vérifié",
                "Update your Home Information Survey": "Mettez à jour votre enquête sur les informations sur la maison",
                "Upload": "Télécharger",
                "Suggested Programs": "Programmes suggérés",
                "Choosing a Contractor": "Choisir un entrepreneur",
                "Choosing the right contractor will determine the success of your energy retrofit project.": "Choisir le bon entrepreneur déterminera le succès de votre projet de rénovation énergétique.",
                "Home Rating FAQ": "FAQ sur l’évaluation de la maison",
                "Rating": "Notation",
                "Uses Least Energy": "Moins d’énergie",
                "Uses Most Energy": "Plus d’énergie",
                "home-rating-units-eng": "<0></0> Les chiffres ci-dessus sont en GJ/an",
                "Retrofit Scenarios FAQ": "FAQ sur les scénarios de rénovation",
                "Select Retrofit Scenario": "Sélectionnez un scénario de rénovation",
                "Included Retrofits": "Modifications incluses",
                "Central Heat Pump": "Thermopompe centrale",
                "Drain Water Heat Recovery": "Récupération de chaleur des eaux usées",
                "Retrofit Scenario Details": "Détails du scénario de rénovation",
                "Checkmark": "Coche",
                "Report Selected": "Rapport sélectionné",
                "Unselected Map": "Carte non sélectionnée",
                "Selected Report": "Rapport sélectionné",
                "Claim My Home": "Réclamez ma maison",
                "Selected Notifications": "Notifications sélectionnées",
                "Selected Settings": "Paramètres sélectionnés",
                "Notifications": "Notifications",
                "Settings": "Paramètres",
                "PERSONALIZE MY RECOMMENDATIONS": "PERSONNALISER MES RECOMMANDATIONS",
                "Get Personalized Retrofit Scenarios": "Obtenez des scénarios de rénovation personnalisés",
                "Mini-Split Heat Pump": "Pompe à chaleur mini-split",
                "Air Sealing": "Étanchéité à l'air",
                "Unselected Notifications": "Notifications non sélectionnées",
                "Unselected Settings": "Paramètres non sélectionnés",
                "SWITCH HOME": "RETOURNER À LA MAISON",
                "More Homeowner Actions": "Plus d'actions des propriétaires",
                "Remove Property": "Supprimer la propriété",
                "Lowest": "Le plus bas",
                "Highest": "Le plus élevé",
                "home-rating-units-env": "<0></0> Les chiffres ci-dessus sont en tonnes de CO2e/an",
                "SHOW MORE": "MONTRE PLUS",
                "Financial Summary": "Résumé financier",
                "COST AFTER\nREBATES": "COÛT APRÈS\nREBASES",
                "SAVINGS AFTER\nREBATES": "ÉCONOMIES APRÈS\nREBASES",
                "SAVINGS OVER\n20 YEARS": "DES ÉCONOMIES SUR\n20 ANS",
                "COST OVER\n20 YEARS": "COÛT SUR\n20 ANS",
                "View Financial Breakdown": "Voir la répartition financière",
                "Projected Energy Bills": "Factures d’énergie projetées",
                "Energy Costs are rising. Here’s how these upgrades impact your energy bills.": "Les coûts de l’énergie augmentent. Voici l’impact de ces améliorations sur vos factures d’énergie.",
                "Do-Nothing Case": "Cas de ne rien faire",
                "Retrofit Scenario": "Scénario de rénovation",
                "Equipment End of Life": "Fin de vie des équipements",
                "Financial": "Financier",
                "Retrofits": "Modernisations",
                "Basic": "Basique",
                "Advanced": "Avancé",
                "Updated Emissions Rating": "Cote d’émissions mise à jour",
                "Savings": "Des économies",
                "Annual Emissions Change": "Changement des émissions annuelles",
                "Lifetime Emissions Impact": "Impact des émissions sur toute la durée de vie",
                "TONNES OF EMISSIONS SAVED (LIFETIME IMPACT)": "TONNES D'ÉMISSIONS ÉCONOMISÉES (IMPACT À VIE)",
                "Trees": "Des arbres",
                "like-planting-n-trees": "C'est comme planter {{nTrees}} arbres",
                "Updated Energy Rating": "Classe énergétique mise à jour",
                "Lifetime Energy Impact": "Impact énergétique à vie",
                "GJ OF ENERGY SAVED": "GJ D'ÉNERGIE ÉCONOMISÉE",
                "Car": "Voiture",
                "like-driving-n-times": "C'est la même énergie qu'il faudrait pour faire le tour de la Terre {{nDrives}} fois.",
                "Energy Advisor’s Comments": "Commentaires du conseiller en énergie",
                "Recommended Retrofits": "Modifications recommandées",
                "The following upgrades are included in this Retrofit Scenario:": "Les mises à niveau suivantes sont incluses dans ce scénario de mise à niveau :",
                "A reversible air conditioner; cools in summer, warms in winter. Generally 2-4x more energy-efficient than traditional systems.": "Un climatiseur réversible ; rafraîchit en été, réchauffe en hiver. Généralement 2 à 4 fois plus économe en énergie que les systèmes traditionnels.",
                "Mini-Split Heat Pump Modeled": "Modélisation d'une pompe à chaleur mini-split",
                "Type": "Taper",
                "Capacity": "Capacité",
                "48000 btu/hr": "48 000 BTU/h",
                "Show Energy Advisor’s Comments": "Afficher les commentaires du conseiller en énergie",
                "Windproofs your home, ensuring a consistent, controllable temperature, fewer drafts, and reduced energy costs. Air sealing a drafty home is one of the best measures to make you feel more comfortable.": "Coupe-vent votre maison, garantissant une température constante et contrôlable, moins de courants d'air et des coûts énergétiques réduits. L'étanchéité à l'air d'une maison aux courants d'air est l'une des meilleures mesures pour que vous vous sentiez plus à l'aise.",
                "Air Sealing Modeled": "Étanchéité à l'air modélisée",
                "Recycles heat from your drainwater, reducing the energy that your hot water heater uses. ": "Recycler la chaleur de votre eau de vidange, réduisant ainsi l’énergie utilisée par votre chauffe-eau. ",
                "Drain Water Heat Recovery Modeled": "Récupération de chaleur des eaux usées modélisée",
                "Hide Energy Advisor’s Comments": "Masquer les commentaires du conseiller en énergie",
                "Energy bills in": "Factures d'énergie en",
                "years: ": "ans: ",
                "Years": "Ans",
                "/year after retrofits": "/an après les rénovations",
                "Expected End of Life:": "Fin de vie prévue :",
                "Cost After Rebates": "Coût après remises",
                "Estimated Retrofit Costs": "Coûts de rénovation estimés",
                "10 Yr Savings": "Économies sur 10 ans",
                "Hide Financial Breakdown": "Masquer la répartition financière",
                "Homeowner Survey": "Enquête auprès des propriétaires",
                "Basic Info": "Informations de base",
                "Windows & Doors": "Fenêtres et portes",
                "": "",
                "What year was your house built?": "En quelle année votre maison a-t-elle été construite ?",
                "Do not count basement/ crawlspace.": "Ne comptez pas le sous-sol/le vide sanitaire.",
                "For split-level homes, include the lower level.": "Pour les maisons à plusieurs niveaux, incluez le niveau inférieur.",
                "1.5": "1,5",
                "2.5": "2,5",
                "3.5": "3,5",
                "How many floors above grade does your home have?": "Combien d'étages au-dessus du sol votre maison a-t-elle ?",
                "square feet (ft²)": "pieds carrés (pi²)",
                "square meters (m²)": "mètres carrés (m²)",
                "What is your preferred unit for floor areas?": "Quelle est votre unité préférée pour les surfaces au sol ?",
                "Area (ft²)": "Superficie (pi²)",
                "1500-2000 ft²": "1 500-2 000 pi²",
                "1000-1500 ft²": "1000-1500 pi²",
                "What is the area of your first floor?": "Quelle est la superficie de votre premier étage ?",
                "NEXT": "SUIVANT",
                "How this data will be used": "Comment ces données seront utilisées",
                "cdd-header-homeowner-survey-0": "<0>Mettez à jour les informations <1>facultatives</1> sur votre bâtiment pour améliorer la modélisation énergétique de votre maison.</0>",
                "type_of_house__duplex": "<0><0></0><br/>Duplex</0>",
                "foundtype__basement": "<0><0></0><br/>Sous-sol</0>",
                "foundtype__crawlspace": "<0><0></0><br/>Espace exploratoire</0>",
                "foundtype__slab": "<0><0></0><br/>Dalle au sol</0>",
                "foundtype__split": "<0><0></0><br/>Partagé niveau</0>",
                "foundtype__unknown": "<0><br/><br/><2> ?</2><br/><br/>Inconnu</0>",
                "If you don't see your system type, choose the most similar": "Si vous ne voyez pas votre type de système, choisissez le plus similaire",
                "1920 to 1940": "1920 à 1940",
                "Do you know when your primary heating system was last upgraded?": "Savez-vous quand votre système de chauffage principal a été modernisé pour la dernière fois ?",
                "cwp-sub-title-primary_heating_system_year": "Si vous n'êtes pas sûr, vous pouvez sélectionner parmi les plages d'années estimées suivantes ci-dessous",
                "Do you have a cooling system/ air conditioning?": "Avez-vous un système de refroidissement/climatisation ?",
                "Do you know when your cooling system was last upgraded?": "Savez-vous quand votre système de refroidissement a été mis à niveau pour la dernière fois ?",
                "cwp-sub-title-air_conditioner_year": "Si vous n'êtes pas sûr, vous pouvez sélectionner parmi les plages d'années estimées suivantes ci-dessous",
                "Do you know when your water heating system was last upgraded?": "Savez-vous quand votre système de chauffage à eau a été modernisé pour la dernière fois ?",
                "Rented": "Loué",
                "Owned": "Possédé",
                "I Don’t Know": "Je ne sais pas",
                "Is your water heated rented or owned?": "Votre eau chauffée est-elle louée ou possédée ?",
                "cwp-sub-title-water_heating_system_year": "Si vous n'êtes pas sûr, vous pouvez sélectionner parmi les plages d'années estimées suivantes ci-dessous",
                "No, they're original": "Non, ils sont originaux",
                "I think so": "Je pense que oui",
                "I don't know": "Je ne sais pas",
                "Were your windows ever upgraded?": "Vos fenêtres ont-elles déjà été rénovées ?",
                "Were your exterior doors ever upgraded?": "Vos portes extérieures ont-elles déjà été rénovées ?",
                "cwp-sub-title-windows": "Comptez les très grandes fenêtres ou de type solarium en fonction du nombre de fenêtres de taille moyenne qu'elles représentent",
                "cwp-sub-title-exterior_doors": "Inclure les portes coulissantes",
                "Do you know when your windows were upgraded?": "Savez-vous quand vos fenêtres ont été mises à niveau ?",
                "cwp-sub-title-windows_year": "Si vous n'êtes pas sûr, vous pouvez sélectionner parmi les plages d'années estimées suivantes ci-dessous",
                "Do you know when your exterior doors were upgraded?": "Savez-vous quand vos portes extérieures ont été modernisées ?",
                "cwp-sub-title-exterior_doors_year": "Si vous n'êtes pas sûr, vous pouvez sélectionner parmi les plages d'années estimées suivantes ci-dessous",
                "No, it is original": "Non, c'est original",
                "Was your ceiling/attic insulation ever upgraded?": "L’isolation de votre plafond/grenier a-t-elle déjà été améliorée ?",
                "Was your foundation insulation ever upgraded?": "L’isolation de vos fondations a-t-elle déjà été améliorée ?",
                "Was your wall insulation ever upgraded?": "L'isolation de vos murs a-t-elle déjà été améliorée ?",
                "Do you know when your ceiling/attic insulation was upgraded?": "Savez-vous quand l’isolation de votre plafond/grenier a été améliorée ?",
                "cwp-sub-title-ceiling_insulation_year": "Si vous n'êtes pas sûr, vous pouvez sélectionner parmi les plages d'années estimées suivantes ci-dessous",
                "Do you know when your foundation insulation was upgraded?": "Savez-vous quand l’isolation de vos fondations a été améliorée ?",
                "cwp-sub-title-foundation_insulation_year": "Si vous n'êtes pas sûr, vous pouvez sélectionner parmi les plages d'années estimées suivantes ci-dessous",
                "Do you know when your wall insulation was upgraded?": "Savez-vous quand l’isolation de vos murs a été améliorée ?",
                "cwp-sub-title-wall_insulation_year": "Si vous n'êtes pas sûr, vous pouvez sélectionner parmi les plages d'années estimées suivantes ci-dessous",
                "Does your home have an electric vehicle charger?": "Votre logement dispose-t-il d'un chargeur de véhicule électrique ?",
                "Does your home have a solar photovoltaic (PV) system?": "Votre maison est-elle équipée d'un système solaire photovoltaïque (PV) ?",
                "SUBMIT": "SOUMETTRE",
                "cwp-sub-title-has_solar_pv_system": "Les systèmes solaires photovoltaïques convertissent l'énergie solaire en électricité.",
                "Your Home Energy Report is Being Updated ...": "Votre rapport énergétique domestique est en cours de mise à jour ...",
                "BACK TO MY HOME": "RETOUR À MA MAISON",
                "Fill in your Home Information Survey": "Remplissez votre enquête d'information sur la maison",
                "Improve the Accuracy of Your Report": "Améliorez la précision de votre rapport",
                "Water Heater": "Chauffe-eau",
                "Ceiling/Attic Insulation": "Isolation du plafond/grenier",
                "HRV": "HRV",
                "Your Recommendation Settings": "Vos paramètres de recommandation",
                "Reducing my Energy Use": "Réduire ma consommation d'énergie",
                "cdd-header-homeowner-priorities": "<0>Mettez à jour cette section <1>facultative</1> pour personnaliser vos scénarios de mise à niveau.</0>",
                "Snooze Alert": "Alerte de répétition",
                "There are currently no outstanding notifications for this building.": "Il n'y a actuellement aucune notification en attente pour ce bâtiment.",
                "Delete my Profile": "Supprimer mon profil",
                "Can't Share More": "Je ne peux pas partager davantage",
                "Building Settings": "Paramètres du bâtiment",
                "You are in control of how much data you share publicly": "Vous contrôlez la quantité de données que vous partagez publiquement",
                "Profile Settings": "Paramètres de profil",
                "Delete My Account": "Supprimer mon compte",
                "Delete": "Supprimer",
                "cst-del-step-1": "Supprimer votre <1>Profil</1> supprime toutes les informations vous concernant (informations de connexion, priorités, etc.).<br/><br/><5>Êtes-vous sûr de vouloir supprimer votre profil ?</5> <br/><7><0></0> Cette opération est irréversible.</7>",
                "cst-del-step-1-conf-no": "<0><0>Non</0>, conserver mon profil.</0>",
                "cst-del-step-1-conf-yes": "<0><0>Oui</0>, supprimez mon profil.</0>",
                "Welcome to your Climative Home Energy Report! This is the start of your retrofit journey, helping you reduce your home’s emissions, make it more comfortable, and save money.": "Bienvenue dans votre rapport Climative sur l’énergie domestique ! C’est le début de votre parcours de rénovation, vous aidant à réduire les émissions de votre maison, à la rendre plus confortable et à économiser de l’argent.",
                "We used a combination of artificial intelligence (AI) and building science to estimate your home’s emissions, energy rating and the breakdown of major energy users in your home.": "Nous avons utilisé une combinaison d’intelligence artificielle (IA) et de science du bâtiment pour estimer les émissions de votre maison, sa cote énergétique et la répartition des principaux utilisateurs d’énergie dans votre maison.",
                "Learn how different retrofit scenarios will impact your home. Each retrofit scenario shows the estimated change to your home’s energy and emissions, as well as the financial impact.": "Découvrez l’impact des différents scénarios de rénovation sur votre maison. Chaque scénario de rénovation montre la modification estimée de la consommation d’énergie et des émissions de votre maison, ainsi que l’impact financier.",
                "Your home is first assessed using publicly-available data, so the ratings and recommendations start with what’s typical for a home like yours. Increase the accuracy of your Home Energy Report by providing more info through the survey.": "Votre maison est d'abord évaluée à l'aide de données accessibles au public. Les évaluations et les recommandations commencent donc par ce qui est typique d'une maison comme la vôtre. Augmentez la précision de votre rapport énergétique domestique en fournissant plus d'informations via l'enquête.",
                "Everyone has different priorities when making energy efficiency upgrades.\n\nWith the Personalize My Recommendations feature, you can get retrofit scenarios customized based on your priorities.": "Chacun a des priorités différentes lorsqu'il s'agit d'améliorer l'efficacité énergétique.\n\Grâce à la fonctionnalité Personnaliser mes recommandations, vous pouvez personnaliser les scénarios de rénovation en fonction de vos priorités.",
                "cdb-dont-show-again": "Ne montre plus ça",
                "hmp-intro-step-title-1": "Obtenez votre rapport énergétique domestique personnalisé gratuit",
                "hmp-intro-step-1-content-line-0": "Économisez de l'argent, rendez votre maison plus confortable et réduisez vos émissions grâce à votre rapport énergétique domestique personnalisé.",
                "hmp-intro-step-title-2": "Premièrement : revendiquez votre maison pour accéder à votre rapport énergétique domestique",
                "hmp-intro-step-2-content-line-0": "Entrez une adresse ou cliquez sur une maison sur la carte pour afficher son évaluation de la maison. Connectez-vous pour consulter votre rapport détaillé qui comprend des recommandations de mise à niveau.",
                "hmp-intro-step-no-3": "{{_step}} sur {{NUM_STEPS}}",
                "hmp-intro-step-title-3": "Ensuite : améliorez vos recommandations de rénovation avec le questionnaire sur le bâtiment",
                "hmp-intro-step-3-content-line-0": "Remplissez plus d'informations pour améliorer la façon dont votre rapport et vos recommandations représentent votre maison.",
                "GO CLAIM MY HOME": "SUIVANT",
                "hmp-intro-step-no-4": "{{_step}} sur {{NUM_STEPS}}",
                "hmp-intro-step-title-4": "Enfin : agissez !",
                "hmp-intro-step-4-content-line-0": "Une fois que vous avez compris vos recommandations de rénovation, il est temps de passer à l’action ! L'application vous dirigera vers des ressources telles que des aides financières et des professionnels de l'efficacité énergétique.",
                "furnace (forced air)": "fournaise (air pulsé)",
                "A typical": "Un typique",
                "has an expected life of around": "a une durée de vie prévue d'environ",
                "years.": "ans.",
                "Your": "Ton",
                "It is recommended you start looking into replacement equipment.": "Il est recommandé de commencer à chercher du matériel de remplacement.",
                "boiler": "chaudière",
                "wood stove": "poêle à bois",
                "electric baseboard": "plinthe électrique",
                "heat pump": "pompe à chaleur",
                "fireplace": "cheminée",
                "conventional/tank water heater": "chauffe-eau conventionnel/à réservoir",
                "tankless instantaneous water heater": "chauffe-eau instantané sans réservoir",
                "solar water heater": "chauffe-eau solaire",
                "heat pump water heater": "chauffe-eau à pompe à chaleur",
                "condensing water heater": "chauffe-eau à condensation",
                "combination boiler": "chaudière combinée",
                "central single package system": "système central à package unique",
                "central split system (ducted)": "système split central (canalisé)",
                "conventional a/c": "climatisation conventionnelle",
                "mini-split heat pump (ductless)": "thermopompe mini-split (sans conduit)",
                "window a/c": "climatiseur de fenêtre",
                "Your Retrofit Scenarios were updated.": "Vos scénarios de rénovation ont été mis à jour.",
                "Your Home Rating and Retrofit Scenarios were updated.": "L’évaluation de votre maison et vos scénarios de rénovation ont été mis à jour.",
                "Furnace (Forced Air) Replacement": "Remplacement de la fournaise (air pulsé)",
                "Boiler Replacement": "Remplacement de chaudière",
                "Boiler": "Chaudière",
                "Wood Stove Replacement": "Remplacement du poêle à bois",
                "Electric Baseboard Replacement": "Remplacement des plinthes électriques",
                "Heat Pump Replacement": "Remplacement de la pompe à chaleur",
                "Fireplace Replacement": "Remplacement de la cheminée",
                "Conventional/ Tank Water Heater Replacement": "Remplacement du chauffe-eau conventionnel/à réservoir",
                "Conventional/ Tank Water Heater": "Chauffe-eau conventionnel/à réservoir",
                "Tankless Instantaneous Water Heater Replacement": "Remplacement du chauffe-eau instantané sans réservoir",
                "Tankless Instantaneous Water Heater": "Chauffe-eau instantané sans réservoir",
                "Solar Water Heater Replacement": "Remplacement du chauffe-eau solaire",
                "Solar Water Heater": "Chauffe-eau solaire",
                "Heat Pump Water Heater Replacement": "Remplacement du chauffe-eau à pompe à chaleur",
                "Heat Pump Water Heater": "Chauffe-eau à pompe à chaleur",
                "Condensing Water Heater Replacement": "Remplacement du chauffe-eau à condensation",
                "Condensing Water Heater": "Chauffe-eau à condensation",
                "Combination Boiler Replacement": "Remplacement de la chaudière combinée",
                "Combination Boiler": "Chaudière combinée",
                "Central Single Package System Replacement": "Remplacement du système central à package unique",
                "Central Single Package System": "Système central à emballage unique",
                "Central Split System (Ducted) Replacement": "Remplacement du système divisé central (canalisé)",
                "Conventional A/C Replacement": "Remplacement du climatiseur conventionnel",
                "Mini-Split Heat Pump (Ductless) Replacement": "Remplacement de la thermopompe mini-split (sans conduits)",
                "Mini-Split Heat Pump (Ductless)": "Thermopompe mini-split (sans conduit)",
                "Window A/C Replacement": "Remplacement de la climatisation de fenêtre",
                "Home Energy Report Updated": "Rapport sur l'énergie domestique mis à jour",
                "Report Uploaded on": "Rapport téléchargé le",
                "Click Here to Upload EnerGuide Label": "Cliquez ici pour télécharger l'étiquette ÉnerGuide",
                "pdf format, Maximum of 2 MB": "format pdf, maximum 2 Mo",
                "cdb-sem-info": "Si vous avez fait effectuer une évaluation ÉnerGuide pour votre maison et que vous leur avez fourni votre adresse électronique, vous avez probablement reçu par courrier électronique une copie PDF de l'étiquette ÉnerGuide.",
                "Your EnerGuide Label": "Votre étiquette ÉnerGuide",
                "Download": "Télécharger",
                "Verified with EnerGuide label": "Vérifié avec l'étiquette ÉnerGuide",
                "Verified": "Vérifié",
                "View/Replace": "Afficher/Remplacer",
                "cdb-sem-info-update-2": "Si vous avez fait effectuer une évaluation ÉnerGuide plus récente, téléchargez votre étiquette ÉnerGuide pour mettre à jour votre maison.<br/><br/>Cela remplacera l'étiquette ÉnerGuide existante dans votre dossier.",
                "Next": "Suivant",
                "Next:": "Suivant:",
                "1952 to 1960": "1952 à 1960",
                "Next Steps: Suggested Programs": "Prochaines étapes : programmes suggérés",
                "See Suggested Programs": "Voir les programmes suggérés",
                "Recommendation Personalization Settings Change": "Modification des paramètres de personnalisation des recommandations",
                "Survey Submission": "Soumission d'une enquête",
                "Structure Data Automatically Updated": "Données de structure mises à jour automatiquement",
                "Modelling Level:": "Niveau de modélisation:",
                "Update Age": "Âge de mise à jour",
                "Go to survey to update": "Accédez à l'enquête pour mettre à jour",
                "age": "âge",
                "Triggered by:": "Déclenché par:",
                "Snooze": "Somnoler",
                "Today": "Aujourd'hui",
                "Unable to claim your home, the address is used by another building.": "Impossible de revendiquer votre logement, l'adresse est utilisée par un autre immeuble.",
                "Unable to claim your home, please try again later.": "Impossible de revendiquer votre maison, veuillez réessayer plus tard.",
                "Sucessfully claimed your home.": "Vous avez revendiqué votre maison avec succès.",
                "You are unable to claim this home, because it is currently associated with another user’s account.": "Vous ne pouvez pas revendiquer cette maison, car elle est actuellement associée au compte d'un autre utilisateur.",
                "You are unable to claim this home, because the address is used by another building.": "Vous ne pouvez pas revendiquer cette maison, car l'adresse est utilisée par un autre immeuble.",
                "Unable to claim your home, please try again later.": "Impossible de revendiquer votre maison, veuillez réessayer plus tard.",
                "Successfuly removed a property from your account.": "Une propriété a été supprimée avec succès de votre compte.",
                "Your email was verified": "Votre email a été vérifié",
                "Password has been updated": "Le mot de passe a été mis à jour",
                "Email with resetting instructions has been sent": "Un e-mail contenant les instructions de réinitialisation a été envoyé",
                "You've been registered successfully. Please check your email for verification link": "Vous avez été inscrit avec succès. Veuillez vérifier votre courrier électronique pour le lien de vérification",
                "Customer info has been Updated!": "Les informations client ont été mises à jour !",
                "Failed to update customer info.": "Échec de la mise à jour des informations client.",
                "EnerGuide Report uploaded.": "Rapport ÉnerGuide téléchargé.",
                "Error uploading EnerGuide Report": "Erreur lors du téléchargement du rapport ÉnerGuide",
                "EnerGuide Report deleted.": "Rapport ÉnerGuide supprimé.",
                "Error deleting EnerGuide Report": "Erreur lors de la suppression du rapport ÉnerGuide",
                "Report has been published!": "Le rapport a été publié !",
                "Failed to publish reporo.": "Échec de la publication du rapport.",
                "Building Updated": "Bâtiment mis à jour",
                "Building has been Updated!": "Le bâtiment a été mis à jour !",
                "Notes have been saved!": "Les notes ont été enregistrées !",
                "Priorities have been saved!": "Les priorités ont été enregistrées !",
                "Report has been published!": "Le rapport a été publié !",
                "Failed to publish report.": "Échec de la publication du rapport.",
                "Report has been Generated!": "Le rapport a été généré !",
                "Report has been Generated!": "Le rapport a été généré !",
                "Test complete.": "Test terminé.",
                "Building has been Updated!": "Le bâtiment a été mis à jour !",
                "Changes have been saved!": "Les modifications ont été enregistrées!",
                "Report has been Updated!": "Le rapport a été mis à jour !",
                "Sucessfully updated your priorities.": "Mise à jour réussie de vos priorités.",
                "Loading": "Chargement",
                "No Home Selected": "Aucune maison sélectionnée",
                "Add your home to view your home energy report.": "Ajoutez votre maison pour consulter votre rapport énergétique domestique.",
                "Find My Home": "Trouver ma maison",
                "This may take a minute, please wait...": "Cela peut prendre une minute, veuillez patienter...",
                "new-search-info-bubble": "Pour commencer, entrez votre adresse domiciliaire ou naviguez sur la carte pour trouver et réclamer votre domicile",
                "Step 1": "Étape 1",
                "simptek-copyright": "Alimenté par <1>Climative</1>",
                "Dashboard Unselected": "Tableau de bord non sélectionné",
                "Unselected Dashboard": "Tableau de bord non sélectionné",
                "Dashboard Selected": "Tableau de bord sélectionné",
                "Selected Dashboard": "Tableau de bord sélectionné",
                "Video Home Energy Assessment Sign Up ": "Inscription à l'évaluation énergétique de la maison par vidéo",
                "Dashboard Frequently Asked Questions": "Questions fréquemment posées sur le tableau de bord",
                "Dashboard FAQ": "FAQ du tableau de bord",
                "Set Up Profile": "Configurer le profil",
                "Home Energy Assessment - Plan Retrofits": "Évaluation énergétique de la maison - Planifier les rénovations",
                "Cost: FREE": "Coût : GRATUIT",
                "Sign Up Here": "Inscrivez-vous ici",
                "Perform Retrofits": "Effectuer les rénovations",
                "Home Energy Assessment - Validate Retrofits": "Évaluation énergétique de la maison - Valider les rénovations",
                "Receive Financial Incentives": "Recevoir des incitatifs financiers",
                "cdb-step1-1-title-ae": "Réclamer ma maison",
                "cdb-step2-1-title-ae": "Inscription pour l'évaluation énergétique de la maison par vidéo",
                "cdb-substep-2-1-unchecked": "<0>Coût : GRATUIT</0><1>Inscrivez-vous ici</1>",
                "cdb-step2-2-title-ae": "Prendre rendez-vous avec un conseiller en énergie",
                "cdb-substep-2-2-unchecked-ae": "Votre conseiller en énergie vous contactera pour fixer votre rendez-vous.",
                "cdb-step2-3-title-ae": "Évaluation énergétique de la maison par vidéo",
                "cdb-substep-2-3-unchecked-ae": "Un conseiller en énergie agréé modélisera votre maison lors d'un appel vidéo",
                "cdb-step2-4-title-ae": "Obtenez le rapport énergétique publié",
                "cdb-substep-2-4-unchecked-ae": "Une fois que votre conseiller en énergie publie votre rapport, vous pourrez le consulter ici!",
                "Wall Insulation": "Isolation des murs",
                "Close Modal": "Fermer la fenêtre modale",
                "None selected": "Aucun sélectionné",
                "Choose your service provider": "Choisissez votre fournisseur de services",
                "For more information, go to:": "Pour plus d'informations, consultez :",
                "Confirm": "Confirmer",
                "Service Provider:": "Fournisseur de services :",
                "Successfully submitted request.": "Demande soumise avec succès.",
                "Please select a service organization": "Veuillez sélectionner une organisation de services.",
                "The Retrofits Have Started": "Les rénovations ont commencé",
                "cdb-step3-0-title-ae": "Commencer les rénovations",
                "Upgrade Package": "Forfait de mise à niveau",
                "Remote Auditor Upgrade Package.": "Forfait de mise à niveau pour auditeur à distance.",
                "Energy Advice Report": "Rapport de conseils énergétiques",
                "Your Energy Advice Report": "Votre rapport de conseils énergétiques",
                "The Retrofits Are Complete": "Les rénovations sont terminées",
                "cdb-step3-1-title-ae": "Finalisation des rénovations",
                "Retrofit Validation Report": "Rapport de validation des rénovations",
                "Your Retrofit Validation Report": "Votre rapport de validation des rénovations",
                "cdb-step4-1-title-ae": "Inscription pour l'évaluation énergétique de la maison par vidéo",
                "cdb-step4-2-title-ae": "Prendre rendez-vous avec un conseiller en énergie",
                "cdb-substep-4-2-unchecked-ae": "Votre conseiller en énergie vous contactera pour fixer votre rendez-vous.",
                "cdb-step4-3-title-ae": "Évaluation énergétique de la maison par vidéo",
                "cdb-substep-4-3-unchecked-ae": "Un conseiller en énergie agréé modélisera votre maison lors d'un appel vidéo",
                "cdb-step4-4-title-ae": "Obtenez le rapport énergétique publié",
                "cdb-substep-4-4-unchecked-ae": "Une fois que votre conseiller en énergie publie votre rapport, vous pourrez le consulter ici!",
                "Financial Incentives Received": "Incitatifs financiers reçus",
                "cdb-step5-0-title-ae": "Recevoir des incitatifs financiers",
                "/year in do-nothing case": "/an dans le cas où aucune action n'est entreprise",
                "btu/hr": "btu/h",
                "Heating Efficiency Rating": "Évaluation de l'efficacité du chauffage",
                "Financial Impact Projection": "Projection de l'impact financier",
                "This graph shows the complete financial impact over years. This includes all financial aspects considered in your retrofit scenario.": "Ce graphique montre l'impact financier complet sur plusieurs années. Il inclut tous les aspects financiers considérés dans votre scénario de rénovation.",
                "Individual Retrofit Impact": "Impact individuel des rénovations",
                "Select Metric": "Sélectionner une métrique",
                "crp-rebeate-warning": "<0>Les rabais réels peuvent différer des estimations montrées.</0> Les rabais estimés ne tiennent pas compte des détails spécifiques de votre maison ou de votre équipement qui peuvent affecter les montants réels des rabais.",
                "year financial impact:": "impact financier de l'année :",
                "in savings": "en économies",
                "Get Your Upgrades Validated": "Faites valider vos améliorations",
                "SHOW LESS": "MONTRER MOINS",
                "Pompe à chaleur mini-split": "Pompe à chaleur mini-split",
                "Save As My Home": "Enregistrer comme ma maison",
                "hmp-cbh-msg-top": "Nous n'avons pas d'information pour<br/>ce bâtiment pour le moment.",
                "hmp-cbh-msg-bottom": "Vous pouvez mettre à jour cette maison et obtenir un rapport énergétique avec un court questionnaire.<br/><br/>Note : Si ce bâtiment sur la carte est associé à une adresse incorrecte, vous pouvez corriger cela dans le questionnaire.",
                "Update this short questionnaire to get a Home Energy Report": "Mettez à jour ce court questionnaire pour obtenir un rapport énergétique de la maison",
                "Confirm address": "Confirmer l'adresse",
                "Submit Survey": "Soumettre le questionnaire",
                "Confirm this is your Home": "Confirmer que c'est votre maison",
                "Yes, this is my home": "Oui, c'est ma maison",
                "hmp-confirm-claim-title": "Confirmer la réclamation de cette maison ?",
                "Step 2": "Étape 2", 
                "Claim Your Home": "Revendiquer Votre Maison",
                "hmp-cta-title-e1": "Votre Parcours d'Économies de Carbone et d'Argent Commence Ici",
                "hmp-cta-button-title-e1": "Revendiquer Ma Maison",
                "hmp-intro-step-title-1-e1": "Bienvenue dans votre Navigateur Énergétique Personnel!",
                "hmp-intro-step-1-content-line-0-e1": "Cette application vous aidera à réserver une Évaluation Énergétique Vidéo à Domicile, à voir les résultats de manière facile à comprendre, et bien plus encore!",
                "hmp-intro-step-title-2-e1": "Première étape : Créez un compte pour vous inscrire à votre Évaluation Énergétique Vidéo à Domicile",
                "hmp-intro-step-2-content-line-0-e1": "Une fois que vous aurez créé un compte et réclamé votre domicile, vous pourrez réserver une Évaluation Énergétique Vidéo à Domicile.",
                "hmp-intro-step-title-3-e1": "Ensuite : Évaluation Énergétique Vidéo à Domicile avec un Conseiller Énergétique",
                "hmp-intro-step-3-content-line-0-e1": "Ensuite, vous passerez votre évaluation énergétique vidéo à domicile, un appel vidéo avec un conseiller énergétique qui vous proposera un plan de rénovation énergétique pour votre maison.",
                "hmp-intro-step-title-4-e1": "Ensuite : Obtenez votre Rapport et Passez à l'Action!",
                "hmp-intro-step-4-content-line-0-e1": "Après que votre conseiller énergétique ait publié les résultats de votre évaluation énergétique, vous pourrez les consulter ici. Après avoir effectué vos rénovations, vous pourrez revenir et suivre le même processus pour l'évaluation énergétique vidéo post-rénovation!",
                "20 yrs": "20 ans",
                "Reduced": "Réduit",
                "EMISSIONS": "ÉMISSIONS",
                "ENERGY USE": "ÉNERGIE",
                "Retrofit Package Savings": "Économies du forfait de rénovation",
                "Foundation Insulation": "Isolation des fondations",
                "Annual Energy Bills ($)": "Factures énergétiques annuelles ($)",
                "Range: 1 to 20 Years": "Plage : 1 à 20 ans",
                "Financial Impact ($)": "Impact financier ($)",
                "Range: 1 to 25 Years": "Plage : 1 à 25 ans",
                "Tonnes": "Tonnes",
                "Acts like a snug blanket around your home, locking in warmth and cutting energy expenses.": "Agit comme une couverture douillette autour de votre maison, emprisonnant la chaleur et réduisant les dépenses énergétiques.",
                "Starting Insulation Level": "Niveau d'isolation initial",
                "Insulation Added": "Isolation ajoutée",
                "New Insulation Level": "Nouveau niveau d'isolation",
                "Wall Insulation Modeled": "Isolation des murs modélisée",
                "More modern windows have better weather seals and insulating glass panels, helping to lower heating and cooling costs.": "Les fenêtres plus modernes ont de meilleurs joints d'étanchéité et des panneaux de verre isolants, ce qui aide à réduire les coûts de chauffage et de climatisation.",
                "# of Windows Replaced": "Nombre de fenêtres remplacées",
                "# of Doors Replaced": "Nombre de portes remplacées",
                "Windows & Doors Modeled": "Fenêtres et portes modélisées",
                "Like a warm hat for your home; keeps it warmer in winter and cooler in summer, reducing energy bills.": "Comme un chapeau chaud pour votre maison ; elle la garde plus chaude en hiver et plus fraîche en été, réduisant les factures d'énergie.",
                "Ceiling Insulation Modeled": "Isolation du plafond modélisée",
                "Like winter boots for your home; leads to warmer floors, fewer drafts, and lower heating costs.": "Comme des bottes d'hiver pour votre maison ; elles mènent à des sols plus chauds, moins de courants d'air et des coûts de chauffage réduits.",
                "Foundation Insulation Modeled": "Isolation des fondations modélisée",
                "Initial financial impact:": "Impact financier initial :",
                "in costs": "en coûts",
                "Projected Savings": "Économies projetées",
                "Break-even point: ": "Point d'équilibre : ",
                "years": "années",
                "Fenêtres et portes": "Fenêtres et portes",
                "years or less": "ans ou moins",
                "Close": "Fermer",
                "cdd-modal-homeowner-priorities-point-1": "Les données que vous entrez sur cet écran peuvent être anonymisées et partagées au niveau régional<br/><br/><i>Par exemple, votre gouvernement local pourrait voir que 73 % de ses résidents ont indiqué que l'économie d'argent est le facteur le plus important dans la prise de décisions sur l'efficacité énergétique.</i>",
                "cdd-modal-homeowner-priorities-point-2": "Les données individuelles que vous entrez sur cet écran NE seront PAS accessibles par d'autres utilisateurs. Elles ne seront accessibles QUE par l'utilisateur qui a saisi les données (vous).",
                "cdd-modal-homeowner-survey-0-point-1": "La cote de votre maison et les scénarios de rénovation seront mis à jour en fonction des données fournies sur cet écran.",
                "cdd-modal-homeowner-survey-0-point-2": "Les données sur votre bâtiment que vous entrez, ainsi que toutes les données dérivées, peuvent être partagées avec de futurs propriétaires et avec des organisations visant à réduire les émissions de carbone dans votre région (comme votre gouvernement régional).",
                "cdd-modal-homeowner-survey-0-point-3": "Il n'y a pas de données personnelles dans ce sondage.",
                "Areas": "Surfaces",
                "1970 to 1980": "1970 à 1980",
                "Exterior Doors": "Portes extérieures",
                "Known Building Information": "Informations sur le bâtiment connues",
                "This data was provided by a high-confidence data provider and is not editable": "Ces données ont été fournies par un fournisseur de données à haute confiance et ne sont pas modifiables",
                "Type Of House": "Type de maison",
                "Single Detached": "Maison individuelle",
                "Double/Semi-Detached": "Maison jumelée",
                "Row House, Middle Unit": "Maison en rangée, unité du milieu",
                "Row House, End Unit": "Maison en rangée, unité de coin",
                "Duplex": "Duplex",
                "Mobile Home": "Maison mobile",
                "Year Of Construction": "Année de construction",
                "Floors Above Grade": "Étages au-dessus du sol",
                "Total Heated Floor Area": "Surface totale chauffée",
                "Building Footprint": "Emprise au sol du bâtiment",
                "Heated Basement": "Sous-sol chauffé",
                "No": "Non",
                "Yes": "Oui",
                "Read more": "En savoir plus",
                "Preferred Contact Phone Number": "Numéro de téléphone de contact préféré",
                "Enter your contact number": "Entrez votre numéro de contact",
                "preferred-phone-desc": "Nous partagerons les informations suivantes avec votre conseiller en énergie afin qu'il puisse vous contacter pour planifier votre audit à distance.<br/><br/>L'adresse courriel de votre compte et votre numéro de téléphone de contact préféré.",
                "I agree to the terms and conditions stated above": "J'accepte les termes et conditions énoncés ci-dessus",
                "Save and Continue": "Sauvegarder et continuer",
                "Preferred Language": "Langue préférée",
                "Select Language": "Sélectionner la langue",
                "Please enter your preferred phone number and language": "Veuillez entrer votre numéro de téléphone et votre langue préférés",
                "Energy Bill Savings (1st Year)": "Économies sur la facture d'énergie (1ère année)",
                "Energy Bill Costs (1st Year)": "Coûts de la facture d'énergie (1ère année)",
                "hmp-cbh-addr-msg-top": "Nous n'avons pas pu associer automatiquement l'adresse à un bâtiment sur la carte. Essayez de cliquer sur la carte pour trouver une correspondance pour votre adresse.",
                "hmp-cbh-addr-msg-bottom": "Vous pouvez cliquer n'importe où sur la carte (qu'il y ait un bâtiment ou non). La plupart du temps, cela résout le problème.<br/>"
            }
        }
    },
    saveMissing: true,
    saveMissingPlurals: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
        window.MISSING_KEYS[key] = fallbackValue || key;
    }
}, (err, t) => {
    
});

let orgUrlPrefix = `${window.location.pathname}`.replace(/\/*/g, '');

// Lock production navigator to E1 experience
if (window.location.href.indexOf('navigator.climative.ai') >= 0) {
    if (orgUrlPrefix !== 'nbpower' && orgUrlPrefix !== 'efficiencyns' && window.location.href.indexOf("id_token=") === -1 && window.location.href.indexOf("&error_description") === -1) {
        window.location.href = '/efficiencyns/';
    }
}

document.body.style.background = 'white';

//Hiding intercom should be done within intercom settings now
//window.HIDE_INTERCOM = window.location.href.includes("navigator.uat.climative.ai") || window.location.href.includes("efficiencyns") || window.location.href.includes("ottawa");
window.HIDE_INTERCOM = false;

if (!window.HIDE_INTERCOM) {
    window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "puelupwp"
    });
}

window.ORG_NAME = orgUrlPrefix;
axios.get('/user/get-org?org_url_prefix=' + orgUrlPrefix).then((data) => {

    window.ORG_INFO = data.data;
    
    const finish = (data) => {
        
        window.ORG_INFO.user_loc = data?.data;

        ReactDOM.render(
            <Provider store={store}>
                <App/>
            </Provider>,
            document.getElementById('root')
        );

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: http://bit.ly/CRA-PWA
        serviceWorker.unregister();
    };    

    axios.get('http://ip-api.com/json/').then(finish).catch(finish);

});