import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTranslation } from "react-i18next";

import './CRatingBubble.scss';

const { RandomKey } = require('../../../helpers/utils');

class CRatingBubble extends React.Component {
  constructor(props) {
    super(props);
    this.key = RandomKey();

    this.T = props.t;
    this.state = {
        offsetX: 0
    };

    this.canvasRef = React.createRef();

    this.resizeCbk = () => {
        this.setState({ rnd: Math.random() });
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeCbk);
    this.intervalId = window.setInterval(() => {
        if (!this.canvasRef.current) {
            return;
        }
        let visible = window.getComputedStyle(this.canvasRef.current).display !== 'none';
        if (visible !== this.lastVisible) {
            this.setState({ rnd: Math.random() });
        }
        this.lastVisible = visible;
    }, 250);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeCbk);
    window.clearInterval(this.intervalId);
  }

  componentDidUpdate() {
    if (this.canvasRef.current && this.canvas !== this.canvasRef.current) {
        this.canvas = this.canvasRef.current;
        this.ctx = this.canvas.getContext("2d");
    }
    this.renderBubble();
  }

  renderBubble() {

    const width = this.props.width;
    const height = this.props.height;
    let compNotchLeft = null;

    if (this.props.meterImgId && this.props.meterContainerId) {
        const meterImg = document.getElementById(this.props.meterImgId);
        const meterContainer = document.getElementById(this.props.meterContainerId);
        if (!meterImg || !meterContainer) {
            //setTimeout(() => this.renderBubble, 50);
            return;
        }
        const meterValue = parseFloat(this.props.meterValue) || 0.;
        const meterWidth = meterImg.offsetWidth;
        const meterX1 = meterImg.offsetLeft;
        const containerWidth = meterContainer.offsetWidth;
        let offsetX = meterX1 + Math.floor(meterValue / 100 * meterWidth);
        if (this.props.style === 'below' || this.props.style === 'above') {
            compNotchLeft = width / 2;
            offsetX -= Math.floor(width/2);
        }
        else if (this.props.style === 'above-left') {
            compNotchLeft = width;
            offsetX -= width;
        }
        else if (this.props.style === 'above-right') {
            compNotchLeft = 0;
            offsetX -= 0;
        }

        if (offsetX < -16) {
            const offset2 = (-16 - offsetX);
            offsetX += offset2;
            compNotchLeft -= offset2;
        }

        if (offsetX > (containerWidth - 2 - width)) {
            const offset2 = offsetX - (containerWidth - 2 - width);
            offsetX -= offset2;
            compNotchLeft += offset2;
        }

        if (offsetX !== this.state.offsetX) {
            this.setState({ offsetX });
        }
    }

    const ctx = this.ctx;
    ctx.fillStyle = this.props.backgroundColor ?? '#FFFFFF';
    ctx.clearRect(0, 0, width, height);
    switch (this.props.style ?? 'below') {
    case 'below': {
        const notchLeft = compNotchLeft ?? (width/2);
        ctx.beginPath();
        ctx.roundRect(0, 10, width, height-10, this.props.borderRadius ?? 3);
        ctx.fill();
        ctx.beginPath();
        ctx.moveTo(notchLeft-9, 12);
        ctx.lineTo(notchLeft, 0);
        ctx.lineTo(notchLeft+9, 12);
        ctx.closePath();
        ctx.fill();
        break;
    } case 'above': {
        const notchLeft = compNotchLeft ?? (width/2);
        ctx.beginPath();
        ctx.roundRect(0, 0, width, height-10, this.props.borderRadius ?? 3);
        ctx.fill();
        ctx.beginPath();
        ctx.moveTo(notchLeft-9, height-12);
        ctx.lineTo(notchLeft+9, height-12);
        ctx.lineTo(notchLeft, height-1);
        ctx.closePath();
        ctx.fill();
        break;
    } case 'above-left': {
        const notchLeft = compNotchLeft ?? width;
        ctx.beginPath();
        ctx.roundRect(0, 0, width, height-10, this.props.borderRadius ?? 3);
        ctx.fill();
        ctx.beginPath();
        ctx.moveTo(notchLeft, height-12);
        ctx.lineTo(notchLeft, height-1);
        ctx.lineTo(notchLeft-9, height-12);
        ctx.closePath();
        ctx.fill();
        break;
    } case 'above-right': {
        const notchLeft = compNotchLeft ?? 0;
        ctx.beginPath();
        ctx.roundRect(0, 0, width, height-10, this.props.borderRadius ?? 3);
        ctx.fill();
        ctx.beginPath();
        ctx.moveTo(notchLeft, height-12);
        ctx.lineTo(notchLeft+9, height-12);
        ctx.lineTo(notchLeft, height-1);
        ctx.closePath();
        ctx.fill();
        break;
    }
    default:
        break;
    }
  }

  render() {
    return (
        <div className={'c-rating-bubble-cont'} key={this.key} style={{width: this.props.width, height: this.props.height, left: this.props.left + this.state.offsetX, top: this.props.top, zIndex: this.props.zIndex}}>
            <canvas className='c-rating-canvas' width={this.props.width} height={this.props.height} ref={this.canvasRef} />
            <div className={'c-rating-inner ' + (this.props.textClass ?? "")}>{this.props.children}</div>
        </div>
    );
  }
}

function mapStateToProps(state) {
	return {
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CRatingBubble)));
