import { combineReducers } from 'redux';
import auth from './auth';
import alerts from './alerts';
import buildings from './buildings';
import { connectRouter } from 'connected-react-router';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    alerts,
    auth,
    buildings
});
