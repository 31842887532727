import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Themes from "./Themes";
import "./Themes.scss";

import { withTranslation } from "react-i18next";

import CPriorities from "./components/CPriorities";

import "./CustomerPriorities.scss";

/////

class CustomerPrioritiesPage extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
		};

		this.theme = Themes.autodetect();
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

	componentDidUpdate(prevProps) {
		if (!prevProps.show && this.props.show) {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			const el = document.getElementById('ph-header-cont');
			if (el) {
				el.scrollTop = 0;
			}
		}
	}

	render() {

		if (this.props.show) {
			if (!this.props.currentUser) {
				window.location.href = "#/app/customer/view-report";
				return (<div/>)
			}
	
			document.title = this.T('Your Recommendation Settings') + ' - ' + this.T('Climative Low Carbon Communities');
		}
		else {
			return (<div/>);
		}

		return (
			<div className={"cprio-page-cont"}>
				<CPriorities show={this.props.show} />
				<div className="sr-only" role="alert" aria-live="polite" aria-atomic="true" aria-relevant="additions" id="live-region-a11y"></div>
			</div>
		);

	}
}

////

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
	return {
		base64_report: state.buildings.loaded_b64_report && state.buildings.loaded_b64_report.base64_report,
		building,
		currentUser: state.auth.currentUser,
		lastPublished: state.buildings.curModel.lastPublished,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomerPrioritiesPage)));
