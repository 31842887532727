import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CDropdown from './CDropdown';
import CStatBlock from './CStatBlock';
import i18next from 'i18next';
import { withTranslation, Trans } from "react-i18next";
import Themes from "../Themes";
import "../Themes.scss";

import './CRetrofitScenariosCard.scss';
import '../CustomerReport.scss';
import CFaqButton from './CFaqButton';

const AuditStatus = require('../../../helpers/audit-status');

const { GetRecIcon, GetRecName } = require('../../../helpers/utils');
const { RandomKey } = require('../../../helpers/utils');

let STATUSES, statusEqual, statusGt, statusGtEqual, THEME, ASYNC_STATUSES;

class CRetrofitScenariosCard extends React.Component {
  constructor(props) {
    super(props);

    this.T = props.t;

    this.key = RandomKey();
    this.state = {};

    THEME = this.theme = Themes.autodetect();
    const statObj  = AuditStatus(this.theme);
    STATUSES       = statObj.STATUSES;
    statusEqual    = statObj.statusEqual;
    statusGt       = statObj.statusGt;
    statusGtEqual  = statObj.statusGtEqual;
    ASYNC_STATUSES = statObj.ASYNC_STATUSES;

    this.updateDDOptions();
  }

  componentDidMount() {
    
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps) {
    this.updateDDOptions();
  }

  updateDDOptions() {

    this.packageDropdownOptions = [
        { key: 1, title: this.T('Good Upgrade Package'), desc: this.T("Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.") },
        { key: 2, title: this.T('Better Upgrade Package'), desc: this.T("Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.") },
        { key: 3, title: this.T('Best Upgrade Package'), desc: this.T("Better Homes Ottawa offers professional advice, qualified contractors and money-saving incentives.") }
    ];

    if (this.props.type2) {
        this.packageDropdownOptions = [
            { key: 4, title: this.T('Upgrade Package'), desc: this.T("Remote Auditor Upgrade Package.") }
        ];
    }

    let rinfo = this.props.rInfo;
    if (rinfo && !this.props.type2) {
        for (let options of this.packageDropdownOptions) {
            let keys = [ '', 'Good_Package', 'Better_Package', 'Best_Package', 'energy_advisor_bundle' ];
            let rinfoPackage = rinfo.bundle_recommendations[keys[options.key]];
            options.subTitle = `${this.T('Savings')}: ${rinfoPackage?.rec_totals?.savings_20_year_text ?? '$0'} (${this.T('20 yrs')}) | ${this.T('Emissions')}: ${rinfoPackage?.rec_totals?.percentSave < 0 ? '↑' : '↓'} ${Math.abs(rinfoPackage?.rec_totals?.percentSave||0)}%`;// | Energy: ${(rinfoPackage?.rec_totals?.bundle_gj_percentSave||0)}%`;
        }
    }
    
  }

  render() {
    let rInfoPackage = this.props.rInfoPackage;
    let recs = rInfoPackage ? rInfoPackage.recommendations : [];

    for (let i=0; i<recs.length; i++) {
        if (recs[i].is_foundation && this.props.hideFoundationUpgrade) {
            recs.splice(i, 1);
            break;
        }
    }

    return (
        <div key={this.key} className="crp-upgrade-packages-cont">
            <div className="crp-upgrade-packages">
                <div className="crp-up-title">
                    <span role='heading' aria-level={2}>{this.props.type2 ? this.T('Retrofit Scenario') : this.T('Retrofit Scenarios')}</span>
                    <CFaqButton extraClass="crp-faq-button" onClick={this.props.modalOpenClick("faq-upgrades")} id="faq-upgrades-trigger" ariaLabel={this.T('Retrofit Scenarios FAQ')}/>
                </div>
                {!this.props.type2 ? <div className="crp-up-package-tabs">
                    <div className="crp-select-package-label">{this.T("Select Package")}</div>
                    <CDropdown aria-label={this.T('Select Package')} options={this.packageDropdownOptions} error={null} defaultValue={this.packageDropdownOptions.filter((e) => this.props.upgradePackageSelected == e.key)[0]} emptyText={this.T("Select Retrofit Scenario")} onChange={(val) => {
                        this.props.updateParentState({ upgradePackageSelected: val.key });
                    }} />
                </div> : undefined}
                <div className="crp-cstat-line-1">
                    <CStatBlock className="cstat-20-year-savings" stat1={(rInfoPackage?.rec_totals?.savings_20_year_text ?? '$0')} stat2={this.T(`SAVINGS OVER\n20 YEARS`)} stat2Negative={this.T(`COST OVER\n20 YEARS`)} />
                </div>
                <div className="crp-cstat-line-2">
                    <CStatBlock className="cstat-emissions" stat1IconAlt={this.T("Reduced")} stat1Icon="/images/emissions-down.svg" stat1={(rInfoPackage?.rec_totals?.percentSave||0) + "%"} stat2={this.T("EMISSIONS")} />
                    <CStatBlock className="cstat-energy-use" stat1IconAlt={this.T("Reduced")} stat1Icon="/images/energy-use-down.svg" stat1={(rInfoPackage?.rec_totals?.bundle_gj_percentSave||0) + "%"} stat2={this.T("ENERGY USE")} />
                </div>
                <h2 className="crp-incl-upgrades-title">{this.T('Included Retrofits')}</h2>
                <div className="crp-incl-upgrades-cont">
                    {recs.map((rec) => (<div className="crp-incl-rec">
                        <img alt={""} src={'/images/' + GetRecIcon(rec.recommendations_name, this.T) + '.svg'} />
                        {GetRecName(rec.recommendations_name, this.T, this.props.modelType2)}
                    </div>))}
                </div>
                {!this.props.noButtons ? [(<button id={'package-modal-trigger'} className="crp-package-details-btn" onClick={() => {
                    this.props.updateParentState({ mpTabSel: 1, mpBaSel: 1, barFilterMetricFinancial: 'bill-savings-first-year', vcbShow: false });
                    this.props.modalOpenClick("package-modal")();
                }}>{this.T("Retrofit Scenario Details")}</button>),(<br/>),
                (!this.props.type2 ? <a href="#/app/customer/priorities" className="crp-priorities-link">{this.T("Personalize my Recommendations")} <img alt={this.T("Checkmark")} src='/images/cdb-step-checkbox-button.svg' /></a> : undefined)] : undefined}
            </div>
        </div>
    );
  }
}

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
    let status = { main: building.highestAuditStatus || 1, sub: building.highestAuditSubStatus || 1 };
    let model = state.buildings.curModel || {};
    let model2 = model.LPUB;

    // Have to have this block here because this function is sometimes called before the constructor
    THEME = Themes.autodetect();
    const statObj  = AuditStatus(THEME);
    STATUSES       = statObj.STATUSES;
    statusEqual    = statObj.statusEqual;
    statusGt       = statObj.statusGt;
    statusGtEqual  = statObj.statusGtEqual;
    ASYNC_STATUSES = statObj.ASYNC_STATUSES;

	return {
		building,
		currentUser: state.auth.currentUser,
        modelType2: THEME?.advisorExperience ? (status.main >= STATUSES.POST_AWAITING_SIGNUP.main || statusEqual(STATUSES.PRE_PREVIEW_PUBLISHED, status) ? model2 : null) : undefined
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CRetrofitScenariosCard)));