import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './CDropdown.scss';

const { RandomKey } = require('../../../helpers/utils');

class CDropdown extends React.Component {
	constructor(props) {
		super(props);
		this.key = RandomKey();

		this.state = {
			filterOpened: false,
			filterSelected: props.defaultValue || null
		}
	}

	closeBubble(e) {
		if (!this.state.filterOpened) {
			return;
		}

		if (!e) {
			return false;
		}

		if (e?.target?.className?.indexOf && (e.target.className.indexOf('cust-dd-option') >= 0 || e.target.parentNode?.className?.indexOf('cust-dd-option') >= 0)) {
			//return;
		}

		this.setState({ filterOpened: false });

		return false;
	}

	selectBubbleOption(e, val) {
		if (this.props.onNonEmpty && val) {
			this.props.onNonEmpty();
		}
		if (this.props.onChange) {
			this.props.onChange(val);
		}
		if (!this.props.selChangeEventOnly) {
			this.setState({ filterSelected: val, rand: Math.random() });
		}
		return this.closeBubble(e);
	}

	openBubble(e) {

		if (!e) {
			return false;
		}

		this.setState({ filterOpened: true });

		e.preventDefault();
		e.stopPropagation();
		return false;
	}

	componentDidMount() {
		if (this.onClick) {
			document.body.removeEventListener('click', this.onClick);
		}
		this.onClick = (e) => {
			if (e.target && !e.target.closest('.cust-dropdown-button')) {
				return this.closeBubble(e || window.event);
			}
		};
		document.body.addEventListener('click', this.onClick);
	}

	componentWillUnmount() {
		if (this.onClick) {
			document.body.removeEventListener('click', this.onClick);
			this.onClick = null;
		}
	}

	componentDidUpdate() {
		let key = JSON.stringify(this.props);
		if (key != this.lastKey) {
			this.lastKey = key;
			let opt = this.state.filterSelected ? this.props.options.filter((e) => e.key === this.state.filterSelected.key)[0] : null;
			if (typeof this.state.filterSelected !== 'object' || this.state.filterSelected === null) {
				opt = this.state.filterSelected ? this.props.options.filter((e) => e === this.state.filterSelected)[0] : null;
			}
			this.setState({ filterSelected: opt, rand: Math.random() });
		}
	}

	render() {
		return (
			<span className='relative'>
				<button
					aria-label={this.props['aria-label']}
					aria-expanded={this.state.filterOpened ? 'true' : 'false'}
					className={
						(this.props.style ? 'cdb-style-' + this.props.style : '') +
						' cust-dropdown-button ' +
						(this.props.readOnly ? 'read-only ' : ' ') +
						(this.state.filterOpened ? 'active' : '') +
						(this.props.error ? ' form-error-border' : '') +
						(this.props.pullUp ? ' dd-pull-up' : '')
					}
					title={"Selected: " + (this.state.filterSelected?.title ?? 'None')}
					onClick={(e) => {
						this.state.filterOpened ? this.setState({ filterOpened: false }) : this.setState({ filterOpened: true });
					}}
					key={this.key}
					style={{
						...(this.props.width ? { width: this.props.width } : {}),
						...(this.state.filterOpened ? { zIndex: '3' } : {}),
					}}
				>
					{this.props.style === 'report-home-switcher' ? (
						<img alt='' className="rhs-sel-icon" src="/images/small-house.svg" />
					) : (
						undefined
					)}
					{this.props.title ? (
						typeof this.props.title === 'string' && this.props.title.startsWith('/images/') ? (
							<img alt='' src={this.props.title} />
						) : (
							`${this.props.title}${this.props.style === 'btmh' ||
								this.props.style === 'report-home-switcher'
								? ''
								: ':'
							}`
						)
					) : ''}{' '}
					{this.props.style !== 'btmh' &&
						this.props.style !== 'report-home-switcher'
						? ((typeof this.state.filterSelected === 'object' && this.state.filterSelected !== null) ? this.state.filterSelected.title ?? '' : this.state.filterSelected) ?? ''
						: ''}
					{this.props.title?.indexOf('/images/') === 0 ? (
						undefined
					) : this.props.style === 'btmh' ||
						this.props.style === 'report-home-switcher' ? (
						<img
							alt=''
							src={
								'/images/' +
								((this.state.filterOpened && !this.props.pullUp) ||
									(!this.state.filterOpened && this.props.pullUp)
									? 'btmh-arrow-active.svg'
									: 'btmh-arrow.svg')
							}
							className="cust-dropdown-arrow-icon"
						/>
					) : (
						<img
							alt=''
							src={
								'/images/' +
								(this.state.filterOpened
									? 'cdropdown-arrow-active.svg'
									: 'cdropdown-arrow.svg')
							}
							className="cust-dropdown-arrow-icon"
						/>
					)}
				</button>
				<div className={
					(this.props.style ? 'cdb-style-' + this.props.style : '') +
					' cust-dropdown-button ' +
					(this.props.readOnly ? 'read-only ' : ' ') +
					(this.state.filterOpened ? 'active' : '') +
					(this.props.error ? ' form-error-border' : '') +
					(this.props.pullUp ? ' dd-pull-up' : '')
				} style={{
					height: 0,
					padding: 0,
					border: 0,
					width: this.state.filterOpened ? '100%' : 0,
					display: 'block',
				}}>
					{this.state.filterOpened && (
						<div role='list'
							className={
								'cust-dd-bubble ' + (this.props.openUp ? 'cust-dd-open-up' : '')
							}
							style={{
								top: 0,
							}}
						>
							{this.props.options.map((val, idx) => (
								<div role='listitem'>
									<button
										key={this.key + '-opt-' + idx}
										className={
											'cust-dd-option ' +
											(val === this.state.filterSelected ||
												(val?.key === this.state?.filterSelected?.key &&
													typeof val === 'object')
												? 'selected'
												: '') +
											(idx === 0
												? ' first'
												: idx === this.props.options.length - 1
													? ' last'
													: '')
										}
										onClick={(e) =>
											this.selectBubbleOption(e || window.event, val)
										}
									>
										{this.props.style === 'btmh' ||
											this.props.style === 'report-home-switcher' ? (
											<img alt='' src="/images/small-house.svg" />
										) : (
											undefined
										)}
										{typeof val === 'object' ? val.title || '-' : val}
										{val.subTitle ? (
											<div className="cust-dd-subtitle">{val.subTitle}</div>
										) : (
											undefined
										)}
										<span className='sr-only'>{(val === this.state.filterSelected || (val?.key === this.state?.filterSelected?.key &&
											typeof val === 'object')
											? 'selected'
											: '')}
										</span>
									</button>
								</div>))}
						</div>
					)}
					{this.props.error && (
						<div className="form-error-message">{this.props.error}</div>
					)}
				</div>
			</span>
		);
	}
}

export default withRouter(connect(() => ({}))(CDropdown));
