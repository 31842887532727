import moment from "moment";
import { getBuildingRequest, getBuildingRequestLM, homeownerAction, createProject } from "../../actions/buildings";
import { getCustomerBuildings, setCurrentBuilding } from "../../actions/auth";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "./CustomerDashboard.scss";
import { withTranslation, Trans } from "react-i18next";
import FocusLock from 'react-focus-lock';
import i18next from 'i18next';
import Themes from "./Themes";
import CDropdown from "./components/CDropdown";
import "./Themes.scss";
import axios from "axios";
import { toast } from "react-toastify";
import CFaqButton from "./components/CFaqButton";
import CFAQSection from "./components/CFAQSection";
import { shuffleArray } from "../../helpers/utils";

const AuditStatus = require('../../helpers/audit-status');

/////

let STATUSES, statusEqual, statusGt, statusGtEqual, THEME, ASYNC_STATUSES;

//

class CustomerDashboard extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			rand: 0,
			modalOpen: null,
			modalY: 0,
			buildingInfoLoaded: false,
			doneExpand: {},
			SOList: [],
			loading: true,
			preferred_language: this.T('hmp-lang-code').toLowerCase() || 'en'
		};

		this.modalTitleRef = {};
		this.modalCloseClick = this.modalCloseClick.bind(this);
		this.modalOpenClick = this.modalOpenClick.bind(this);

		THEME = this.theme = Themes.autodetect();
		const statObj  = AuditStatus(this.theme);
		STATUSES       = statObj.STATUSES;
		statusEqual    = statObj.statusEqual;
		statusGt       = statObj.statusGt;
		statusGtEqual  = statObj.statusGtEqual;
		ASYNC_STATUSES = statObj.ASYNC_STATUSES;

		if (window.location.href.includes('/dashboard')) {
			(async () => {
				if (localStorage.getItem('token') !== null) {
					await props.dispatch(getCustomerBuildings(true));
				}
				else {
					await props.dispatch(getBuildingRequest());
					await props.dispatch(getBuildingRequestLM());
				}
				this.state.loading = false;
				this.setState({ loading: false });
			})();
		}
		else {
			this.state.loading = false;
		}

		this.langOptions = [
			{ key: 'en', title: 'English' },
			{ key: 'fr', title: 'Français' }
		];
	}

	static propTypes = {
		building: PropTypes.object,
	};

	static defaultProps = {
		building: {},
	};

	componentDidMount() {
		const buildingId = this.props.currentUser?.currentBuildingId || this.props.currentUser?.primaryBuildingId;
	}

	componentDidUpdate(prevProps) {
		if (this.props.building && !this.state.buildingInfoLoaded) {
			this.setState({
				buildingInfoLoaded: true,
				rand: Math.random(),
			});
		}
	}

	modalCloseClick(stateName) {
		return () => {
			const element = document.getElementById(stateName+='-trigger');
			setTimeout(()=>{
				element?.focus()
			}, 300);
			this.modalJustOpened = false;
			this.setState({
				modalOpen: null,
				rand: Math.random(),
			});
		};
	}

	modalOpenClick(stateName) {

		if (!this.modalTitleRef[stateName]) {
			this.modalTitleRef[stateName] = React.createRef();
		}

		return () => {
			this.modalJustOpened = true;
			setTimeout(()=>{
				if (this.modalJustOpened && this.modalTitleRef[stateName] && this.modalTitleRef[stateName].current) {
					document.getElementById('crp-modal-close')?.focus();
					this.modalJustOpened = false;
				}
			}, 100);
			this.setState({
				faqExpand: null,
				modalOpen: stateName,
				rand: Math.random(),
				modalY: this.props.isPreview ? document.getElementById('report-preview-cont').scrollTop : document.documentElement.scrollTop,
				genCommentsShow: false,
				recCommentsShow: []
			});
		};
	}

	renderModal(stateName, title, contentFn) {
		return (
			<div
				className="crp-modal-cont"
				style={this.state.modalOpen === stateName ? { display: "block" } : { display: "none" }}
				//onClick={this.modalCloseClick(stateName)}
				aria-label={title + " Modal"}
				role="dialog"
				tabIndex="-1"
				aria-modal="true"
				aria-labelledby="crp-modal-title"
				aria-live="polite" accessibilityLiveRegion="polite"
			>
				{this.state.modalOpen === stateName ? <FocusLock>
					<div
						className="crp-modal"
						style={{ top: this.state.modalY + "px" }}
						onClick={(e) => {
							(e || window.event).stopPropagation();
							return false;
						}}
					>
						<div ref={this.modalTitleRef[stateName]} className="crp-modal-header">
							<h2 className="crp-modal-title">{title}</h2>
							<button id="crp-modal-close" onClick={this.modalCloseClick(stateName)}><img alt={this.T("Close Modal")} src="/images/x-icon-black.svg" /></button>
						</div>
						<div className="crp-modal-body">{contentFn()}</div>
					</div>
				</FocusLock> : undefined}
			</div>
		);
	}


	renderGenericStep(number, statusCode, title, contentFn, disabled) {
		const STEP_STATE_TITLE = {
			'unavailable': 'Unavailable',
			'pending': 'Ongoing',
			'complete': 'Done',
			'not-started': 'Not Started'
		};
	
		let status = '';
		if (statusCode instanceof Array) {
			if (disabled) {
				status = 'unavailable';
			}
			else if (statusGtEqual(this.props.status, statusCode[0]) && !statusGt(this.props.status, statusCode[1])) {
				status = 'pending';
			}
			else if (statusGt(this.props.status, statusCode[1])) {
				status = 'complete';
			}
			else {
				status = 'not-started';
			}
		}
		else {
			if (disabled) {
				status = 'unavailable';
			}
			else if (statusEqual(this.props.status, statusCode)) {
				status = 'pending';
			}
			else if (statusGt(this.props.status, statusCode)) {
				status = 'complete';
			}
			else {
				status = 'not-started';
			}
		}
		return (
			<div className={"cdb-step-cont-generic cdb-step-cont-" + status} aria-label={this.T("STEP") + " " + number}>
				<div className={"cdb-step-highlight cdb-step-hl-" + status} />
				<img src={status === "complete" ? "/images/cdb-step-done.svg" : (disabled ? "/images/step-unavailable.svg" : status == "pending" ? "/images/cdb-step-ongoing.svg" : "/images/cdb-step-not-started.svg")} alt={""} />
				<div className="cdb-step-right">
					<div className="cdb-step-no" ><Trans i18nKey={"cdb-step-no"}>STEP {{number}}</Trans></div>
					<div className={"cdb-step-status cdb-step-status-" + status} >{this.T(`${STEP_STATE_TITLE[status]}`.toUpperCase())}</div>
					{status == 'complete' ? <button className={"cdb-done-expand-collapse"} onClick={() => {
						const expand = this.state.doneExpand;
						expand[number] = !expand[number]
						this.setState({ doneExpand: expand, rand: Math.random() });
					}}><img src={'/images/' + (this.state.doneExpand[number] ? 'cdb-done-collapse.svg' : 'cdb-done-expand.svg')} alt={(this.state.doneExpand[number] ? this.T('Collapse') : this.T('Expand'))} /></button> : undefined}
				</div>
				<div className="cdb-step-title"  style={this.state.doneExpand[number] ? {} : {borderBottom: "none"}}>{title}</div>
				{(contentFn && status != 'not-started' && (status != 'complete' || this.state.doneExpand[number])) ? contentFn(status) : undefined}
			</div>
		);
	}

	shareBillsClick(e) {
		e.preventDefault();
		this.modalOpenClick('share-bills-modal')(e);
		return false;
	}

	shareEnerGuideClick(e) {
		e.preventDefault();
		this.modalOpenClick('share-energuide-modal')(e);
		return false;
	}

	shareBillsModal() {	
		return (
			<div className="cdb-share-bills-modal" aria-live="polite" accessibilityLiveRegion="polite">
				<div className="cdb-share-modal-para" ><Trans i18nKey="cdb-accept-bills-para">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam venenatis ligula eget turpis ultrices, sit amet venenatis purus mollis. Aliquam odio velit, iaculis sollicitudin nibh non, ullamcorper rutrum augue. Vivamus non fermentum lorem. Aenean porttitor egestas laoreet. Nullam in aliquet nunc, vitae feugiat ante.<br/>
					<br/>
					Curabitur nunc ipsum, tincidunt at elementum quis, congue quis ante. Phasellus mattis laoreet turpis. Proin pellentesque tortor id aliquam mollis. Fusce libero sapien, congue ac felis eu, euismod pretium mauris. Ut nec diam quis leo viverra venenatis. Pellentesque tempor, lorem at hendrerit varius, dui est fringilla dolor, sed suscipit lectus eros fringilla nibh.
				</Trans></div>
				<button className="cdb-accept-checkbox" onClick={() => this.setState({ acceptShareBills: !this.state.acceptShareBills })}>
					<img alt={this.T("Current state: ") + this.T(this.state.acceptShareBills ? 'Checked' : 'Unchecked')} src={this.state.acceptShareBills ? "/images/cdb-check-checked.svg" : "/images/cdb-check-unchecked.svg"}/>
					<div className="cdb-accept-checkbox-label"><Trans i18nKey="cdb-accept-bills-checkbox">
						I understood all terms and herby give my consent to share my Utility Data.
					</Trans></div>
				</button>
				<div className="cdb-accept-buttons-block"></div>
				<div className="cdb-accept-buttons">
					<button className={"cdb-accept-cancel-btn"} onClick={() => {
						this.setState({ acceptShareBills: false });
						this.modalCloseClick()();
					}}>{this.T("Cancel")}</button>
					<button className={"cdb-accept-done-btn " + (!this.state.acceptShareBills ? "disabled" : "")} onClick={() => {
						if (this.state.acceptShareBills) {
							this.props.dispatch(homeownerAction(this.props.building.buildingId, {action: 'share-bills'}));
							this.modalCloseClick()();
						}
					}}>{this.T("Done")}</button>
				</div>
			</div>
		);
	}

	shareEnerGuideModal() {	
		return (
			<div className="cdb-share-energuide-modal" aria-live="polite" accessibilityLiveRegion="polite">
				<div className="cdb-share-modal-para" ><Trans i18nKey="cdb-accept-bills-para">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam venenatis ligula eget turpis ultrices, sit amet venenatis purus mollis. Aliquam odio velit, iaculis sollicitudin nibh non, ullamcorper rutrum augue. Vivamus non fermentum lorem. Aenean porttitor egestas laoreet. Nullam in aliquet nunc, vitae feugiat ante.<br/>
					<br/>
					Curabitur nunc ipsum, tincidunt at elementum quis, congue quis ante. Phasellus mattis laoreet turpis. Proin pellentesque tortor id aliquam mollis. Fusce libero sapien, congue ac felis eu, euismod pretium mauris. Ut nec diam quis leo viverra venenatis. Pellentesque tempor, lorem at hendrerit varius, dui est fringilla dolor, sed suscipit lectus eros fringilla nibh.
				</Trans></div>
				<button className="cdb-accept-checkbox" onClick={() => this.setState({ acceptShareEnerGuide: !this.state.acceptShareEnerGuide })}>
					<img alt={this.T("Current state: ") + this.T(this.state.acceptShareEnerGuide ? 'Checked' : 'Unchecked')} src={this.state.acceptShareEnerGuide ? "/images/cdb-check-checked.svg" : "/images/cdb-check-unchecked.svg"}/>
					<div className="cdb-accept-checkbox-label"><Trans i18nKey="cdb-accept-bills-checkbox">
					I understood all terms and herby give my consent to share my EnergyGuide Report.
					</Trans></div>
				</button>
				<div className="cdb-accept-buttons-block"></div>
				<div className="cdb-accept-buttons">
					<button className={"cdb-accept-cancel-btn"} onClick={() => {
						this.setState({ acceptShareEnerGuide: false });
						this.modalCloseClick()();
					}}>{this.T("Cancel")}</button>
					<button className={"cdb-accept-done-btn " + (!this.state.acceptShareEnerGuide ? "disabled" : "")} onClick={() => {
						if (this.state.acceptShareEnerGuide) {
							this.props.dispatch(homeownerAction(this.props.building.buildingId, {action: 'share-energuide-reports'}));
							this.modalCloseClick()();
						}
					}}>{this.T("Done")}</button>
				</div>
			</div>
		);
	}

	validationModal() {	
		return (
			<div className="cdb-share-energuide-modal" aria-live="polite" accessibilityLiveRegion="polite">
				<div className="cdb-share-modal-para" ><Trans i18nKey="cdb-accept-bills-para">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam venenatis ligula eget turpis ultrices, sit amet venenatis purus mollis. Aliquam odio velit, iaculis sollicitudin nibh non, ullamcorper rutrum augue. Vivamus non fermentum lorem. Aenean porttitor egestas laoreet. Nullam in aliquet nunc, vitae feugiat ante.<br/>
					<br/>
					Curabitur nunc ipsum, tincidunt at elementum quis, congue quis ante. Phasellus mattis laoreet turpis. Proin pellentesque tortor id aliquam mollis. Fusce libero sapien, congue ac felis eu, euismod pretium mauris. Ut nec diam quis leo viverra venenatis. Pellentesque tempor, lorem at hendrerit varius, dui est fringilla dolor, sed suscipit lectus eros fringilla nibh.
				</Trans></div>
				<div className="cdb-accept-buttons-block"></div>
				<div className="cdb-accept-buttons">
					<button className={"cdb-accept-done-btn"} onClick={() => {
						this.modalCloseClick()();
					}}>{this.T("Close")}</button>
				</div>
			</div>
		);
	}

	selectSOModal() {	
		let soOptions = [
			{
				title: this.T('None selected'),
				key: null
			}
		];
		for (let item of this.state.SOList) {
			soOptions.push({
				title: item.name,
				key: item.ServiceOrganizationId
			});
		}
		return (
			<div className="cdb-select-so-modal" aria-live="polite" accessibilityLiveRegion="polite">
				{!THEME.randomSOSelectOnly ? [
					<div className="cdb-ssom-label">{this.T("Choose your service provider")}</div>,
					<CDropdown aria-label="Select service provider" key="crp-select-so" title={this.T('Selected')} options={soOptions} defaultValue={this.state.soSelected ?? soOptions[0]} onChange={(val) => {
						this.setState({soSelected: val, rand: Math.random()});
					}}></CDropdown>,
					<br/>,
					<div className="cdb-ssom-label">{this.T("For more information, go to:")}</div>,
					<br/>,
					(THEME.advisorExperience ? <a className="cdb-ssom-link" href="https://www.efficiencyns.ca/preferred-partners/" target="_blank">www.efficiencyns.ca/preferred-partners/</a> : undefined),
					<br/>,
					<hr/>
				] : undefined}
				<div className="cdb-ssom-label">{this.T("Preferred Contact Phone Number")}</div><br/>
				<input defaultValue={this.props.building?.phoneNumber} type="tel" className="cdb-ssom-phone-input" id="preferred_phone_number" placeholder={this.T("Enter your contact number")}/><br/>
				<div className="cdb-ssom-text"><Trans i18nKey={"preferred-phone-desc"}>
					We will share the following information with your energy advisor so they can contact you to schedule your remote audit.<br/>
					<br/>
					The email address on your account andyour preferred contact phone number.
				</Trans></div>
				{THEME.bilingualRemoteAudits ? [
				<div className="cdb-ssom-label">{this.T("Preferred Language")}</div>,
				<br/>,
				<CDropdown
					aria-label={this.T("Preferred Language")}
					options={this.langOptions}
					error={null}
					defaultValue={this.langOptions.filter((e) => this.state.preferred_language === e.key)[0]}
					emptyText={this.T("Select Language")}
					onChange={(val) => {
						this.setState({ preferred_language: val.key });
					}}
				/>] : undefined}
				<div className="cdb-ssom-btn-cont">
					<button className="cdb-ssom-confirm-btn" onClick={async () => {
						if (!this.state?.soSelected?.key && !THEME.randomSOSelectOnly) {
							toast.error(this.T("Please select a service organization"));
							return;
						}
						if (THEME.randomSOSelectOnly && (!document.getElementById('preferred_phone_number').value?.length || (THEME.bilingualRemoteAudits && !this.state.preferred_language?.length))) {
							toast.error(this.T("Please enter your preferred phone number and language"));
							return;
						}
						this.setState({ loading: true });
						if (await this.props.dispatch(createProject(this.props.building.buildingId, THEME.randomSOSelectOnly ? null : this.state?.soSelected?.key))) {
							let action = 'pre-requested-booking';
							let eventId = 'request-remote-audit';
							if (this.props.status?.main >= STATUSES.POST_AWAITING_SIGNUP.main) {
								action = 'post-requested-booking';
								eventId = 'request-post-upgrade-validation-audit';
							}
							await this.props.dispatch(homeownerAction(this.props.building.buildingId, {action, phoneNumber: document.getElementById('preferred_phone_number').value, preferred_language: THEME.bilingualRemoteAudits ? this.state.preferred_language : undefined}));
							await this.props.dispatch(getBuildingRequest(this.props.building.buildingId));
							await this.props.dispatch(getBuildingRequestLM(this.props.building.buildingId));
							window.TRACK_EVENT({eventId});
							toast.success(this.T("Successfully submitted request."));
							this.modalCloseClick()();
						}
						this.setState({ loading: false });
					}}>{this.T("Confirm")}</button>
					<button className="cdb-ssom-cancel-btn" onClick={this.modalCloseClick()}>{this.T("Cancel")}</button>
				</div>
			</div>
		);
	}

	step1InnerGeneric() {
		return (
			<div className="cdb-step-content">
				<div className="cdb-step-para" >
					{this.T('Here are the things you can do to increase accuracy. Do one or more.')}
				</div>
				<div className="cdb-step-checkbox">
					<img src={this.props.dataSources.has_survey ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div  className="cdb-step-check-title">{this.T('Survey')}</div>
					<a  className="cdb-step-checkbox-button" href="#/app/customer/wizard">{this.props.dataSources.has_survey ? this.T('Update') : this.T('Share')} <img src='/images/cdb-step-checkbox-button.svg' /></a>
				</div>
				<div className="cdb-step-checkbox">
					<img src={this.props.dataSources.has_bills ? '/images/cdb-step-checkbox-checked.svg' : this.props.building.utilityDataStatus == ASYNC_STATUSES.SHARED ? '/images/cdb-step-checkbox-pending.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div  className="cdb-step-check-title">{this.T('Utility Data')}</div>
					{!this.props.dataSources.has_bills && !this.props.building.utilityDataStatus ? <a  className="cdb-step-checkbox-button" onClick={(e) => this.shareBillsClick(e || window.event)}>{this.T('Share')} <img src='/images/cdb-step-checkbox-button.svg' /></a> : undefined}
					{this.props.building.utilityDataStatus == ASYNC_STATUSES.SHARED && !this.props.dataSources.has_bills ? <a  className="cdb-step-checkbox-button pending">{this.T('Pending')}</a> : undefined}
				</div>
				<div className="cdb-step-checkbox">
					<img src={this.props.dataSources.has_energuide ? '/images/cdb-step-checkbox-checked.svg' : this.props.building.energuideReportStatus == ASYNC_STATUSES.SHARED ? '/images/cdb-step-checkbox-pending.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div  className="cdb-step-check-title">{this.T('EnerGuide Report')}</div>
					{!this.props.dataSources.has_energuide && !this.props.building.energuideReportStatus ? <a  className="cdb-step-checkbox-button" onClick={(e) => this.shareEnerGuideClick(e || window.event)}>{this.T('Share')} <img src='/images/cdb-step-checkbox-button.svg' /></a> : undefined}
					{this.props.building.energuideReportStatus == ASYNC_STATUSES.SHARED ? <a  className="cdb-step-checkbox-button pending">{this.T('Pending')}</a> : undefined}
				</div>
			</div>
		);
	}

	doneWithStep2Click() {
		if (statusEqual(this.props.status, STATUSES.PLN_MAKING_RETROFIT_PLAN)) {
			this.props.dispatch(homeownerAction(this.props.building.buildingId, {action: 'done-step-2'}));
		}
	}

	step2InnerGeneric(status) {
		const vendorName = this.props.building?.vendorInfo?.vendorName;
		const vendorUrl = this.props.building?.vendorInfo?.vendorLink;
		const vendorNamePossesive = this.props.building?.vendorInfo?.vendorNamePossesive;
		return (
			<div className="cdb-step-content cdb-step-has-footer">
				<div className="cdb-step-para" >
					<Trans i18nKey="cdb-vendor-make-plan">Make a plan with a pro. Take advantage of {{vendorNamePossesive}} great incentives.</Trans>
				</div>				
				<button className="cdb-done-with-this-step" onClick={() => this.doneWithStep2Click()}>{this.T('Done with this step?')} <img alt={status == "complete" ? this.T('Current: Yes') : this.T('Current: No')} src={status == 'complete' ? '/images/slide-toggle-yes.svg' : '/images/slide-toggle-no.svg'}/></button>
				<div className="cdb-step-footer">
					<a className="cdb-vendor-link" href={vendorUrl}><img alt="" src="/images/vendor-icon-blue.svg" /> <Trans i18nKey={"cdb-vendor-link"}>Open {{vendorNamePossesive}} website</Trans></a>
				</div>
			</div>
		);
	}

	step3InnerGeneric() {
		return (
			<div className={"cdb-step-content " + (this.theme.fileUpload ? "cdb-step-has-footer" : "")}>
				<div className="cdb-step-para" >
					{this.T('Upgrades completed? Update your Energy Rating and Retrofit Guidance.')}
				</div>
				<div className="cdb-step-checkbox">
					<img src={this.props.dataSources.has_survey ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div  className="cdb-step-check-title">{this.T('Survey')}</div>
					<a  className="cdb-step-checkbox-button" href="#/app/customer/wizard">{this.props.dataSources.has_survey ? this.T('Update') : this.T('Share')} <img src='/images/cdb-step-checkbox-button.svg' /></a>
				</div>
				<div className="cdb-step-checkbox">
					<img src={this.props.upgradesValidated ? '/images/cdb-step-checkbox-checked.svg' : '/images/cdb-step-checkbox-empty.svg'}/><div  className="cdb-step-check-title">{this.T('Get Upgrades Vaildated \nby a Pro')}</div>
					<a  className="cdb-step-checkbox-button cdb-more-info" href="#/app/customer/wizard" onClick={(e) => {
						e = e || window.event;
						e.preventDefault();
						e.stopPropagation();
						this.modalOpenClick('validation-modal')(e);
						return false;
					}}><img src='/images/more-info-blue.svg' className="cdb-more-info-icon"/> {this.T('More info')}</a>
				</div>
				{this.theme.fileUpload ? <div className="cdb-step-footer">
					<a className="cdb-vendor-link" href="#/app/customer/upload"><img alt="" src="/images/upload-icon-blue.svg" /> <Trans i18nKey={"cdb-upload-link"}>Upload Files Before Your Audit</Trans></a>
				</div> : undefined}
			</div>
		);
	}

	renderFAQModal(step) {

		if (step === 0) {
			return this.renderModal("faq-modal-" + step, this.T("Dashboard Frequently Asked Questions"), () => {
				return (
					<div className="hmp-faq-section-wrap">
						<CFAQSection section="Dashboard" needsCard={true} />
					</div>
				);
			});	
		}
		/*
		return this.renderModal("faq-modal-" + step, this.T("Step " + step + " Frequently Asked Questions"), () => {
			return (
				<div className="hmp-faq-section-wrap">
					<CFAQSection section="Map > Home Rating" needsCard={true} />
				</div>
			);
		});
	*/
	}

	renderStep(number, statusCode, title, contentFn, disabled, faqInfo) {
		let status = '';
		if (statusCode instanceof Array) {
			if (disabled) {
				status = 'unavailable';
			}
			else if (statusGtEqual(this.props.status, statusCode[0]) && !statusGt(this.props.status, statusCode[1])) {
				status = 'pending';
			}
			else if (statusGt(this.props.status, statusCode[1])) {
				status = 'complete';
			}
			else {
				status = 'not-started';
			}
		}
		else if (statusCode) {
			if (disabled) {
				status = 'unavailable';
			}
			else if (statusEqual(this.props.status, statusCode)) {
				status = 'pending';
			}
			else if (statusGt(this.props.status, statusCode)) {
				status = 'complete';
			}
			else {
				status = 'not-started';
			}
		}
		else {
			status = 'complete';
		}
		if (number == 'optional') {
			return (
				<div className="cdb-step-cont-optional" aria-label={this.T("OPTIONAL STEP")}>
					<div className="cdb-optional-header" ><img alt={""} src="/images/step-optional.svg"/>{this.T('OPTIONAL')}</div>
					{contentFn ? contentFn() : undefined}
				</div>
			);			
		}
		else {
			return (
				<div className={"cdb-step-cont cdb-step-cont-" + status} aria-label={this.T("STEP") + " " + number}>
					<div className={"cdb-step-highlight cdb-step-hl-" + status} />
					<img src={status === "complete" ? "/images/step-check.svg" : (disabled ? "/images/step-unavailable.svg" : status == "pending" ? "/images/step-pending.svg" : "/images/step-not-started.svg")} alt={""} />
					<div className="cdb-step-right">
						<div className="cdb-step-no" ><Trans i18nKey={"cdb-step-no"}>STEP {{number}}</Trans></div>
						{faqInfo ? <div className={"cdb-faq-cont " + (status === 'not-started' ? 'not-started' : (status === 'pending' ? 'pending' : '')) }>
							<CFaqButton ariaLabel={this.T('Dashboard FAQ Step ') + `${number}`} id={'dashboard-faq-step-' + number} onClick={this.modalOpenClick("faq-modal-" + number)} />
						</div> : undefined}
						<div className={"cdb-step-status cdb-step-status-" + status} tabIndex={status == 'not-started' ? undefined : 0}>{this.T(`${status == 'not-started' ? '' : status}`.toUpperCase())}</div>
					</div>
					<div className="cdb-step-title" >{title}</div>
					{contentFn && status !== 'not-started' ? contentFn() : undefined}
				</div>
			);
		}
	}

	renderSubStep(status, step, substep, title, contentFnActive, contentFnComplete) {
		return statusGtEqual(this.props.status, {main: status.main, sub: 0}) ? 
			(<div className={"cdb-sub-step " + (statusEqual(this.props.status, status) ? 'cdb-sub-step-current' : '')}>
				<img alt="" src={statusGt(this.props.status, status) ? "/images/sub-step-complete.svg" : "/images/sub-step-incomplete.svg"} /> 
				<div className="cdb-sub-step-text"><Trans i18nKey={`cdb-step${step}-${substep}-title-ae`}>{title}</Trans></div>
				{(contentFnActive && !statusGt(this.props.status, status)) ? contentFnActive() : undefined}
				{(contentFnComplete && statusGt(this.props.status, status)) ? contentFnComplete() : undefined}
			</div>)
		: undefined;
	}

	step1Inner() {
		if (this.theme?.disableBooking) {
			return (
				<div className="cdb-step-content">
					<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-booking-disabled"}>
						<div className="cdb-booking-disabled-1" >We are no longer able to accept requests for video audit at this time as the pilot period has ended.</div>
						<div className="cdb-booking-disabled-2" >We thank you for your interest and apologize for the inconvenience.</div>
					</Trans></div>
				</div>
			);			
		}
		else if (statusGtEqual(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED)) {
			return (
				<div className="cdb-step-content">
					{statusEqual(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED) ? 
						<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-sub-step-eq-upg-upgrades-completed-1"}>
							<img alt="" src="/images/sub-step-incomplete.svg"/> 
							<div className="cdb-sub-step-text" >Request to Book Home Energy Assessment</div>
						</Trans></div>
					: undefined}
					{statusEqual(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED) ? <center><Trans i18nKey={"cdb-step1-sub-step-eq-upg-upgrades-completed-2"}>
						<button className="cdb-sc-button-2" onClick={() => {
							this.props.dispatch(homeownerAction(this.props.building.buildingId));
							setTimeout(() => {
								window.location.href = `#/app/customer/success/booking`;
							}, 500);
						}}>
							Request Home Energy Assessment
						</button>
					</Trans></center>
					: undefined}
					{statusGt(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED) ? 
						<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-sub-step-after-upg-upgrades-completed"}>
							<img alt="" src="/images/sub-step-complete.svg"/> 
							<div className="cdb-sub-step-text" >Request to Book Home Energy Assessment</div>
						</Trans></div>
					: undefined}
					{!statusGtEqual(this.props.status, STATUSES.POST_AWAITING_AUDIT) ? 
						<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-sub-step-before-post-awaiting-audit"}>
							<img alt="" src="/images/sub-step-incomplete.svg"/> 
							<div className="cdb-sub-step-text" >Energy Auditor Book HEA</div>
							<div className="cdb-sub-step-lower-text" >Your Energy Advisor will reach out to you to book your appointment.</div>
						</Trans></div>
					: undefined}
					{statusGtEqual(this.props.status, STATUSES.POST_AWAITING_AUDIT) ? 
						<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-sub-step-gte-post-awaiting-audit"}>
							<img alt="" src="/images/sub-step-complete.svg"/> 
							<div className="cdb-sub-step-text" >Energy Auditor Book HEA</div>
							<br/>
							<div style={{height: '12px'}}/>
						</Trans></div>
					: undefined}
				</div>
			);
		}
		else {
			return (
				<div className="cdb-step-content">
					{statusEqual(this.props.status, STATUSES.VA_AUTOMATED_INSIGHTS) ? 
						<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-sub-step-eq-pre-auto-insights-1"}>
							<img alt="" src="/images/sub-step-incomplete.svg"/> 
							<div className="cdb-sub-step-text" >Request to Book Home Energy Assessment</div>
						</Trans></div>
					: undefined}
					{statusEqual(this.props.status, STATUSES.VA_AUTOMATED_INSIGHTS) ? <center><Trans i18nKey={"cdb-step1-sub-step-eq-pre-auto-insights-2"}>
						<button className="cdb-sc-button-2" onClick={() => {
							this.props.dispatch(homeownerAction(this.props.building.buildingId))
							setTimeout(() => {
								window.location.href = `#/app/customer/success/booking`;
							}, 500);
						}}>
							Request Home Energy Assessment!
						</button>
					</Trans></center>
					: undefined}
					{statusGt(this.props.status, STATUSES.VA_AUTOMATED_INSIGHTS) ? 
						<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-sub-step-after-va-auto-insights"}>
							<img alt="" src="/images/sub-step-complete.svg"/> 
							<div className="cdb-sub-step-text" >Request to Book Home Energy Assessment</div>
						</Trans></div>
					: undefined}
					{!statusGtEqual(this.props.status, STATUSES.PRE_AWAITING_AUDIT) ? 
						<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-sub-step-before-pre-awaiting-audit"}>
							<img alt="Sub-Step Incomplete Icon" src="/images/sub-step-incomplete.svg"/> 
							<div className="cdb-sub-step-text" >Energy Auditor Book HEA</div>
							<div className="cdb-sub-step-lower-text" >Your Energy Advisor will reach out to you to book your appointment.</div>
							</Trans></div>
					: undefined}
					{statusGtEqual(this.props.status, STATUSES.PRE_AWAITING_AUDIT) ? 
						<div className="cdb-sub-step"><Trans i18nKey={"cdb-step1-sub-step-pre-awaiting-audit"}>
							<img alt="" src="/images/sub-step-complete.svg"/> 
							<div className="cdb-sub-step-text" >Energy Auditor Book HEA</div>
							<br/>
							<div style={{height: '12px'}}/>
							</Trans></div>
					: undefined}
				</div>
			);
		}
	}

	step1InnerE1() {
		return (
			<div className="cdb-step-content">
				{this.renderSubStep(STATUSES.VA_AUTOMATED_INSIGHTS, 1, 1, "Claim My Home")}
			</div>
		);
	}

	async updateSOList() {
		try {
			let res = await axios.get(`/v3/orgs/get-organization-children/${this.props.org_id}`);
			if (res.status === 200 || res.status === 204) {
				if (res.data instanceof Array) {
					this.setState({ SOList: shuffleArray(res.data), rand: Math.random() });
				}
				else {
					this.setState({ SOList: [], rand: Math.random() });
				}
				return true;
			}
			return false;
		}
		catch (err) {
			return false;
		}
	}

	step2InnerE1() {
		return (
			<div className="cdb-step-content">
				{this.renderSubStep(STATUSES.PRE_AWAITING_SIGNUP, 2, 1, "Sign Up for Video Home Energy Assessment", () => (<div><Trans i18nKey="cdb-substep-2-1-unchecked">
					<div className="cdb-substep-subtext-highlight">{this.T("Cost: FREE")}</div>
					<button className="cdb-substep-sign-up-here" onClick={async () => {
						if (await this.updateSOList()) {
							this.modalOpenClick('select-so-modal')();
						}
					}}>{this.T("Sign Up Here")}</button>
				</Trans></div>), () => (<div>
					{!statusGt(this.props.status, STATUSES.POST_AWAITING_SIGNUP) && this.props.building?.project?.ServiceOrganizationName ? <div className="cdb-substep-subtext-highlight">{this.T("Service Provider:")} {this.props.building?.project?.ServiceOrganizationName}</div> : undefined}
				</div>))}
				{this.renderSubStep(STATUSES.PRE_AWAITING_BOOKING, 2, 2, "Book Appointment with Energy Advisor", () => (<div className="cdb-substep-subtext"><Trans i18nKey="cdb-substep-2-2-unchecked-ae">
					Your Energy Advisor will reach out to you to book your appointment.
				</Trans></div>), () => (<div/>))}
				{this.renderSubStep(STATUSES.PRE_AWAITING_AUDIT, 2, 3, "Video Home Energy Assessment", () => (<div className="cdb-substep-subtext"><Trans i18nKey="cdb-substep-2-3-unchecked-ae">
					A Registered Energy Advisor will model your home on a video call
				</Trans></div>), () => (<div/>))}
				{this.renderSubStep(STATUSES.PRE_PREVIEW_REPORT, 2, 4, "Get Published Home Energy Report", () => (<div className="cdb-substep-subtext"><Trans i18nKey="cdb-substep-2-4-unchecked-ae">
					Once your energy advisor publishes your report, you can see it here!
				</Trans></div>), () => (<div/>))}
				{/*this.renderSubStep(STATUSES.PRE_PLAN_APPROVED, 2, 7, "Homeowner Plan Approval", () => (<div><Trans i18nKey="cdb-substep-2-7-unchecked">
					<button className="cdb-substep-sign-up-here">Approve Retrofit Plan</button>
				</Trans></div>), () => (<div/>))*/}
			</div>
		);
	}

	step3InnerE1() {
		return (
			<div className="cdb-step-content">
				{this.renderSubStep(STATUSES.PRE_PREVIEW_PUBLISHED, 3, 0, "Start Retrofits", () => (<div>
					<button className="cdb-substep-sign-up-here" onClick={async () => {
						this.setState({ loading: true });
						await this.props.dispatch(homeownerAction(this.props.building.buildingId, {action: 'start-retrofits'}));
						this.setState({ loading: false });
						this.modalCloseClick()();
					}}>{this.T("The Retrofits Have Started")}</button>
				</div>), () => (<div/>))}
				{this.renderSubStep(STATUSES.UPG_UPGRADES_IN_PROGRESS, 3, 1, "Retrofit Completion", () => (<div>{statusGtEqual(this.props.status, STATUSES.UPG_UPGRADES_IN_PROGRESS) ? 
					<button className="cdb-substep-sign-up-here" onClick={async () => {
						this.setState({ loading: true });
						await this.props.dispatch(homeownerAction(this.props.building.buildingId, {action: 'completed-retrofits'}));
						this.setState({ loading: false });
						this.modalCloseClick()();
					}}>{this.T("The Retrofits Are Complete")}</button>
				: undefined}</div>), () => (<div/>))}
			</div>
		);
	}

	step4InnerE1() {
		return (
			<div className="cdb-step-content">
				{this.renderSubStep(STATUSES.POST_AWAITING_SIGNUP, 4, 1, "Sign Up for Video Home Energy Assessment", () => (<div>
					<div className="cdb-substep-subtext-highlight">Cost: FREE</div>
					<button className="cdb-substep-sign-up-here" onClick={async () => {
						if (await this.updateSOList()) {
							this.modalOpenClick('select-so-modal')();
						}
					}}>{this.T("Sign Up Here")}</button>
				</div>), () => (<div>
					{this.props.building?.project?.ServiceOrganizationName ? <div className="cdb-substep-subtext-highlight">{this.T("Service Provider:")} {this.props.building?.project?.ServiceOrganizationName}</div> : undefined}
				</div>))}
				{this.renderSubStep(STATUSES.POST_AWAITING_BOOKING, 4, 2, "Book Appointment with Energy Advisor", () => (<div className="cdb-substep-subtext"><Trans i18nKey="cdb-substep-4-2-unchecked-ae">
					Your Energy Advisor will reach out to you to book your appointment.
				</Trans></div>), () => (<div/>))}
				{this.renderSubStep(STATUSES.POST_AWAITING_AUDIT, 4, 3, "Video Home Energy Assessment", () => (<div className="cdb-substep-subtext"><Trans i18nKey="cdb-substep-4-3-unchecked-ae">
					A Registered Energy Advisor will model your home on a video call
				</Trans></div>), () => (<div/>))}
				{this.renderSubStep(STATUSES.POST_PREVIEW_REPORT, 4, 4, "Get Published Home Energy Report", () => (<div className="cdb-substep-subtext"><Trans i18nKey="cdb-substep-4-4-unchecked-ae">
					Once your energy advisor publishes your report, you can see it here!
				</Trans></div>), () => (<div/>))}
			</div>
		);
	}

	step5InnerE1() {
		return (
			<div className="cdb-step-content">
				{this.renderSubStep(STATUSES.POST_PREVIEW_PUBLISHED, 5, 0, "Receive Financial Incentives", () => (<div>
					<button className="cdb-substep-sign-up-here" onClick={async () => {
						this.setState({ loading: true });
						await this.props.dispatch(homeownerAction(this.props.building.buildingId, {action: 'incentives-received'}));
						this.setState({ loading: false });
					}}>{this.T("Financial Incentives Received")}</button>
				</div>), () => (<div/>))}
			</div>
		);
	}

	step2Inner() {
		if (statusGtEqual(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED)) {
			if (!statusGtEqual(this.props.status, STATUSES.POST_PREVIEW_PUBLISHED)) {
				return (
					<div className="cdb-step-content">
						<div className="cdb-sc-desc" ><Trans i18nKey={"cdb-sc-desc-call-booked"}>
							Your energy advisor will perform an energy assessment of your home to validate your upgrades while on a video call with you.
						</Trans></div>
					</div>
				);
			}
			else {
				return (<div className="cdb-step-content no-sep-line"></div>);
			}
		}
		else {
			if (!statusGtEqual(this.props.status, STATUSES.PRE_PREVIEW_PUBLISHED)) {
				return (
					<div className="cdb-step-content">
						<div className="cdb-sc-desc" ><Trans i18nKey={"cdb-sc-desc-booked"}>
							Your energy advisor will perform an energy assessment of your home while on a video call with you.
						</Trans></div>
					</div>
				);
			}
			else {
				return (<div className="cdb-step-content no-sep-line"></div>);
			}
		}
	}

	step3Inner() {
		if (statusGtEqual(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED)) {
			if (statusGtEqual(this.props.status, STATUSES.POST_PREVIEW_PUBLISHED)) {
				return (
					<div className="cdb-step-content">
						<div className="cdb-sc-desc" ><Trans i18nKey={"cdb-sc-desc-val-report-ready"}>
							<a className="cdb-sc-link" href="#/app/customer/remote-audit">You now have an Upgrade Validation Report. You can access it at the top of this page, or by clicking here.</a><br/>
							<br/>
						</Trans></div>
					</div>
				);
			}
			else {
				return (<div className="cdb-step-content no-sep-line"></div>);
			}
		}
		else {
			if (statusGtEqual(this.props.status, STATUSES.PRE_PREVIEW_PUBLISHED)) {
				return (
					<div className="cdb-step-content">
						{this.theme.findServiceProviderURL && <div className="cdb-sc-desc"><Trans i18nKey={"cdb-sc-desc-report-ready-with-fsp"}>
							<a className="cdb-sc-link" href="#/app/customer/remote-audit">You now have an Energy Advice Report. You can access it at the top of this page, or by clicking here.</a><br/>
							<br/>
							<div className="cdb-sc-title" >Ready to start saving energy?</div>
							<button className="cdb-sc-button-2" onClick={() => window.location.href = this.theme.findServiceProviderURL}>
								Find a Service Provider
							</button>
							</Trans></div>}
						{!this.theme.findServiceProviderURL && <div className="cdb-sc-desc" ><Trans i18nKey={"cdb-sc-desc-report-ready-no-fsp"}>
							<a className="cdb-sc-link" href="#/app/customer/remote-audit">You now have an Energy Advice Report. You can access it at the top of this page, or by clicking here.</a><br/>
						</Trans></div>}
					</div>
				);
			}
			else {
				return (<div className="cdb-step-content no-sep-line"></div>);
			}
		}
	}

	optional1Inner() {
		return (
			<div className="cdb-step-content csc-optional">
				<div className="cdb-sc-title" >{this.T('Home Information Survey')}</div>
				{!this.props.type1SurveySubmitted ? <div className="cdb-sc-desc" ><Trans i18nKey={"cdb-sc-desc-pre-survey"}>
					Please tell us as much about your home as you can in preparation for your Energy Assessment.<br/>
					<br/>
					This info will be shared with your energy advisor, and this will help determine which upgrades are the best fit for your home in your updated Energy Savings Potential Report.
				</Trans></div> : <div className="cdb-sc-desc" ><Trans i18nKey={"cdb-sc-desc-post-survey"}>
					Thank you for taking the time to provide more information about your home.<br/>
					<br/>
					<a className="cdb-sc-link" href="#/app/customer/view-report">Your Energy Savings Potential Report has been updated. You can access it at the top of this page, or by clicking here.</a>
					<br/><br/>
					Still want to provide more information?<br/>
				</Trans></div>}
				{!statusGtEqual(this.props.status, STATUSES.PRE_AWAITING_AUDIT) ? <button className="cdb-sc-button" onClick={() => (window.location.href = "#/app/customer/wizard")}>
					{this.T('Enter Home Information')}
				</button> : <button className="cdb-sc-button" onClick={() => (window.location.href = "#/app/customer/wizard")}>
					{this.T('Update Home Information')}
				</button>}
			</div>
		);
	}

	getRInfo() {
		if (this.props.type2) {
			return this.props.modelType2 && this.props.modelType2.__report_info;
		} else {
			return this.props.modelType1 && this.props.modelType1.__report_info;
		}
	}

	mviInner() {
		const recs = this.getRInfo() ? this.getRInfo().recommendations : [];
		return (
			<div className="cdb-step-content">
				{recs.map((rec, index) => (
					<div className="cdb-sc-upgrade">
						<img alt={this.T("HEA Check/Uncheck")} src="/images/hea-check-unchecked.svg" /> {rec.name}
					</div>
				))}
			</div>
		);
	}

	renderHomeSwitcher() {
		let ret;
		if (this.props?.currentUser?.primaryBuildingId) {
			let options = [];
			if (this.props.customerBuildings) {
				for (let building of this.props.customerBuildings) {
					options.push({
						title: building.address,
						id: building.id,
						key: building.id,
						naddr: building.normalized_address,
						current: building.current,
						primary: building.primary
					});
				}
			}
			let selected = options.find((e) => e.id === this.props.building.id);
			if (options.length > 1 && selected) {
				ret = (<CDropdown aria-label="Switch Home" key="crp-rh-switcher" style="report-home-switcher" title={selected.title} options={options} defaultValue={selected} onChange={async (val) => {
					this.setState({ loading: true });
					await Promise.all([
						this.props.dispatch(setCurrentBuilding(val.id)),
						this.props.dispatch(getBuildingRequest(val.id)),
						this.props.dispatch(getBuildingRequestLM(val.id))
					]);
					this.setState({ loading: false });
				}}></CDropdown>);
			}
			else {
				if (!selected) {
					selected = {
						title: this.props.building.address,
						id: this.props.building.id,
						naddr: this.props.building.normalized_address,
						current: true
					};
				}
				ret = (<div className="btmh-address"><img src="/images/small-house.svg"/> {selected.title}</div>);
			}
		}
		return ret;
	}

	render() {
		document.body.className = "customer-page " + this.theme.className;

		if (this.props.show && !this.props.currentUser?.primaryBuildingId) {
			window.location.href = "#/public/homeowner-map";
			return (<div/>);
		}

		if (!this.props.show) {
			return (<div/>)
		}
		else if (!this.props.currentUser) {
			window.location.href = "#/public/homeowner-map";
			return (<div/>)
		}

		if (this.props.show) {
			if (this.theme.hasAdvisor) {
				document.title = this.T('Dashboard') + ' - ' + this.T('Energy Navigator');
			}
			else {
				document.title = this.T('Dashboard') + ' - ' + this.T('Climative  Low Carbon Communities');
			}
		}

		if (!this.state.buildingInfoLoaded) {
			return <div className={"customer-dashboard-page " + this.theme.className}></div>;
		}

		const lang = `${this.T('hmp-lang-code')}`.toLowerCase();

		const lastPublishedDate = moment(this.props.lastPublished).format('MMM. D, YYYY - h:mm a');
		const lastUpdatedDate = moment(this.props.lastUpdated).format('MMM. D, YYYY - h:mm a');

		return (
			<div className="customer-dashboard-page">
				<div role="alert">
					<div className={'loading ' + (this.state.loading ? 'loading-show' : 'loading-hide')}>{this.state.loading && <p className="sr-only">{this.T("Loading")}</p>}<img alt="" src='/images/loading-transparent.gif'/>{this.state.loadingClaiming ? <div className="loading-claiming-msg">{this.T("This may take a minute, please wait...")}</div> : undefined}</div>
				</div>
				{this.renderModal("share-bills-modal", this.T("Share Utility Data"), () => this.shareBillsModal())}
				{this.renderModal("share-energuide-modal", this.T("Share EnerGuide Report"), () => this.shareEnerGuideModal())}
				{this.renderModal("validation-modal", this.T("Get Your Upgrades Validated"), () => this.validationModal())}
				{this.renderModal("select-so-modal", this.T("Video Home Energy Assessment Sign Up "), () => this.selectSOModal())}
				{this.renderFAQModal(0)}
				{/*this.renderFAQModal(1)*/}
				{/*this.renderFAQModal(2)*/}
				{/*this.renderFAQModal(3)*/}
				{/*this.renderFAQModal(4)*/}
				{/*this.renderFAQModal(5)*/}
				{this.props.building?.address ? <div className="cdb-non-footer">
					{this.theme.advisorExperience ? <div className="cdb-body">
						<h1 className="cdb-title-2">{this.T("Dashboard")}
						<CFaqButton ariaLabel={this.T('Dashboard FAQ')} id={'dashboard-faq-step-' + 0} onClick={this.modalOpenClick("faq-modal-" + 0)} />
						</h1>
						{this.renderHomeSwitcher()}
						{false && !statusGtEqual(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED) && <div className="cdb-view-report-block" aria-label={this.T("View Report")}>
							<div className="cdb-vrb-title">
								<img alt={this.T("")} src="/images/fact-check.svg" /> {this.props.modelType2 ? this.T("Energy Advice Report") : this.T("Energy Savings Potential Report")}
							</div>
							{this.props.modelType2 ? (
								<button className="cdb-vrb-button" onClick={() => (window.location.href = "#/app/customer/remote-audit")}>
									{this.T("View Report")}
								</button>
							) : (
								<button className="cdb-vrb-button" onClick={() => (window.location.href = "#/app/customer/view-report")}>
									{this.T("View Report")}
								</button>
							)}
							{this.props.lastPublished && <div className="cdb-last-published" ><Trans i18nKey={"report-updated-on"}>Updated: {{lastPublishedDate}}</Trans></div>}
						</div>}
						{false && statusGtEqual(this.props.status, STATUSES.POST_PREVIEW_PUBLISHED) && <div className="cdb-view-report-block" aria-label="View Report">
							<div className="cdb-vrb-title">
								<img alt="" src="/images/fact-check.svg" /> {this.T("Upgrade Validation Report")}
							</div>
							<button className="cdb-vrb-button" onClick={() => (window.location.href = "#/app/customer/remote-audit")}>
								{this.T("View Report")}
							</button>
							{this.props.lastPublished && <div className="cdb-last-published" ><Trans i18nKey={"report-updated-on"}>Updated: {{lastPublishedDate}}</Trans></div>}
						</div>}
						{this.renderStep(1, undefined, this.T("Set Up Profile"), this.step1InnerE1.bind(this), this.theme.disableBooking)}
						{this.renderStep(2, [STATUSES.PRE_AWAITING_SIGNUP, STATUSES.PRE_PREVIEW_REPORT], this.T("Home Energy Assessment - Plan Retrofits"), this.step2InnerE1.bind(this), this.theme.disableBooking)}
						{!this.theme.eaPreUpgradeOnly ? this.renderStep(3, [STATUSES.PRE_PREVIEW_PUBLISHED, STATUSES.UPG_UPGRADES_IN_PROGRESS], this.T("Perform Retrofits"), this.step3InnerE1.bind(this), false) : undefined}
						{!this.theme.eaPreUpgradeOnly ? this.renderStep(4, [STATUSES.POST_AWAITING_SIGNUP, STATUSES.POST_PREVIEW_REPORT], this.T("Home Energy Assessment - Validate Retrofits"), this.step4InnerE1.bind(this), this.theme.disableBooking) : undefined}
						{!this.theme.eaPreUpgradeOnly ? this.renderStep(5, [STATUSES.POST_PREVIEW_PUBLISHED, STATUSES.POST_PREVIEW_PUBLISHED], this.T("Receive Financial Incentives"), this.step5InnerE1.bind(this), false) : undefined}
					</div> : <div className="cdb-body">
						{!statusGtEqual(this.props.status, STATUSES.UPG_UPGRADES_COMPLETED) && <div className="cdb-view-report-block-generic" aria-label={this.T("View Report")}>
							<button className="cdb-vrb-report-link"  onClick={() => (window.location.href = this.props.modelType2 ? "#/app/customer/remote-audit" : "#/app/customer/view-report")}>
								<img alt={this.T("")} src="/images/fact-check-blue.svg" /> {this.props.modelType2 ? this.T("View Energy Advice Report") : this.T("View Energy Savings Potential Report")}
							</button>
							{this.props.lastUpdated && <div className="cdb-last-updated" ><Trans i18nKey={"report-updated-on-generic"}>Last updated {{lastUpdatedDate}}</Trans></div>}
						</div>}
						<h1 className="cdb-title-2">{this.T("Your Efficiency Roadmap")}</h1>
						{this.renderGenericStep(1, [STATUSES.VA_AUTOMATED_INSIGHTS, STATUSES.VA_AUTOMATED_INSIGHTS], this.T("Increase Accuracy of Energy Rating and Retrofit Guidance"), this.step1InnerGeneric.bind(this))}
						{this.renderGenericStep(2, [STATUSES.PLN_MAKING_RETROFIT_PLAN, STATUSES.PLN_MAKING_RETROFIT_PLAN], this.T("Make Actionable Retrofit Plan"), this.step2InnerGeneric.bind(this))}
						{this.renderGenericStep(3, [STATUSES.UGR_UPGRADES_VALIDATION, STATUSES.UGR_UPGRADES_VALIDATION], this.T("Building Upgrades and Update Energy Rating"), this.step3InnerGeneric.bind(this))}
					</div>}
				</div> : <div className="cdb-non-footer"><div className="cdb-body"></div></div>}
				<footer className="cdb-footer">
					{this.theme.isSimptek ? <br/> : undefined}
					<div className='cwp-fspace'/>{this.theme.footer.copyright[lang]}<br />{!this.theme.isSimptek ? <span><Trans i18nKey="simptek-copyright">Powered by <a href="https://www.climative.ai">Climative</a></Trans></span> : undefined}
				</footer>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	let building = state.buildings.curBuilding.building || {};
	let model = state.buildings.curModel || {};
	let model2 = model.LPUB;
	let status = { main: building.highestAuditStatus || 1, sub: building.highestAuditSubStatus || 0 };
	//let org_id = model2?.property?.organization_id ?? (model?.property?.organization_id ?? window.ORG_INFO.org_id);
	let org_id = window.ORG_INFO.org_id;
	return {
		status,
		modelType1: model,
		type1SurveySubmitted: !model.isAutomated,
		lastPublished: (THEME && THEME.advisorExperience) ? statusGtEqual(status, STATUSES.PRE_PREVIEW_PUBLISHED) ? model.lastPublished : (model.isAutomated ? null : model.time_stamp) : undefined,
		modelType2: (THEME && THEME.advisorExperience) ? (status.main >= STATUSES.POST_AWAITING_SIGNUP.main || statusEqual(STATUSES.PRE_PREVIEW_PUBLISHED, status) ? model2 : null) : undefined,
		building,
		currentUser: state.auth.currentUser,
		customerBuildings: state.auth.customerBuildings ?? null,
		lastUpdated: model.updatedAt,
		dataSources: model?.__report_info?.data_sources || {},
		org_id
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomerDashboard)));
