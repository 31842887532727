import moment from "moment";
import { getBuildingRequest, getBuildingRequestLM, downloadReportRequest } from "../../actions/buildings";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { acceptTermsAndConditions} from "../../actions/auth";
import { logoutCustomer  } from "../../actions/auth";
import axios from "axios";

import { withTranslation, Trans } from "react-i18next";

import "./CustomerTerms.scss";
import Themes from "./Themes";
import "./Themes.scss";
import i18next from 'i18next';

const { onBlurOutside } = require("../../helpers/utils");

/////

const CUSTOMER_TERMS_HTML = `
<style type="text/css">
    .awlist1 {
        list-style: none;
        counter-reset: awlistcounter22_1
    }

    .awlist1>li:before {
        content: '('counter(awlistcounter22_1, lower-latin) ')';
        counter-increment: awlistcounter22_1
    }

    .awlist2 {
        list-style: none;
        counter-reset: awlistcounter23_1
    }

    .awlist2>li:before {
        content: '('counter(awlistcounter23_1, lower-latin) ')';
        counter-increment: awlistcounter23_1
    }

    .awlist3 {
        list-style: none;
        counter-reset: awlistcounter22_1 1
    }

    .awlist3>li:before {
        content: '('counter(awlistcounter22_1, lower-latin) ')';
        counter-increment: awlistcounter22_1
    }
</style>
<div>
    <p style="margin-top:0pt; margin-bottom:12pt; text-align:center; font-size:16pt;"><a name="a1051406"><strong><span style="font-family:Calibri;">Climative Terms and Conditions of Use</span></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a891809"><strong><u><span style="font-family:Calibri;">Acceptance of the Website Terms and Conditions of Use</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051407"><span style="font-family:Calibri;">These website terms and conditions of use for climative.ai constitute a legal agreement and are entered into by and between you and Climative (&quot;</span><strong><span style="font-family:Calibri;">Company</span></strong><span style="font-family:Calibri;">,&quot; &quot;</span><strong><span style="font-family:Calibri;">we</span></strong><span style="font-family:Calibri;">,&quot; &quot;</span><strong><span style="font-family:Calibri;">us</span></strong><span style="font-family:Calibri;">,&quot; &quot;</span><strong><span style="font-family:Calibri;">our</span></strong><span style="font-family:Calibri;">&quot;). The following terms and conditions (these &quot;</span><strong><span style="font-family:Calibri;">Terms and Conditions</span></strong><span style="font-family:Calibri;">&quot;), govern your access to and use of, including any content, functionality, and services offered on or through climative.ai (the &quot;</span><strong><span style="font-family:Calibri;">Website</span></strong><span style="font-family:Calibri;">&quot;).</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051408"><strong><span style="font-family:Calibri;">BY USING THE WEBSITE OR BY CLICKING TO ACCEPT THE TERMS AND CONDITIONS, YOU ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THESE TERMS AND CONDITIONS AND OUR PRIVACY POLICY, FOUND AT CLIMATIVE.AI, INCORPORATED HEREIN BY REFERENCE. IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS OR THE PRIVACY POLICY, YOU MUST NOT ACCESS OR USE THE WEBSITE.</span></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051409"><span style="font-family:Calibri;">By using this Website, you represent and warrant that you are of the legal age of majority under applicable law to form a binding contract with Climative and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.</span></a></p>
    <p style="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><strong><span style="font-family:Calibri;">For more information about your data and how Climative uses it (including, Who Can See My Home Rating and Home Energy Report? Where Does Climative Find Information About My Home? How can I Hide my Data? What user authentication does Climative use?</span></strong><strong><span style="font-family:Calibri;">&nbsp;&nbsp;</span></strong><strong><span style="font-family:Calibri;">and What types of data are kept on the Climative platform?), please see</span></strong><span style="font-family:Calibri;">&nbsp;</span><strong><span style="font-family:Calibri;">the &ldquo;About my Data&rdquo; article on our knowledge base at Climative.ai</span></strong></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a456309"><strong><u><span style="font-family:Calibri;">Modifications to the Terms and Conditions and to the Website&nbsp;</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051480"><span style="font-family:Calibri;">We reserve the right in our sole discretion to revise and update these terms and conditions from time to time. Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Website. You agree to periodically review the terms and conditions in order to be aware of any such modifications and your continued use shall be your acceptance of these.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051484"><span style="font-family:Calibri;">The information and material on this Website may be changed, withdrawn, or terminated at any time in our sole discretion without notice. We will not be liable if, for any reason, all or any part of the Website is restricted to users or unavailable at any time or for any period.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1004439"><strong><u><span style="font-family:Calibri;">Your Use of the Website and Account Set-Up and Security</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051413"><span style="font-family:Calibri;">The security of your personal information is very important to us. We use physical, electronic, and administrative measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a292411"><span style="font-family:Calibri;">The safety and security of your information also depends on you. Users are responsible for obtaining their own access to the Website. Users are required to ensure that all persons who access the Website through a user&apos;s internet connection are aware of these Terms and Conditions and comply with them. The Website, including content or areas of the Website, may require user registration. It is a condition of your use of the Website that all the information you provide on the Website is correct, current, and complete.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a611263"><span style="font-family:Calibri;">Unfortunately, the transmission of information via the Internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051511"><span style="font-family:Calibri;">Your provision of registration information and any submissions you make to the Website through any functionality (collectively, &quot;</span><strong><span style="font-family:Calibri;">Interactive Functions</span></strong><span style="font-family:Calibri;">&quot;) constitutes your consent to all actions we take with respect to such information consistent with our Privacy Policy, found at Climative.ai</span></a><span style="font-family:Calibri;">.</span></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051415"><span style="font-family:Calibri;">Any username, password, or any other piece of information chosen by you, or provided to you as part of our security procedures, must be treated as confidential, and you must not disclose it to any other person or entity. You must exercise caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information. You understand and agree that should you be provided an account, your account is personal to you and you agree not to provide any other person with access to this Website or portions of it using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you logout from your account at the end of each session. You are responsible for any password misuse or any unauthorized access.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051416"><span style="font-family:Calibri;">We reserve the right at any time and from time to time, to disable or terminate your account, any username, password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no reason, including any violation of any provision of these Terms and Conditions.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051485"><span style="font-family:Calibri;">You are prohibited from attempting to circumvent and from violating the security of this Website, including, without limitation: (a) accessing content and data that is not intended for you; (b) attempting to breach or breaching the security and/or authentication measures which are not authorized; (c) restricting, disrupting, or disabling service to users, hosts, servers, or networks; (d) illicitly reproducing TCP/IP packet header; (e) disrupting network services and otherwise disrupting Website owner&apos;s ability to monitor the Website; (f) using any robot, spider, or other automatic device, process, or means to access the Website for any purpose, including monitoring or copying any of the material on the Website; (g) introducing any viruses, trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful; (h) attacking the Website via a denial-of-service attack, distributed denial-of-service attack, flooding, mailbombing, or crashing; and (i) otherwise attempting to interfere with the proper working of the Website.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051401"><strong><u><span style="font-family:Calibri;">Intellectual Property Rights and Ownership</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051417"><span style="font-family:Calibri;">You understand and agree that the Website and its entire contents, features, and functionality, including, but not limited to, all information, software, code, data text, displays, graphics, photographs, images, video, audio, music, broadcast, design, presentation, website layout, selection, and arrangement, are owned by the Company, its licensors, or other providers of such material and are protected in all forms by intellectual property laws including, without limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051487"><span style="font-family:Calibri;">Climative and all related names, logos, product and service names, designs, images, and slogans are trademarks of Climative or its affiliates or licensors. You must not use such marks without the prior written permission of the Company. Other names, logos, product and service names, designs, images, and slogans mentioned, or which appear on this Website are the trademarks of their respective owners. Use of any such property, except as expressly authorized, shall constitute an infringement or violation of the rights of the property owner and may be a violation of federal or other laws and could subject the infringer to legal action.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051418"><span style="font-family:Calibri;">You may only use the Website for your personal and non-commercial use. You shall not directly or indirectly reproduce, compile for an internal database, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, in any form or medium whatsoever except:&nbsp;</span></a></p>
    <ol type="a" class="awlist1" style="margin:0pt; padding-left:0pt;">
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.97pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a667288">your computer and browser may temporarily store or cache copies of materials being accessed and viewed;</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.42pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a508401">a reasonable number of copies for personal use only may be printed keeping any proprietary notices thereon, which may only be used for non-commercial and lawful personal use and not for further reproduction, publication, or distribution of any kind on any medium whatsoever;/and</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:23.65pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a625522">one single user copy may be downloaded with any proprietary notices intact, for your own personal, non-commercial use, conditional on your agreement to be bound by our end user licence agreement for such downloads</a>.</li>
    </ol>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051419"><span style="font-family:Calibri;">Users are not</span><strong><span style="font-family:Calibri;">&nbsp;</span></strong><span style="font-family:Calibri;">permitted to modify copies of any materials from this Website nor delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site. You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051422"><span style="font-family:Calibri;">If you print off, copy, or download any part of our Website in breach of these Terms and Conditions, your right to use the Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You have no right, title, or interest in or to the Website or to any content on the Website, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may infringe or violate copyright, trademark, and other intellectual property or other proprietary laws.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051488"><strong><u><span style="font-family:Calibri;">Conditions of Use and User Submissions and Site Content Standards</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051424"><span style="font-family:Calibri;">As a condition of your access and use of the Website, you agree that you may use the Website only for lawful purposes and in accordance with these Terms and Conditions.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051516"><span style="font-family:Calibri;">The following site content standards apply to any and all content, material, and information a user submits, posts, publishes, displays, or transmits (collectively, &quot;</span><strong><span style="font-family:Calibri;">submit</span></strong><span style="font-family:Calibri;">&quot;) to the website or to other users or persons (collectively, &quot;</span><strong><span style="font-family:Calibri;">User Submissions</span></strong><span style="font-family:Calibri;">&quot;) and any and all Interactive Functions. Any and all User Submissions must also comply with all applicable federal, provincial, local, and international laws, regulations, and terms of service.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051489"><span style="font-family:Calibri;">Without limiting the foregoing, you warrant and agree that your use of the Website and any User Submissions shall not:</span></a></p>
    <ol type="a" class="awlist2" style="margin:0pt; padding-left:0pt;">
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.97pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051517">In any manner violate any applicable federal, provincial, local, or international law or regulation including, without limitation, any laws regarding the export of data or software, patent, trademark, trade secret, copyright, or other intellectual property, legal rights (including the rights of publicity and privacy of others) or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these Terms and Conditions and our Privacy Policy found&nbsp;</a>at Climative.ai.</li>
    </ol>
    <ol start="2" type="a" class="awlist3" style="margin:0pt; padding-left:0pt;">
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.42pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a459036">In any manner violate the terms of use of any third-party website that is linked to the Website, including but not limited to, any third-party social media website.</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:23.65pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051518">Include or contain any material that is exploitive, obscene, harmful, threatening, abusive, harassing, hateful, defamatory, sexually explicit or pornographic, violent, inflammatory, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age or other such legally prohibited ground or be otherwise objectionable, such determination to be made in Company&apos;s sole discretion.</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.42pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051519">Involve stalking, attempting to exploit or harm any individual (including minors) in any way by exposing them to inappropriate content or otherwise or ask for personal information as prohibited under applicable laws, regulations, or code.</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.75pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051520">Involve, provide, or contribute any false, inaccurate, or misleading information.&nbsp;</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:25.06pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051521">Include sending, knowingly receiving, uploading, downloading, using, or reusing any material that does not comply with the Conditions of Use and User Submissions and Site Content Standards.&nbsp;</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:23.07pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a112516">Impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.42pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051523">Transmit, or procure the sending of, any advertisements or promotions without our prior written consent, sales, or encourage any other commercial activities, including, without limitation, any &quot;spam&quot;, &quot;junk mail&quot;, &quot;chain letter&quot;, contests, sweepstakes and other sales promotions, barter, or advertising or any other similar solicitation.&nbsp;</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:25.97pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051524">Encourage any other conduct that restricts or inhibits anyone&apos;s use or enjoyment of the Website, or which, as determined by us, may harm Climative or users of the Website or expose them to liability.</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:25.85pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051525">Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, or alarm any other person.</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:23.27pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051526">Promote any illegal activity, or advocate, promote, or assist any unlawful act.</a></li>
        <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:25.97pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051527">Give the impression that they originate from or are endorsed by us or any other person or entity, if this is not the case.</a></li>
    </ol>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a583166"><strong><u><span style="font-family:Calibri;">User Submissions: Grant of Licence</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051427"><span style="font-family:Calibri;">The Website may contain Interactive Functions allowing User Submissions on or through the Website.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051490"><span style="font-family:Calibri;">None of the User Submissions you submit to the Website will be subject to any confidentiality by the Company. By providing any User Submission to the Website, you grant us and our licensees, successors, and assigns the right to a world-wide, royalty free, perpetual, irrevocable, non-exclusive licence to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose and according to your account settings and/or incorporate such material into any form, medium or technology throughout the world without compensation to you. You further waive any moral rights or other rights of authorship as a condition of submitting any User Submission.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051430"><span style="font-family:Calibri;">By submitting the User Submissions, you declare and warrant that you own or have the necessary rights to submit the User Submissions and have the right to grant the licence hereof to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns to the User Submissions and comply with these Terms and Conditions. You represent and warrant that all User Submissions comply with applicable laws and regulations and the Conditions of Use and User Submissions and Site Content Standards set out in these Terms and Conditions.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051431"><span style="font-family:Calibri;">You understand and agree that you, not Climative, are fully responsible for any User Submissions you submit or contribute, and you are fully responsible and legally liable, including to any third party, for such content and its accuracy. We are not responsible or legally liable to any third party for the content or accuracy of any User Submissions submitted by you or any other user of the Website.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a454764"><strong><u><span style="font-family:Calibri;">Site Monitoring and Enforcement, Suspension, and Termination</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051433"><span style="font-family:Calibri;">Company has the right, without provision of notice to:</span></a></p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:6pt; text-indent:-18pt; text-align:left; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:Calibri;">Remove or refuse to post on the Website any User Submissions for any or no reason in our sole discretion.</span></p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:6pt; text-indent:-18pt; text-align:left; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:Calibri;">At all times, take such actions with respect to any User Submission deemed necessary or appropriate in our sole discretion, including, without limitation, for violating these Terms and Conditions.</span></p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:6pt; text-indent:-18pt; text-align:left; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:Calibri;">Take appropriate legal action, including, without limitation, referral to law enforcement or regulatory authority, or notifying the harmed party of any illegal or unauthorized use of the Website. Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Website.</span></p>
    <p style="margin-top:0pt; margin-left:36pt; margin-bottom:6pt; text-indent:-18pt; text-align:left; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:Calibri;">Terminate or suspend your access to all or part of the Website for any or no reason, including, without limitation, any violation of these Terms and Conditions.</span></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051434"><span style="font-family:Calibri;">YOU WAIVE AND HOLD HARMLESS CLIMATIVE FROM ANY AND ALL CLAIMS RESULTING FROM ANY ACTION TAKEN BY CLIMATIVE RELATING TO ANY, INVESTIGATIONS BY EITHER CLIMATIVE OR BY LAW ENFORCEMENT AUTHORITIES.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051435"><span style="font-family:Calibri;">We have no obligation, nor any responsibility to any party to monitor the Website or its use, and do not and cannot undertake to review material that you or other users submit to the Website. We cannot ensure prompt removal of objectionable material after it has been posted and we have no liability for any action or inaction regarding transmissions, communications, or content provided by any user or third party, subject to applicable laws.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051491"><strong><u><span style="font-family:Calibri;">No Reliance&nbsp;</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051438"><span style="font-family:Calibri;">The content on our Website is provided for general information purposes only. It is not intended to amount to advice on which you should rely. You must obtain more specific or professional advice before taking, or refraining from, any action or inaction on the basis of the content on our site.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051492"><span style="font-family:Calibri;">Although we make reasonable efforts to update the information on our Website, we make no representations, warranties, or guarantees, whether express or implied, that the content on our Website is accurate, complete, or up to date. Your use of the Website is at your own risk and Climative has no responsibility or liability whatsoever for your use of this Website.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051493"><span style="font-family:Calibri;">This Website may include content provided by third parties, including from other users and third-party licensors. All statements and/or opinions expressed in any such third-party content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. Such materials do not necessarily reflect the opinion of the Company. Climative has no responsibility or liability whatsoever to you, or any third party, for the content or accuracy of any third-party materials.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a212305"><strong><u><span style="font-family:Calibri;">Privacy</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051441"><span style="font-family:Calibri;">By submitting your personal information and using our Website, you consent to the collection, use, reproduction, hosting, transmission, and disclosure of any such user content submissions in compliance with our Privacy Policy, found at Climative.ai as we deem necessary for use of the Website and provision of services.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a452006"><strong><u><span style="font-family:Calibri;">Third-Party Websites&nbsp;</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051495"><span style="font-family:Calibri;">For your convenience, this Website may provide links or pointers to third-party sites. We make no representations about any other websites that may be accessed from this Website. If you choose to access any such sites, you do so at your own risk. We have no control over the contents of any such third-party sites and accept no responsibility for such sites or for any loss or damage that may arise from your use of them. You are subject to any terms and conditions of such third-party sites.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051530"><strong><u><span style="font-family:Calibri;">Geographic Restrictions</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051451"><span style="font-family:Calibri;">We provide this Website for use only by persons located in Canada. This Website is not intended for use in any jurisdiction where its use is not permitted. If you access the Website from outside Canada, you do so at your own risk and you are responsible for compliance with local laws of your jurisdiction.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a107208"><strong><u><span style="font-family:Calibri;">Disclaimer of Warranties</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051452"><span style="font-family:Calibri;">YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051497"><span style="font-family:Calibri;">NEITHER CLIMATIVENOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE ANY WARRANTY, REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR AVAILABILITY OF THE WEBSITE OR ITS CONTENTS. WITHOUT LIMITING THE FOREGOING, NEITHER CLIMATIVENOR ITS PARENT, SUBSIDIARIES, AFFILIATES OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS REPRESENT OR WARRANT THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051498"><span style="font-family:Calibri;">WE CANNOT AND DO NOT GUARANTEE OR WARRANT THAT FILES OR DATA AVAILABLE FOR DOWNLOADING FROM THE INTERNET OR THE WEBSITE WILL BE FREE OF VIRUSES OR OTHER DESTRUCTIVE CODE. YOU ARE SOLELY AND ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER, INTERNET, AND DATA SECURITY. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY DENIAL-OF-SERVICE ATTACK, DISTRIBUTED DENIAL-OF-SERVICE ATTACK, OVERLOADING, FLOODING, MAILBOMBING, OR CRASHING, VIRUSES, TROJAN HORSES, WORMS, LOGIC BOMBS, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a136928"><strong><u><span style="font-family:Calibri;">Limitation on Liability</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051457"><span style="font-family:Calibri;">EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, UNDER NO CIRCUMSTANCE WILL CLIMATIVENOR ITS PARENT, SUBSIDIARIES, AFFILIATES, OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION, FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING, BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT, BREACH OF CONTRACT, BREACH OF PRIVACY, OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, OR RELIANCE ON, THE WEBSITE, ANY LINKED WEBSITES OR SUCH OTHER THIRD-PARTY WEBSITES, NOR ANY WEBSITE CONTENT, MATERIALS, POSTING, OR INFORMATION THEREON.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051459"><strong><u><span style="font-family:Calibri;">Indemnification</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051460"><span style="font-family:Calibri;">To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the Company, its parent, subsidiaries, affiliates, and their respective directors, officers, employees, agents, service providers, contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable legal fees) arising out of or relating to your breach of these Terms and Conditions or your use of the Website, including, but not limited to, your User Submissions, third-party sites, any use of the Website&apos;s content, services, and products other than as expressly authorized in these Terms and Conditions.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a699054"><strong><u><span style="font-family:Calibri;">Governing Law and Choice of Forum</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051462"><span style="font-family:Calibri;">The Website and these Terms and Conditions will be governed by and construed in accordance with the laws of the Province of New Brunswick and the federal laws of Canada applicable therein, without giving effect to any choice or conflict of law provision, principle, or rule (whether of the laws of the Province of Ontario or any other jurisdiction) and notwithstanding your domicile, residence, or physical location.</span><span style="font-family:Calibri;">&nbsp;&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051499"><span style="font-family:Calibri;">Any action or proceeding arising out of or relating to this Website and under these Terms and Conditions will be instituted in the courts of the Province of Ontario and/or the Federal Court of Canada, and each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue of such courts.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051531"><strong><u><span style="font-family:Calibri;">Waiver&nbsp;</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a406642"><span style="font-family:Calibri;">No failure to exercise, or delay in exercising, any right, remedy, power, or privilege arising from these Terms and Conditions operates, or may be construed, as a waiver thereof; and no single or partial exercise of any right, remedy, power, or privilege hereunder precludes any other or further exercise thereof or the exercise of any other right, remedy, power, or privilege.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051507"><strong><u><span style="font-family:Calibri;">Severability</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051508"><span style="font-family:Calibri;">If any term or provision of these Terms and Conditions is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of these Terms and Conditions or invalidate or render unenforceable such term or provision in any other jurisdiction.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a361445"><strong><u><span style="font-family:Calibri;">Entire Agreement</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051469"><span style="font-family:Calibri;">The Terms and Conditions and our Privacy Policy constitute the sole and entire agreement between you and Climative regarding the Website and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding such subject matter.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051470"><strong><u><span style="font-family:Calibri;">Reporting and Contact</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051471"><span style="font-family:Calibri;">This website is operated by Climative.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051510"><span style="font-family:Calibri;">Should you become aware of misuse of the website including libelous or defamatory conduct, you must report it to Climative at info@climative.ai.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051473"><span style="font-family:Calibri;">All other feedback, comments, requests for technical support, and other communications relating to the Website should be directed to info@climative.ai.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><span style="font-family:Calibri;">2024-07-25</span></p>
</div>
`;

const CUSTOMER_TERMS_HTML_FR = `
<style type="text/css">
    .awlist1 {
        list-style: none;
        counter-reset: awlistcounter22_1
    }
    .awlist1>li:before {
        content: '('counter(awlistcounter22_1, lower-latin) ')';
        counter-increment: awlistcounter22_1
    }
    .awlist2 {
        list-style: none;
        counter-reset: awlistcounter23_1
    }
    .awlist2>li:before {
        content: '('counter(awlistcounter23_1, lower-latin) ')';
        counter-increment: awlistcounter23_1
    }
    .awlist3 {
        list-style: none;
        counter-reset: awlistcounter22_1 1
    }
    .awlist3>li:before {
        content: '('counter(awlistcounter22_1, lower-latin) ')';
        counter-increment: awlistcounter22_1
    }
</style>
<div>
    <p style="margin-top:0pt; margin-bottom:12pt; text-align:center; font-size:16pt;"><a name="a1051406"><strong><span style="font-family:Calibri;">Conditions générales d'utilisation de Climative</span></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a891809"><strong><u><span style="font-family:Calibri;">Acceptation des conditions générales d'utilisation du site Web</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051407"><span style="font-family:Calibri;">Ces conditions générales d'utilisation du site Web pour climative.ai constituent un accord légal entre vous et Climative (&quot;</span><strong><span style="font-family:Calibri;">Société</span></strong><span style="font-family:Calibri;">,&quot; &quot;</span><strong><span style="font-family:Calibri;">nous</span></strong><span style="font-family:Calibri;">,&quot; &quot;</span><strong><span style="font-family:Calibri;">notre</span></strong><span style="font-family:Calibri;">&quot;). Les termes suivants (ces &quot;</span><strong><span style="font-family:Calibri;">Conditions générales</span></strong><span style="font-family:Calibri;">&quot;), régissent votre accès et votre utilisation, y compris tout contenu, fonctionnalité et services offerts sur ou via climative.ai (le &quot;</span><strong><span style="font-family:Calibri;">Site Web</span></strong><span style="font-family:Calibri;">&quot;).</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051408"><strong><span style="font-family:Calibri;">EN UTILISANT LE SITE WEB OU EN CLIQUANT POUR ACCEPTER LES CONDITIONS GÉNÉRALES, VOUS ACCEPTEZ ET CONSENTEZ À ÊTRE LIÉ PAR CES CONDITIONS ET À VOUS CONFORMER À NOTRE POLITIQUE DE CONFIDENTIALITÉ, DISPONIBLE SUR CLIMATIVE.AI, INTÉGRÉE PAR RÉFÉRENCE AUX PRÉSENTES. SI VOUS N'ACCEPTEZ PAS CES CONDITIONS OU LA POLITIQUE DE CONFIDENTIALITÉ, VOUS NE DEVEZ PAS ACCÉDER AU SITE NI L'UTILISER.</span></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051409"><span style="font-family:Calibri;">En utilisant ce site Web, vous déclarez et garantissez que vous avez l'âge légal de la majorité en vertu de la loi applicable pour conclure un contrat contraignant avec Climative et que vous remplissez toutes les conditions d'éligibilité susmentionnées. Si vous ne remplissez pas toutes ces conditions, vous ne devez pas accéder au site ni l'utiliser.</span></a></p>
    <p style="margin-top:0pt; margin-bottom:0pt; font-size:12pt;"><strong><span style="font-family:Calibri;">Pour plus d'informations sur vos données et la manière dont Climative les utilise (y compris, Qui peut voir mon évaluation de domicile et mon rapport énergétique ? Où Climative trouve-t-il des informations sur ma maison ? Comment puis-je cacher mes données ? Quelle authentification utilisateur Climative utilise-t-il ?</span></strong><strong><span style="font-family:Calibri;">&nbsp;&nbsp;</span></strong><strong><span style="font-family:Calibri;">et Quels types de données sont conservés sur la plateforme Climative ?), veuillez consulter</span></strong><span style="font-family:Calibri;">&nbsp;</span><strong><span style="font-family:Calibri;">l'article &ldquo;À propos de mes données&rdquo; dans notre base de connaissances sur Climative.ai</span></strong></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a456309"><strong><u><span style="font-family:Calibri;">Modifications des conditions générales et du site Web&nbsp;</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051480"><span style="font-family:Calibri;">Nous nous réservons le droit, à notre seule discrétion, de réviser et de mettre à jour ces conditions générales de temps à autre. Toutes ces modifications prennent effet immédiatement après leur publication et s'appliquent à tout accès et utilisation continue du site Web. Vous acceptez de consulter périodiquement les conditions générales afin d'être informé de toute modification et votre utilisation continue sera considérée comme une acceptation de celles-ci.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051484"><span style="font-family:Calibri;">Les informations et le contenu de ce site Web peuvent être modifiés, retirés ou supprimés à tout moment, à notre seule discrétion, sans préavis. Nous ne serons pas responsables si, pour quelque raison que ce soit, tout ou partie du site Web est restreint ou indisponible à tout moment ou pendant une période donnée.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1004439"><strong><u><span style="font-family:Calibri;">Votre utilisation du site Web et création de compte et sécurité</span></u></strong></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051413"><span style="font-family:Calibri;">La sécurité de vos informations personnelles est très importante pour nous. Nous utilisons des mesures physiques, électroniques et administratives conçues pour sécuriser vos informations personnelles contre les pertes accidentelles ainsi que contre l'accès, l'utilisation, la modification et la divulgation non autorisés.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a292411"><span style="font-family:Calibri;">La sécurité de vos informations dépend également de vous. Les utilisateurs sont responsables de leur propre accès au site Web. Ils doivent également s'assurer que toutes les personnes accédant au site via leur connexion Internet sont informées et respectent ces conditions générales. L'accès à certaines parties ou fonctionnalités du site peut nécessiter une inscription. L'utilisation du site suppose que toutes les informations fournies sont exactes, actuelles et complètes.&nbsp;</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a611263"><span style="font-family:Calibri;">Malheureusement, la transmission d'informations sur Internet n'est pas totalement sécurisée. Bien que nous fassions de notre mieux pour protéger vos informations personnelles, nous ne pouvons garantir leur sécurité lorsqu'elles sont transmises à notre site. Toute transmission est à vos risques et périls. Nous ne sommes pas responsables des contournements des paramètres de confidentialité ou des mesures de sécurité sur le site.</span></a></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051511"><span style="font-family:Calibri;">Les informations d'inscription et tout contenu que vous soumettez au site via des fonctionnalités (collectivement, &quot;</span><strong><span style="font-family:Calibri;">Fonctions interactives</span></strong><span style="font-family:Calibri;">&quot;) constituent votre consentement aux actions que nous entreprenons conformément à notre politique de confidentialité, disponible sur Climative.ai</span></a><span style="font-family:Calibri;">.</span></p>
    <p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051415"><span style="font-family:Calibri;">Tout nom d'utilisateur, mot de passe ou toute autre information fournie dans le cadre de notre processus de sécurité doit être traité comme confidentiel et ne doit pas être divulgué à une autre personne ou entité. Votre compte est personnel. Vous acceptez de ne pas fournir à une autre personne l'accès au site Web ou à des parties de celui-ci en utilisant votre nom d'utilisateur, mot de passe ou autres informations de sécurité. Vous acceptez de nous informer immédiatement de tout accès non autorisé ou de toute utilisation de votre nom d'utilisateur ou mot de passe ou de toute autre violation de la sécurité. Vous acceptez également de vous assurer que vous quittez votre compte à la fin de chaque session. Nous vous encourageons à être particulièrement vigilant lorsque vous accédez à votre compte à partir d'un ordinateur public ou partagé pour que d'autres ne puissent pas visualiser ou enregistrer votre mot de passe ou d'autres informations personnelles.&nbsp;</span></a></p>
</div>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051401"><strong><u><span style="font-family:Calibri;">Droits de propriété intellectuelle et propriété</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051401"><strong><u><span style="font-family:Calibri;">Droits de propriété intellectuelle et propriété</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051417"><span style="font-family:Calibri;">Vous comprenez et acceptez que le site Web et son contenu, ses fonctionnalités et ses fonctionnalités, y compris, mais sans s'y limiter, toutes les informations, logiciels, codes, données, textes, affichages, graphiques, photographies, images, vidéos, audios, musiques, diffusions, conception, présentation, mise en page du site Web, sélection et arrangement, sont la propriété de la société, de ses concédants de licence ou d'autres fournisseurs de ce matériel et sont protégés sous toutes les formes par les lois sur la propriété intellectuelle, y compris, sans limitation, le droit d'auteur, la marque, le brevet, le secret commercial et tout autre droit de propriété.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051487"><span style="font-family:Calibri;">Climative et tous les noms, logos, noms de produits et de services, conceptions, images et slogans associés sont des marques déposées de Climative ou de ses affiliés ou concédants de licence. Vous ne devez pas utiliser ces marques sans l'autorisation écrite préalable de la société. D'autres noms, logos, noms de produits et services, conceptions, images et slogans mentionnés ou apparaissant sur ce site Web sont les marques déposées de leurs propriétaires respectifs. L'utilisation de toute propriété, sauf si expressément autorisée, constitue une violation des droits du propriétaire et peut enfreindre les lois fédérales ou autres et soumettre l'infracteur à des actions légales.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051418"><span style="font-family:Calibri;">Vous ne pouvez utiliser le site Web que pour votre usage personnel et non commercial. Vous ne devez pas reproduire, compiler pour une base de données interne, distribuer, modifier, créer des œuvres dérivées, afficher publiquement, exécuter publiquement, republier, télécharger, stocker ou transmettre tout matériel sur notre site Web, sous quelque forme ou support que ce soit, sauf :</span></a></p>
<ol type="a" class="awlist1" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.97pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a667288">votre ordinateur et votre navigateur peuvent temporairement stocker ou mettre en cache des copies des matériaux consultés et visualisés ;</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.42pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a508401">un nombre raisonnable de copies pour un usage personnel uniquement peut être imprimé en conservant les avis de propriété, qui ne peuvent être utilisés que pour un usage personnel non commercial et légal et non pour une reproduction, publication ou distribution ultérieure sous quelque forme que ce soit / et</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:23.65pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a625522">une seule copie utilisateur peut être téléchargée avec les avis de propriété intacts, pour votre usage personnel et non commercial, sous réserve de votre accord pour être lié par notre contrat de licence utilisateur final pour de tels téléchargements.</a></li>
</ol>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051419"><span style="font-family:Calibri;">Les utilisateurs ne sont pas</span><strong><span style="font-family:Calibri;">&nbsp;</span></strong><span style="font-family:Calibri;">autorisés à modifier des copies de tout matériel provenant de ce site Web ni à supprimer ou altérer les avis de droit d'auteur, de marque ou autres avis de droits de propriété sur les copies de matériel de ce site. Vous ne devez pas accéder ou utiliser à des fins commerciales toute partie du site Web ou tout service ou matériel disponible via le site Web.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051422"><span style="font-family:Calibri;">Si vous imprimez, copiez ou téléchargez une partie de notre site Web en violation de ces termes et conditions, votre droit d'utiliser le site Web cessera immédiatement et vous devrez, à notre choix, retourner ou détruire toutes les copies des matériaux que vous avez faites. Vous n'avez aucun droit, titre ou intérêt sur ou dans le site Web ou sur tout contenu du site, et tous les droits non expressément accordés sont réservés par la société. Toute utilisation du site Web non expressément autorisée par ces termes et conditions constitue une violation de ces termes et conditions et peut enfreindre ou violer les droits d'auteur, les marques déposées et d'autres lois sur la propriété intellectuelle ou d'autres lois de propriété.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051488"><strong><u><span style="font-family:Calibri;">Conditions d'utilisation et soumissions des utilisateurs et normes de contenu du site</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051424"><span style="font-family:Calibri;">Comme condition d'accès et d'utilisation du site Web, vous acceptez que vous ne puissiez utiliser le site Web que pour des fins légales et conformément à ces termes et conditions.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051516"><span style="font-family:Calibri;">Les normes suivantes de contenu du site s'appliquent à tout contenu, matériel et information qu'un utilisateur soumet, publie, affiche ou transmet (collectivement, &quot;</span><strong><span style="font-family:Calibri;">soumettre</span></strong><span style="font-family:Calibri;">&quot;) sur le site ou à d'autres utilisateurs ou personnes (collectivement, &quot;</span><strong><span style="font-family:Calibri;">Soumissions des utilisateurs</span></strong><span style="font-family:Calibri;">&quot;) et à toutes les fonctions interactives. Toutes les soumissions des utilisateurs doivent également respecter toutes les lois, règlements et termes de service fédéraux, provinciaux, locaux et internationaux applicables.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051489"><span style="font-family:Calibri;">Sans limiter ce qui précède, vous garantissez et acceptez que votre utilisation du site Web et toute soumission des utilisateurs ne doit pas :</span></a></p>
<ol type="a" class="awlist2" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.97pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051517">Violer de quelque manière que ce soit une loi ou un règlement fédéral, provincial, local ou international applicable, y compris, mais sans s'y limiter, toute loi concernant l'exportation de données ou de logiciels, les brevets, les marques, les secrets commerciaux, le droit d'auteur ou d'autres droits de propriété intellectuelle, les droits légaux (y compris les droits de publicité et de confidentialité des autres) ou contenir tout matériel susceptible de donner lieu à une responsabilité civile ou pénale en vertu des lois ou règlements applicables ou
</ol>
<ol start="2" type="a" class="awlist3" style="margin:0pt; padding-left:0pt;">
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.42pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a459036">De toute manière enfreindre les conditions d'utilisation de tout site tiers lié au site Web, y compris mais sans s'y limiter, tout site de médias sociaux tiers.</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:23.65pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051518">Inclure ou contenir tout matériel qui est exploiteur, obscène, nuisible, menaçant, abusif, harcelant, haineux, diffamatoire, sexuellement explicite ou pornographique, violent, inflammatoire, ou discriminatoire basé sur la race, le sexe, la religion, la nationalité, le handicap, l'orientation sexuelle, ou l'âge ou toute autre base légalement interdite, ou être autrement répréhensible, cette détermination devant être faite à la seule discrétion de la société.</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.42pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051519">Impliquer du harcèlement, tenter d'exploiter ou de nuire à toute personne (y compris les mineurs) de quelque manière que ce soit en les exposant à un contenu inapproprié ou autrement ou demander des informations personnelles comme interdit par les lois, réglementations ou codes applicables.</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.75pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051520">Impliquer, fournir ou contribuer des informations fausses, inexactes ou trompeuses.&nbsp;</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:25.06pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051521">Inclure l'envoi, la réception consciente, le téléchargement, l'utilisation ou la réutilisation de tout matériel qui ne respecte pas les Conditions d'Utilisation et les Normes des Soumissions Utilisateurs et du Contenu du Site.&nbsp;</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:23.07pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a112516">Usurper l'identité ou tenter d'usurper l'identité de la société, d'un employé de la société, d'un autre utilisateur ou de toute autre personne ou entité (y compris, sans limitation, en utilisant des adresses email ou des noms d'utilisateur associés à l'un des précédents).</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:22.42pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051523">Transmettre, ou procurer l'envoi de, toute publicité ou promotion sans notre consentement écrit préalable, ventes, ou encourager toute autre activité commerciale, y compris, sans limitation, tout "spam", "courrier indésirable", "chaîne de lettres", concours, tirages au sort et autres promotions de ventes, troc, ou publicité ou toute autre sollicitation similaire.&nbsp;</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:25.97pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051524">Encourager toute autre conduite qui restreint ou empêche l'utilisation ou la jouissance du site Web par quiconque, ou qui, selon nous, pourrait nuire à Climative ou aux utilisateurs du site Web ou les exposer à une responsabilité.</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:25.85pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051525">Causer des désagréments, des inconvénients, ou de l'anxiété inutile ou être susceptible de troubler, embarrasser, ou alarmer toute autre personne.</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:23.27pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051526">Promouvoir toute activité illégale, ou plaider en faveur de, promouvoir ou assister tout acte illégal.</a></li>
    <li style="margin-left:36pt; margin-bottom:12pt; text-align:left; font-family:Calibri; font-size:12pt; list-style-position:inside;"><span style="width:25.97pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><a name="a1051527">Donner l'impression qu'ils proviennent de ou sont approuvés par nous ou toute autre personne ou entité, si ce n'est pas le cas.</a></li>
</ol>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a583166"><strong><u><span style="font-family:Calibri;">Soumissions des utilisateurs : Octroi de licence</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051427"><span style="font-family:Calibri;">Le site Web peut contenir des fonctions interactives permettant les soumissions des utilisateurs sur ou via le site Web.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051490"><span style="font-family:Calibri;">Aucune des soumissions des utilisateurs que vous soumettez au site Web ne sera soumise à une confidentialité de la part de la société. En fournissant toute soumission d'utilisateur au site Web, vous nous accordez, ainsi qu'à nos concédants de licence, successeurs et ayants droit, le droit d'une licence mondiale, sans redevance, perpétuelle, irrévocable, non exclusive, d'utiliser, reproduire, modifier, exécuter, afficher, distribuer et divulguer autrement à des tiers tout tel matériel à toute fin et conformément aux paramètres de votre compte et/ou d'incorporer ce matériel dans toute forme, support ou technologie dans le monde entier, sans compensation pour vous. Vous renoncez en outre à tout droit moral ou autre droit d'auteur comme condition de soumettre toute soumission d'utilisateur.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051430"><span style="font-family:Calibri;">En soumettant les soumissions des utilisateurs, vous déclarez et garantissez que vous êtes propriétaire ou avez les droits nécessaires pour soumettre les soumissions des utilisateurs et que vous avez le droit de nous accorder la licence décrite ici, à nous, à nos affiliés et prestataires de services, ainsi qu'à chacun de leurs et de nos respectifs concédants de licence, successeurs et ayants droit des soumissions des utilisateurs, et de respecter ces conditions générales. Vous déclarez et garantissez que toutes les soumissions des utilisateurs sont conformes aux lois et réglementations applicables, ainsi qu'aux conditions d'utilisation, aux soumissions des utilisateurs et aux normes de contenu du site énoncées dans ces conditions générales.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051431"><span style="font-family:Calibri;">Vous comprenez et acceptez que vous, et non Climative, êtes pleinement responsable de toute soumission d'utilisateur que vous soumettez ou contribuez, et vous êtes entièrement responsable et légalement responsable, y compris vis-à-vis de toute tierce partie, pour ce contenu et son exactitude. Nous ne sommes pas responsables ni légalement responsables vis-à-vis de toute tierce partie du contenu ou de l'exactitude de toute soumission d'utilisateur soumise par vous ou tout autre utilisateur du site Web.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a454764"><strong><u><span style="font-family:Calibri;">Surveillance du site, application, suspension et résiliation</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051433"><span style="font-family:Calibri;">La société a le droit, sans préavis, de :</span></a></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:6pt; text-indent:-18pt; text-align:left; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:Calibri;">Supprimer ou refuser de publier sur le site Web toute soumission d'utilisateur pour toute raison ou aucune raison, à notre entière discrétion.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:6pt; text-indent:-18pt; text-align:left; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:Calibri;">À tout moment, prendre les mesures jugées nécessaires ou appropriées par nous, à notre entière discrétion, y compris, sans limitation, pour violation des présentes conditions générales.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:6pt; text-indent:-18pt; text-align:left; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:Calibri;">Prendre les mesures légales appropriées, y compris, sans limitation, référer à l'application de la loi ou à une autorité réglementaire, ou notifier la partie lésée de toute utilisation illégale ou non autorisée du site Web. Sans limiter ce qui précède, nous avons le droit de coopérer pleinement avec toute autorité de la loi ou ordonnance du tribunal demandant ou nous dirigeant à divulguer l'identité ou d'autres informations de toute personne publiant des matériaux sur ou via le site Web.</span></p>
<p style="margin-top:0pt; margin-left:36pt; margin-bottom:6pt; text-indent:-18pt; text-align:left; font-size:12pt;"><span style="font-family:Symbol;"></span><span style="width:12.48pt; font:7pt 'Times New Roman'; display:inline-block;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span style="font-family:Calibri;">Résilier ou suspendre votre accès à tout ou partie du site Web pour toute raison ou aucune raison, y compris, sans limitation, toute violation des présentes conditions générales.</span></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051434"><span style="font-family:Calibri;">VOUS RENONCEZ À TOUT RECOURS ET TENEZ CLIMATIVE À L'ÉCART DE TOUTES LES RECLAMATIONS DÉCOULANT DE TOUTE ACTION ENTREPRISE PAR CLIMATIVE EN LIEN AVEC TOUTE ENQUÊTE MENÉE PAR CLIMATIVE OU PAR LES AUTORITÉS COMPÉTENTES.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051435"><span style="font-family:Calibri;">Nous n'avons aucune obligation, ni aucune responsabilité envers une partie de surveiller le site Web ou son utilisation, et nous ne pouvons pas entreprendre de revoir le matériel que vous ou d'autres utilisateurs soumettez au site Web. Nous ne pouvons pas garantir le retrait rapide de tout matériel répréhensible après sa publication et nous n'assumons aucune responsabilité pour toute action ou inaction concernant les transmissions, communications ou contenus fournis par un utilisateur ou un tiers, sous réserve des lois applicables.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051491"><strong><u><span style="font-family:Calibri;">Aucune confiance&nbsp;</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051438"><span style="font-family:Calibri;">Le contenu de notre site Web est fourni à titre informatif général uniquement. Il n'est pas destiné à constituer des conseils sur lesquels vous devez vous baser. Vous devez obtenir des conseils plus spécifiques ou professionnels avant de prendre, ou de vous abstenir de prendre, toute action ou inaction sur la base du contenu de notre site.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051492"><span style="font-family:Calibri;">Bien que nous fassions des efforts raisonnables pour mettre à jour les informations sur notre site Web, nous ne faisons aucune déclaration, garantie ou promesse, expresse ou implicite, que le contenu de notre site Web est exact, complet ou à jour. L'utilisation du site Web se fait à vos risques et Climative n'a aucune responsabilité ou obligation à l'égard de votre utilisation de ce site.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051493"><span style="font-family:Calibri;">Ce site Web peut inclure du contenu fourni par des tiers, y compris d'autres utilisateurs et des concédants de licence tiers. Toutes les déclarations et/ou opinions exprimées dans ce contenu tiers, autres que le contenu fourni par la société, sont uniquement celles de la personne ou de l'entité fournissant ces matériaux. De tels matériaux ne reflètent pas nécessairement l'opinion de la société. Climative n'assume aucune responsabilité ni obligation envers vous ou toute autre partie pour le contenu ou l'exactitude des matériaux tiers.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a212305"><strong><u><span style="font-family:Calibri;">Vie privée</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051441"><span style="font-family:Calibri;">En soumettant vos informations personnelles et en utilisant notre site Web, vous consentez à la collecte, à l'utilisation, à la reproduction, à l'hébergement, à la transmission et à la divulgation de ces soumissions de contenu utilisateur conformément à notre politique de confidentialité, disponible sur Climative.ai, comme nous le jugeons nécessaire pour l'utilisation du site Web et la fourniture de services.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a452006"><strong><u><span style="font-family:Calibri;">Sites Web tiers&nbsp;</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051495"><span style="font-family:Calibri;">Pour votre commodité, ce site Web peut fournir des liens ou des pointeurs vers des sites tiers. Nous ne faisons aucune déclaration concernant d'autres sites Web qui peuvent être accessibles depuis ce site Web. Si vous choisissez d'accéder à ces sites, vous le faites à vos risques et périls. Nous n'avons aucun contrôle sur le contenu de ces sites tiers et n'acceptons aucune responsabilité à leur égard, ni pour toute perte ou dommage pouvant résulter de leur utilisation. Vous êtes soumis à toutes les conditions d'utilisation de ces sites tiers.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051530"><strong><u><span style="font-family:Calibri;">Restrictions géographiques</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051451"><span style="font-family:Calibri;">Nous mettons ce site Web à disposition uniquement pour les personnes situées au Canada. Ce site Web n'est pas destiné à être utilisé dans une juridiction où son utilisation est interdite. Si vous accédez au site depuis l'extérieur du Canada, vous le faites à vos risques et vous êtes responsable du respect des lois locales de votre juridiction.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a107208"><strong><u><span style="font-family:Calibri;">Avertissement concernant les garanties</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051452"><span style="font-family:Calibri;">VOUS COMPRENEZ ET ACCEPTEZ QUE L'UTILISATION DU SITE WEB, DE SON CONTENU, ET DE TOUT SERVICE OU ARTICLE TROUVÉ OU OBTENU PAR LE SITE EST À VOS PROPRES RISQUES. LE SITE WEB, SON CONTENU, ET TOUT SERVICE OU ARTICLE TROUVÉ OU OBTENU PAR LE SITE SONT FOURNIS "EN L'ÉTAT" ET "SELON DISPONIBILITÉ", SANS GARANTIE OU CONDITION AUCUNE, QU'ELLE SOIT EXPRESSE OU IMPLICITE, Y COMPRIS, MAIS SANS S'Y LIMITER, LES GARANTIES IMPLICITES DE QUALITÉ MARCHANDE, D'ADAPTATION À UN USAGE PARTICULIER, OU DE NON-VIOLATION. LES DISPOSITIONS CI-DESSUS NE TOUCHENT PAS LES GARANTIES QUI NE PEUVENT ÊTRE EXCLUES OU LIMITÉES EN VERTU DE LA LOI APPLICABLE.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051497"><span style="font-family:Calibri;">NI CLIMATIVENOR, NI SA MAISON MÈRE, FILIALES, AFFILIÉS, NI LEURS RÉPRESENTANTS, DIRIGEANTS, EMPLOYÉS, AGENTS, FOURNISSEURS DE SERVICES, CONTRACTANTS, LICENCIÉS, LICENCIÉS, FOURNISSEURS, OU SUCCESSEURS NE FOURNISSENT AUCUNE GARANTIE, DÉCLARATION, OU APPROBATION EN CE QUI CONCERNE L'EXACTITUDE, LA SÉCURITÉ, LA FIABILITÉ, L'ADAPTATION, L'EXACTITUDE, L'ACTUALITÉ, OU LA DISPONIBILITÉ DU SITE WEB OU DE SON CONTENU. SANS LIMITER CE QUI PRÉCÈDE, NI CLIMATIVENOR, NI SA MAISON MÈRE, FILIALES, AFFILIÉS NI LEURS RÉPRESENTANTS, DIRIGEANTS, EMPLOYÉS, AGENTS, FOURNISSEURS DE SERVICES, CONTRACTANTS, LICENCIÉS, LICENCIÉS, FOURNISSEURS, OU SUCCESSEURS NE GARANTISSENT OU NE DÉCLARENT QUE LE SITE WEB, SON CONTENU, OU TOUT SERVICE OU ARTICLE TROUVÉ OU OBTENU PAR LE SITE SERA EXACT, FIABLE, SANS ERREUR, OU ININTERROMPU, QUE LES DÉFAUTS SERONT CORRIGÉS, QUE NOTRE SITE WEB OU LE SERVEUR QUI LE REND DISPONIBLE SONT EXEMPTS DE VIRUS OU D'AUTRES COMPOSANTS NUISIBLES.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051498"><span style="font-family:Calibri;">NOUS NE POUVONS PAS ET NE GARANTISSONS PAS QUE LES FICHIERS OU LES DONNÉES DISPONIBLES POUR TÉLÉCHARGEMENT À PARTIR D'INTERNET OU DU SITE SERONT EXEMPTS DE VIRUS OU D'AUTRES CODES DESTRUCTIFS. VOUS ÊTES UNIQUEMENT ET ENTIÈREMENT RESPONSABLE DE VOTRE UTILISATION DU SITE WEB ET DE LA SÉCURITÉ DE VOTRE ORDINATEUR, INTERNET, ET DONNÉES. DANS LA MESURE MAXIMALE PERMISE PAR LA LOI, NOUS NE SERONS PAS RESPONSABLES DE TOUTE PERTE OU DOMMAGE CAUSÉ PAR UNE ATTAQUE DE REFUS DE SERVICE, UNE ATTAQUE PAR REFUS DE SERVICE DISTRIBUÉ, UNE SURENCHÈRE, UNE INONDATION, UN MAILBOMBING, OU UN PLANTAGE, DES VIRUS, CHEVAUX DE TROIE, WORMS, BOMBS LOGIQUES, OU AUTRES MATÉRIAUX TECHNOLOGIQUEMENT NUISIBLES QUI POURRAIENT INFECTER VOTRE ÉQUIPEMENT INFORMATIQUE, VOS PROGRAMMES INFORMATIQUES, VOS DONNÉES, OU AUTRES MATÉRIAUX PROPRIÉTAIRES EN RAISON DE VOTRE UTILISATION DU SITE WEB OU DE TOUT SERVICE OU ARTICLE TROUVÉ OU OBTENU PAR LE SITE, OU DE VOTRE TÉLÉCHARGEMENT DE MATÉRIELS PUBLIÉS DESSUS, OU SUR TOUT SITE LIÉ À CELUI-CI.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a136928"><strong><u><span style="font-family:Calibri;">Limitation de responsabilité</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051457"><span style="font-family:Calibri;">SAUF LORSQUE DE TELLES EXCLUSIONS SONT INTERDITES PAR LA LOI, EN AUCUNE CIRCONSTANCE CLIMATIVENOR, SA MAISON MÈRE, FILIALES, AFFILIÉS, OU LEURS RÉPRESENTANTS, DIRIGEANTS, EMPLOYÉS, AGENTS, FOURNISSEURS DE SERVICES, CONTRACTANTS, LICENCIÉS, LICENCIÉS, FOURNISSEURS, OU SUCCESSEURS NE SERONT RESPONSABLES DE NÉGLIGENCE, GROSSIÈRE NÉGLIGENCE, DÉCLARATION ERRONÉE, VIOLATION FONDAMENTALE, DOMMAGES DE TOUT TYPE, EN VERTU DE TOUTE THÉORIE JURIDIQUE, Y COMPRIS DES DOMMAGES DIRECTS, INDIRECTS, SPÉCIAUX, INCIDENTS, CONSÉCUTIFS, OU PUNITIFS, Y COMPRIS, MAIS SANS S'Y LIMITER, LES BLESSURES PERSONNELLES, LA DOULEUR ET LA SOUFFRANCE, LE STRESS ÉMOTIONNEL, LA PERTE DE REVENUS, LA PERTE DE PROFITS, LA PERTE D'AFFAIRES OU D'ÉCONOMIES ANTICIPÉES, LA PERTE D'UTILISATION, LA PERTE DE BONNE VOLONTÉ, LA PERTE DE DONNÉES, ET QUELLE QU'EN SOIT LA CAUSE, QU'ELLE SOIT DUE À UN DÉLIT, UNE VIOLATION DE CONTRAT, UNE VIOLATION DE LA VIE PRIVÉE, OU AUTRE, MÊME SI LA PARTIE AURAIT ÉTÉ CONSEILLÉE OU AURAIT RAISON DE LE SAVOIR, DÉCOULANT DE OU EN LIEN AVEC VOTRE UTILISATION, OU L'IMPOSSIBILITÉ D'UTILISER, OU VOTRE CONFIANCE EN, LE SITE WEB, TOUT SITE WEB LIÉ OU TELS AUTRES SITES TIERS, NI AUCUN CONTENU DE SITE, MATERIEL, PUBLICATION, OU INFORMATION Y FIGURANT.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051459"><strong><u><span style="font-family:Calibri;">Indemnisation</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051460"><span style="font-family:Calibri;">Dans la mesure maximale permise par la loi applicable, vous acceptez de défendre, indemniser et dégager de toute responsabilité la Société, sa société mère, ses filiales, ses affiliés, ainsi que leurs administrateurs, dirigeants, employés, agents, fournisseurs de services, entrepreneurs, concédants de licence, fournisseurs, successeurs et ayants droit, de et contre toute réclamation, responsabilité, dommage, jugement, indemnité, perte, coût, dépense ou frais (y compris les honoraires juridiques raisonnables) découlant de ou liés à votre violation de ces Termes et Conditions ou à votre utilisation du Site Web, y compris, mais sans s'y limiter, vos soumissions d'utilisateur, les sites tiers, toute utilisation du contenu, des services et des produits du Site Web autre que celle expressément autorisée dans ces Termes et Conditions.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a699054"><strong><u><span style="font-family:Calibri;">Droit applicable et choix du forum</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051462"><span style="font-family:Calibri;">Le Site Web et ces Termes et Conditions seront régis et interprétés conformément aux lois de la province du Nouveau-Brunswick et aux lois fédérales du Canada qui y sont applicables, sans tenir compte de toute disposition, principe ou règle de choix ou de conflit de lois (qu'il s'agisse des lois de la province de l'Ontario ou d'une autre juridiction) et indépendamment de votre domicile, résidence ou localisation physique.</span><span style="font-family:Calibri;">&nbsp;&nbsp;</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051499"><span style="font-family:Calibri;">Toute action ou procédure découlant de ou liée à ce Site Web et sous ces Termes et Conditions sera intentée devant les tribunaux de la province de l'Ontario et/ou la Cour fédérale du Canada, et chaque partie se soumet irrévocablement à la juridiction exclusive de ces tribunaux dans toute action ou procédure. Vous renoncez à toute objection à l'exercice de la juridiction sur vous par ces tribunaux et à la compétence de ces tribunaux.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051531"><strong><u><span style="font-family:Calibri;">Renonciation&nbsp;</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a406642"><span style="font-family:Calibri;">Aucune omission ou retard dans l'exercice d'un droit, remède, pouvoir ou privilège découlant de ces Termes et Conditions ne constitue, ni ne peut être interprété comme, une renonciation à ce droit, remède, pouvoir ou privilège; et aucun exercice partiel ou unique de tout droit, remède, pouvoir ou privilège en vertu des présentes n'empêche l'exercice d'un autre ou d'un nouveau droit, remède, pouvoir ou privilège.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051507"><strong><u><span style="font-family:Calibri;">Divisibilité</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051508"><span style="font-family:Calibri;">Si une disposition ou un terme de ces Termes et Conditions est invalide, illégal ou inapplicable dans une juridiction, cette invalidité, illégalité ou inapplicabilité n'affectera pas toute autre disposition ou terme de ces Termes et Conditions, ni n'invalidera ou ne rendra inapplicable ce terme ou disposition dans toute autre juridiction.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a361445"><strong><u><span style="font-family:Calibri;">Accord intégral</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051469"><span style="font-family:Calibri;">Les Termes et Conditions et notre Politique de confidentialité constituent l'intégralité de l'accord entre vous et Climative concernant le Site Web et remplacent toutes les ententes, accords, représentations et garanties antérieurs et contemporains, tant écrits qu'oraux, concernant ledit sujet.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051470"><strong><u><span style="font-family:Calibri;">Signalement et contact</span></u></strong></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051471"><span style="font-family:Calibri;">Ce site Web est exploité par Climative.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051510"><span style="font-family:Calibri;">Si vous prenez connaissance d'une utilisation abusive du site Web, y compris d'un comportement diffamatoire ou calomnieux, vous devez en informer Climative à l'adresse info@climative.ai.&nbsp;</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><a name="a1051473"><span style="font-family:Calibri;">Tous les autres commentaires, demandes de support technique et autres communications relatives au site Web doivent être envoyés à info@climative.ai.</span></a></p>
<p style="margin-top:12pt; margin-bottom:12pt; font-size:12pt;"><span style="font-family:Calibri;">2024-07-25</span></p>
</div>
`;

class CustomerTerms extends PureComponent {
	constructor(props) {
		super(props);

		this.T = props.t;

		this.state = {
			rand: 0
		};

		this.theme = Themes.autodetect();
	}

	static propTypes = {
	};

	static defaultProps = {
	};

	componentDidMount() {
	}

	componentDidUpdate(prevProps) {
	}

    onRadioKeydown(e){
		if(e.key == 'ArrowDown'){
		  e.preventDefault();
		  const next = e.target.nextElementSibling;
		  if(next){
			next.focus();
			next.click();
		  }
		} else if(e.key == 'ArrowUp'){
		  e.preventDefault();
		  const prev = e.target.previousElementSibling;
		  if(prev){
			prev.focus();
			prev.click();
		  }
		}
	}

	switchLanguage(lang) {
		i18next.changeLanguage(lang);
        this.setState({ rand: Math.random() });
	}

	render() {

        if (!this.props.currentUser) {
			window.location.href = "#/app/customer/view-report";
			return (<div/>)
		}

		document.body.className = "customer-page " + this.theme.className;

        let termsHtml = '';
        if (this.T('hmp-lang-code') === 'EN') {
            termsHtml = window?.ORG_INFO?.customer_terms_html || CUSTOMER_TERMS_HTML;
        }
        else if (this.T('hmp-lang-code') === 'FR') {
            termsHtml = window?.ORG_INFO?.customer_terms_fr_html || CUSTOMER_TERMS_HTML_FR;
        }

        return (
			<div role="main" className="customer-terms-page">
                <div className='ph-lang-switcher' onBlur={(e) => onBlurOutside(e, () => this.setState({ langShow: false }))}>
                    <button className='phls-btn' aria-label={this.T('Change Language') + ' (' + (this.T('hmp-lang-code') === 'EN' ? 'English Selected' : 'French Selected') + ')'} aria-expanded={this.state.langShow} onClick={() => { if (!this.theme.langSwitchDisabled) { this.setState({langShow: !this.state.langShow}) } }}><img alt="" src="/images/lang-icon.svg"/>{this.T('hmp-lang-code') === 'EN' ? 'English' : 'Français'}</button>
                    <div role='radiogroup' className={'phls-dropdown ' + (this.state.langShow ? 'phls-dd-show' : '')}>
                        <button role="radio" tabIndex={this.T('hmp-lang-code') === 'EN' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "true" : "false"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'EN' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('en')}><img alt={''} src={this.T('hmp-lang-code') === 'EN' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> English (EN)</button>
                        <button role="radio" tabIndex={this.T('hmp-lang-code') === 'FR' ? 0 : -1} onKeyDown={(e) => this.onRadioKeydown(e)} aria-checked={this.T('hmp-lang-code') === 'EN' ? "false" : "true"} className={'phlsdd-lang-option ' + (this.T('hmp-lang-code') === 'FR' ? 'phlsdd-lang-selected' : '')} onClick={() => this.switchLanguage('fr')}><img alt={''} src={this.T('hmp-lang-code') === 'FR' ? "/images/hmp-radio-checked.svg" : "/images/hmp-radio-unchecked.svg" }/> Français (FR)</button>
                    </div>
                </div>
                <div className="ctc-terms" dangerouslySetInnerHTML={{__html: termsHtml }}>
                </div>
                <div className="ctc-terms-footer">
                    <button role="checkbox" aria-checked={this.state.termsAccepted} className={"ctc-check " + (this.state.termsAccepted ? "ctc-check-checked" : "ctc-check-unchecked")} onClick={() => this.setState({ termsAccepted: !this.state.termsAccepted, rand: Math.random() })}>
                        <img alt="" src={this.state.termsAccepted ? "/images/ctc-checkbox-checked.svg" : "/images/ctc-checkbox-unchecked.svg"} />
                        <div className="ctc-checkbox-label">{this.T("I agree to the terms and conditions stated above")}</div>
                    </button><br/>
                    <button disabled={!this.state.termsAccepted} className={"ctc-save-and-continue"} onClick={() => this.props.dispatch(acceptTermsAndConditions())}>{this.T("Save and Continue")}</button>
                </div>
			</div>
		);
	}
}

////

function mapStateToProps(state) {
	return {
		currentUser: state.auth.currentUser,
	};
}

export default withTranslation()(withRouter(connect(mapStateToProps)(CustomerTerms)));
